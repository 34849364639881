'use strict';

// for Vue mutations 內使用
Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
};

$(function () {
    'use strict';
    // branch頁面內商品圖片增加連結

    $('.category-cover, .product-cover, .branch-product-card').each(function (i, v) {
        if ($(v).data('href') !== undefined) {
            var link = $(v).data('href');
            $(v).on('click', function () {
                location.href = link;
            });
        }
    });

    // iTunes Preview: https://funnow.page.link/download-ios
    // GooglePlay :    https://funnow.page.link/download-android
    // app:            https://www.myfunnow.com/app
    var downloadDYLink = function downloadDYLink(source) {
        source = source.toLowerCase();
        var isDesktop = !isMobile.android.device && !isMobile.apple.device;
        var link = location.href;
        if (source.indexOf('ios') > -1) {
            link = 'https://funnow.page.link/download-ios';
        } else if (source.indexOf('android') > -1) {
            link = 'https://funnow.page.link/download-android';
        } else if (isDesktop) {
            link = location.origin + '/app';
        }

        return link;
    };

    $('.download-App').on('click', function (e) {
        e.preventDefault();
        var source = $(this).data('src');
        gtag('event', 'click', {
            'event_category': 'DownloadApp',
            'event_label': $(this).data('src')
        });

        var url = downloadDYLink(source);
        console.log(url);
        window.open(url);
    });

    var getSmartBnnerAppLink = function getSmartBnnerAppLink() {
        var env = GLOBAL.ENV;
        var appLink = '/app';
        switch (env) {
            case 'prod':
                appLink = 'https://funnow.page.link/smart-banner';
                break;
            case 'stg':
                appLink = 'https://funnowstg.page.link/smart-banner-stg';
                break;
            case 'beta':
                appLink = 'https://funnowstg.page.link/smart-banner-beta';
                break;
        }
        return appLink;
    };
    // SmartBanner 開啟 APP
    $('#appdl_openapp').click(function () {
        if (navigator.userAgent.match(/iPad|iPhone|iPod/i)) {
            gtag('event', 'click', {
                'event_category': 'OpenApp',
                'event_label': 'Smartbanner_Open_iOS'
            });
            var url = getSmartBnnerAppLink('ios');
            console.log('#appdl_openapp: iOS', url);
            window.open(url);
        } else if (navigator.userAgent.match(/Android/i)) {
            gtag('event', 'click', {
                'event_category': 'OpenApp',
                'event_label': 'Smartbanner_Open_Android'
            });
            var _url = getSmartBnnerAppLink('android');
            console.log('#appdl_openapp: Android', _url);
            window.open(_url);
            // let campaign = location.pathname.substr(1);
            // campaign = campaign === '' ? 'index' : campaign;
            // let utm_link = '?utm_source=FunNow&utm_medium=SmartBanner&utm_campaign=' + campaign;
            // let url = $(this).data('url');
            // url = FunNowTools.insertStr(url, url.indexOf('#Intent'), utm_link);
            // window.open(url);

            // window.open(UniversalLink(url));
        } else {
            var _url2 = downloadDYLink('android');
            window.open(_url2);
        }
    });

    $('img.cover.lazyload').lazyload();

    // 快速登入Modal && [商品頁] 立即預訂
    $('.quick-login-modal').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var name = $(this).data('name');

        if (url === 'SAME_PAGE') {
            url = location.pathname;
        }
        if (name !== undefined && name !== 'Open_Gift') {
            gtag('event', 'click', {
                'event_category': 'Booking',
                'event_label': name
            });
        }
        // 收禮時,[註冊會員]
        if (/\/gift\/[0-9a-z]+\/accept/.test(url)) {
            gtag('event', 'receive', {
                'event_category': 'Gift',
                'event_label': name
            });
            // $.cookie('AcceptGift', '1', { expires: 1, path: '/' });
        }

        var toggle = $(this).data('toggle');
        if (toggle !== undefined && toggle === 'modal') {
            // 導向網址記錄到cookie
            $.cookie('RedirectTo', url, { expires: 1, path: '/' });
            FunNowTools.setRedirect(url);
        } else {
            location.href = url;
        }
    });

    $('.bookin-now-modal').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        location.href = url + location.search;
    });

    // Landing Page 點擊地區列表
    $('.region-block[data-href]').on('click', function () {
        var url = $(this).data('href');
        if (typeof url !== 'undefined' && url.length > 0) {
            location.href = url;
        }
    });

    // 地區切換, 分類切換
    $('#switch_page').on('change', function () {
        var url = $(this).find('option:selected').data('url');
        location.href = url;
    });

    // [送禮流程]
    var isGift = /\/gift\/[0-9a-zA-Z]+/.test(location.pathname);
    if (isGift) {
        $.cookie('appdownload', 0, { expires: 1, path: '/' });
        var giftbox = $('#giftbox');
        var orderinfo = $('#orderinfo');
        var nogift = $('#nogift');
        var modalOption = { backdrop: 'static', keyboard: false, show: true };
        // $('.loading-gift').hide();
        if (giftbox.length > 0) {
            // 收禮Modal
            giftbox.modal(modalOption);
        } else if (orderinfo.length > 0) {
            // 訂單Modal
            orderinfo.modal(modalOption);
        } else {
            // 發生錯誤
            var msg = nogift.data('msg');
            var type = nogift.data('received') !== undefined ? 'success' : 'info';
            var backlink = '/order/list';
            var confirmText = i18n.t('Go to order list');
            if (type === 'success' && $.cookie('uid') === undefined) {
                // 已被領取 + 尚未登入 : 慢了一步的人, 回首頁
                backlink = '/';
                confirmText = i18n.t('Back to index');
            }

            swal({
                title: '',
                text: msg,
                type: type,
                confirmButtonColor: '#3085d6',
                confirmButtonText: confirmText
            }).then(function (result) {
                if (result.value) {
                    // handle confirm
                    location.href = 'https://' + location.hostname + backlink;
                } else {
                    // handle dismiss, result.dismiss can be 'cancel', 'overlay', 'close', and 'timer'
                    location.href = 'https://' + location.hostname + backlink;
                }
            });
        }
    }

    // 台灣大車隊 55688 合作方案
    var isTWTAXI = FunNowTools.getParameter('utm_source');

    if (isTWTAXI === 's3qFxqqBACHQbOpZ8qg89VEGuMiBLCJl11wUjyE4YuM3LJ8En0c2VMKpiRc1YJ0zguTyI74VTQHfxWQQb9Qm84GLJSWEmPlnDm4xEQQ1TG9eBcWM4buOHRmE3tXQputl') {
        var timestamp = { time: moment().unix() };
        localStorage.setItem('TWTAXI', JSON.stringify(timestamp));
    }

    // load 活動快訊
    var eventSheet = 'https://cdn.myfunnow.com/web/csv/event_20200630.csv';
    var appendEventNewsList = function appendEventNewsList(data) {
        $('#header_menu_news ul').html(data.headerMenuList);
        $('#mobile_menu_news ul').html(data.mobileMenuList);
    };
    var loadEventNews = function loadEventNews(sheetData) {
        var lines = sheetData.split(/\r?\n/);
        // console.log(lines);
        var headerMenuList = '';
        var mobileMenuList = '';
        var nameIndex = {
            'zh-TW': 0, 'zh-CN': 2, 'ja-JP': 3, 'en-US': 4
        };

        lines.forEach(function (line, i) {
            // 第一行說明內容,不處理
            if (i === 0) {
                return false;
            }
            // 資料以,隔開
            // 連結名稱,網址,簡中,日文,英文
            var data = line.split(',');
            // console.log(data);
            if (data[0] == '' || data[1] == '') {
                return false;
            }

            console.log(data, nameIndex, LANGUAGE);
            var name = data[nameIndex[LANGUAGE]].trim();
            if (name === '') {
                name = data[0].trim();
            }
            var link = data[1].trim();
            if (link.startsWith('http')) {
                headerMenuList += '<li><a href="' + link + '" target="_blank" rel="nofollow">' + name + '</a></li>';
                mobileMenuList += '<li class="lv2"><a href="' + link + '" target="_blank" rel="nofollow">' + name + '</a></li>';
            }
        });

        if (headerMenuList !== '') {
            var data = {
                headerMenuList: headerMenuList,
                mobileMenuList: mobileMenuList
            };
            if (sessionStorage.getItem('EventNews') === null) {
                sessionStorage.setItem('EventNews', JSON.stringify(data));
            }
            appendEventNewsList(data);
        }
    };
    // 讀取活動快訊
    var eventNewsSheet = sessionStorage.getItem('EventNews');
    if (eventNewsSheet) {
        appendEventNewsList(JSON.parse(eventNewsSheet));
    } else {
        $.ajax({
            type: 'GET',
            xhrFields: {
                withCredentials: false
            },
            url: eventSheet,
            success: function success(result) {
                loadEventNews(result);
            },
            error: function error(_error) {
                console.error(_error);
            }
        });
    }

    // Fix iOS 11下 Modal 內 input 欄位，輸入框游標跑版問題
    $('#quickLoginModal').on('show.bs.modal', function () {
        var v = FunNowTools.iOSversion;
        if (v && v[0] >= 11) {
            $(this).css('position', 'absolute');
        }
    });
    $('#quickLoginModal').on('hidden.bs.modal', function () {
        var curPosition = $(this).css('position');
        if (curPosition === 'absolute') {
            $(this).css('position', 'fixed');
        }
    });

    $('.social-media').on('click', '.media-icon', function () {
        switch (this.dataset.type) {
            case 'fb':
                var fbLinkURL = {
                    'zh-TW': 'https://bit.ly/32nOu7K',
                    'zh-CN': 'https://bit.ly/32nOu7K',
                    'ja-JP': 'https://bit.ly/32nOu7K',
                    'en-US': 'https://bit.ly/32nOu7K'
                };
                window.open(fbLinkURL[LANGUAGE] || fbLinkURL['zh_TW'], '_blank');
                return;
            case 'ig':
                var igLinkURL = {
                    'zh-TW': 'https://bit.ly/31lc6uC',
                    'zh-CN': 'https://bit.ly/2EtQMdq',
                    'ja-JP': 'https://bit.ly/2EuLT3P',
                    'en-US': 'https://bit.ly/31lc6uC'
                };
                console.log(LANGUAGE, igLinkURL[LANGUAGE]);
                window.open(igLinkURL[LANGUAGE] || igLinkURL['zh_TW'], '_blank');
                return;
        }
    });
});