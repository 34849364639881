'use strict';

var Freshchat = function Freshchat() {
    this.publicFreshchat = false;
    this.forceClosed = false;
    this.tags = [];
    this.userData = {};
};

Freshchat.prototype = {
    renderFreshchatButton: function renderFreshchatButton() {
        $('body').append('<a id="open_fc_widget" onClick="window.Freshchat.openWidget()" style="cursor:pointer;">' + '<div id="custom_fc_button" style="visibility:hidden;">' + '<div id="custom-btn-notify" style="visibility:hidden;"></div>' + '<img id="imageicon" src="/images/icon/message-funnow-chat.svg"" />' + '</div>' + '</a>');
    },
    openWidget: function openWidget() {
        document.getElementById('fc_frame').style.visibility = 'visible';
        window.fcWidget.open();
    },
    setForceClosed: function setForceClosed(state) {
        this.forceClosed = state;
    },
    switchFcButton: function switchFcButton(state) {
        document.getElementById('fc_frame').style.visibility = state && !this.forceClosed ? 'visible' : 'hidden';
        document.getElementById('custom_fc_button').style.visibility = state || this.forceClosed ? 'hidden' : 'visible';
    },
    switchNotify: function switchNotify(state) {
        document.getElementById('custom-btn-notify').style.visibility = state && !this.forceClosed ? 'visible' : 'hidden';
    },
    setUserData: function setUserData(userData) {
        this.userData = Object.assign({}, userData);
        window.fcWidget.user.setProperties({
            firstName: userData.nickname,
            email: userData.email,
            phone: userData.phone
        });
    },
    publicSaveFreshchat: function publicSaveFreshchat() {
        var parameter = FunNowTools.findGetParameter();
        if (/\/(faq|orders|contact)/.test(window.location.pathname) && parameter['from'] !== 'app') {
            this.publicFreshchat = true;
        }
        return this.publicFreshchat;
    },
    setTags: function setTags(tags) {
        this.tags = tags.length > 0 ? tags : this.tags;
    },
    resetUserFreshchat: function resetUserFreshchat(newData) {
        var _this2 = this;

        window.fcWidget.destroy();
        setTimeout(function () {
            _this2.setInitForFreshchat(newData);
            _this2.setUserData(newData);
        }, 1000);
    },
    setInitForFreshchat: function setInitForFreshchat(userData) {
        window.fcWidget.init({
            token: GLOBAL.FRESHCHAT_TOKEN,
            host: 'https://wchat.freshchat.com',
            externalId: userData.uid || '',
            restoreId: userData.fc_restore_id || null,
            tags: this.tags,
            config: {
                hideFAQ: true,
                headerProperty: {
                    hideChatButton: true
                }
            }
        });

        var _this = this;
        window.fcWidget.user.get(function (resp) {
            var status = resp && resp.status;
            if (status !== 200) {
                _this.setUserData(userData);
                window.fcWidget.on('user:created', function (resp) {
                    var status = resp && resp.status;
                    var data = resp && resp.data;
                    if (status === 200) {
                        if (data.restoreId) {
                            fetch(FunNowTools.API + '/member/fresh-chat/restore-id', {
                                body: JSON.stringify({ restore_id: resp.data.restoreId }),
                                cache: 'no-cache',
                                credentials: 'include',
                                headers: { 'content-type': 'application/json' },
                                method: 'POST',
                                mode: 'cors'
                            }).then(function (response) {
                                console.log(response.json());
                            });
                        }
                    }
                });
            }
        });
    },
    init: function init() {
        var _this3 = this;

        var userData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var initFreshchat = function initFreshchat() {
            this.setInitForFreshchat(Object.assign({}, userData));
            var self = this;
            window.fcWidget.on('widget:loaded', function () {
                var isOrderPage = /\/(orders)/.test(window.location.pathname);
                var isOrderInfoPage = /\/(info)/.test(window.location.hash);
                if (isOrderPage && !isOrderInfoPage) {
                    self.setForceClosed(true);
                }
                self.switchFcButton(false);
                window.fcWidget.on('unreadCount:notify', function (res) {
                    self.switchNotify(res.count > 0);
                });
                window.fcWidget.on('widget:closed', function () {
                    self.switchFcButton(false);
                });
                window.fcWidget.on('widget:opened', function () {
                    self.switchFcButton(true);
                    self.switchNotify(false);
                });
            });
        };

        if (this.publicSaveFreshchat()) {
            var script = document.createElement('script');
            script.src = 'https://wchat.freshchat.com/js/widget.js';
            script.onload = function () {
                _this3.renderFreshchatButton();
                initFreshchat.call(_this3);
            };
            document.getElementsByTagName('body')[0].appendChild(script);
        }
    }
};

window.Freshchat = new Freshchat();