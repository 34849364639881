'use strict';

$(function () {
    'use strict';

    // faq link can add #app
    // When faq page for app, this page need hide header and footer and navigation

    if ($('.faq-page.container').attr('data-isapp') === '1') {
        $('body').css('padding-top', '0');
    }

    // 調整Android內的smartbanner與navbar的出現位置
    var appdownload_show = function appdownload_show() {
        $('#appdownload').show();
        $('header').css('position', 'relative');
        $('nav').css('position', 'relative').css('top', 'auto');
        $('.faq-page.container.mt80').css('margin-top', '0');
        $('body').css('padding-top', '0');
        $('.product-container__warpper').css('padding-top', '0');
        $('.reading-progress-bar').css('top', '0');
    };
    var appdownload_remove = function appdownload_remove() {
        $('#appdownload').remove();
        $('header').css('position', 'fixed');
        $('nav').css('position', 'fixed');
        $('body').css('padding-top', '44px');
        $('.reading-progress-bar').css('top', '44px');
        $('.product-container__warpper').css('padding-top', '44px');
        // 訂單資訊頁面修正
        $('.order-info__wrapper').css('padding-top', '44px');
    };
    // Smartbanner
    var displaySmartBanner = function displaySmartBanner() {
        return isMobile.apple.phone || isMobile.android.phone;
    };
    // if ((navigator.userAgent.match(/Android/i))
    if (displaySmartBanner() && /\/booking/.test(location.pathname) === false && $('#appdownload').length > 0 && $.cookie('appdownload') != 0 && $.cookie('hidden_header_footer') != 1 && $.cookie('Line_SID') === undefined) {
        appdownload_show();
    }
    $('#appdl_close').click(function () {
        $.cookie('appdownload', 0, { path: '/', expires: 30 });
        appdownload_remove();
    });

    var Header = {
        menuBtn: $('#mobileMenuBtn'),
        mobileMenu: $('ul.mobile-menu'),
        searchBtn: $('.js-header-search-btn'),
        mobileSearch: $('.header-search-block')
    };

    // header menu 點擊 [mobile]
    Header.menuBtn.on('click', function () {
        if (Header.mobileSearch.hasClass('active')) {
            Header.searchBtn.removeClass('active');
            Header.mobileSearch.removeClass('active');
        }
        $(this).toggleClass('active');
        Header.mobileMenu.toggleClass('active');
    });
    Header.searchBtn.on('click', function () {
        if (Header.menuBtn.hasClass('active')) {
            Header.menuBtn.removeClass('active');
            Header.mobileMenu.removeClass('active');
        }
        Header.searchBtn.toggleClass('active');
        Header.mobileSearch.toggleClass('active');
    });
    /*
    var HiddenMobileMenu = function() {
        if ($(window).width() > 767) {
            if (Header.menuBtn.hasClass('active')) {
                Header.menuBtn.removeClass('active');
                Header.mobileMenu.removeClass('active');
            }
            if (Header.mobileSearch.hasClass('active')) {
                Header.mobileSearch.removeClass('active');
            }
        }
    };
    */

    // Footer裡的 dropdown menu
    $('.c-dropdown div[class*="dropdown-btn"]').on('click', function () {
        var dropdown = $(this).parents('.c-dropdown');
        var needInMobile = dropdown.hasClass('c-dropdown-xs');
        var winWidth = window.innerWidth;
        var mobileStyle = winWidth < 768 || isMobile.any;
        if (needInMobile == false || needInMobile && mobileStyle) {
            dropdown.toggleClass('dd-open');
        }
    });
    // 點擊他處,關閉dropdown
    $('html').on('click', function (e) {
        var $target = $(e.target);
        if (!$target.parents().hasClass('dd-open')) {
            $('.c-dropdown').removeClass('dd-open');
        }
    });
    // [首頁], 選擇地區
    $('.search-region__list li').on('click', function (e) {
        var target = e.target;
        var newId = $(target).data('regionid');
        var parent = $(target).parents('.dd-open');
        var btn = parent.find('.c-dropdown-btn');
        var input = parent.find('input[name="regionid"]');
        var newDom = target.innerText + ' <i class="fa fa-angle-down"></i>';
        input.val(newId);
        btn.html(newDom);
        parent.removeClass('dd-open');
    });
    // 聯絡方式, nav dropdown list
    $('#contact_method li, .nav-dropdown-list li').on('click', function () {
        var link = $(this).find('a').attr('href');
        if (link !== undefined || link !== '#' || link !== '') {
            location.href = link;
        }
    });
    // 更換語系
    $('#language li').on('click', function (e) {
        e.preventDefault();
        var link = $(this).find('a');
        if ($('#loginLoading').hasClass('show')) {
            // 登入loading中, 不切換語系
            return false;
        }
        var lang = $(link).attr('id');
        switch (lang) {
            case 'zh_TW':
                $.cookie('language', 'zh-TW', { path: '/' });
                $.cookie('language-nuxt', 'tw', { path: '/' });
                break;
            case 'zh_CN':
                $.cookie('language', 'zh-CN', { path: '/' });
                $.cookie('language-nuxt', 'cn', { path: '/' });
                break;
            case 'ja_JP':
                $.cookie('language', 'ja-JP', { path: '/' });
                $.cookie('language-nuxt', 'jp', { path: '/' });
                break;
            default:
                $.cookie('language', 'en-US', { path: '/' });
                $.cookie('language-nuxt', 'en', { path: '/' });
                break;
        }
        window.location.reload();
    });

    // [聯絡方式]頁面
    var switchTabContent = function switchTabContent(region) {
        $('.list-tab a[data-tab]').removeClass('active');
        $('.list-tab a[data-tab="' + region + '"]').addClass('active');

        $('.tab-content-group > .tab-content').removeClass('active');
        $('.tab-content-group > #' + region).addClass('active');
    };

    // [聯絡方式]點選地區切換
    $('.list-tab a[data-tab]').on('click', function (e) {
        e.preventDefault();
        var region = $(this).data('tab');
        switchTabContent(region);
    });

    // [常見問題]展開收合
    $('.accordion-wrapper .accordion').on('click', function () {
        $(this).toggleClass('active');
        var isOpen = $(this).hasClass('active');
        // 切換方向鍵位置
        $(this).find('.fa-lg').removeClass(isOpen ? 'fa-angle-down' : 'fa-angle-up');
        $(this).find('.fa-lg').addClass(isOpen ? 'fa-angle-up' : 'fa-angle-down');

        var panel = $(this).next('.acc-panel')[0];
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    });
    // [常見問題]項目, 畫面下滑
    $('a.faq-link').on('click', function (e) {
        e.preventDefault();
        var id = $(this).attr('href').substr(1);
        var domTop = document.querySelector('#' + id).offsetTop;
        var wHeight = window.innerHeight;
        if (isNaN(domTop)) return false;

        if (domTop > wHeight / 2) {
            var scrollY = domTop - 120;
            $('html, body').animate({
                scrollTop: scrollY
            }, 500);
        }
    });
    // [常見問題]開啟 Freshchat
    $('.accordion-wrapper .contact-info-panel').on('click', function (e) {
        e.preventDefault();
        var tag = this.dataset.tag ? [this.dataset.tag] : '';
        if (window.fcWidget) {
            if (tag) {
                window.fcWidget.setTags(tag);
            }
            window.fcWidget.open();
        } else {
            window.prompt('open_fresh_chat', tag);
        }
    });

    // [首頁]最新商品 Slider
    new Swiper('.latest-product.swiper-container', {
        freeMode: false,
        freeModeMomentumRatio: 5,
        threshold: 50,
        slidesPerColumn: 1,
        slidesPerView: 'auto',
        spaceBetween: 10,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        grabCursor: true,
        prevButton: '.c-slider__btn.prev',
        nextButton: '.c-slider__btn.next'
    });

    // [首頁]上方slider
    /*
    var BannerImages = [
        ['2019跨年01', 'https://cdn.myfunnow.com/web/images/2019_01.jpg'],
        ['2019跨年02', 'https://cdn.myfunnow.com/web/images/2019_02.jpg'],
        ['2019跨年03', 'https://cdn.myfunnow.com/web/images/2019_03.jpg'],
        ['2019跨年04', 'https://cdn.myfunnow.com/web/images/2019_04.png'],
        ['王道聯名卡', 'https://cdn.myfunnow.com/web/images/funnow_card_obank_s.png'],
        ['米其林', 'https://cdn.myfunnow.com/web/michelin/mainpage_banner.jpg'],
        ['老外喝香檳', 'https://cdn.myfunnow.com/web/images/bg-landing-o.jpg'],
        ['台北', 'https://cdn.myfunnow.com/images/region/台北1080_472508.jpg'],
        ['沖繩', 'https://cdn.myfunnow.com/web/regions/pexels-photo-457882.jpg'],
        ['高雄', 'https://cdn.myfunnow.com/images/region/高雄1080_d4c2b8.jpg'],
        ['曼谷', 'https://cdn.myfunnow.com/web/images/bangkok.jpg']
    ];
    var BannerSlides = [];
    BannerImages.forEach((img, i) => {
        if (i === 0) {
            BannerSlides.push({
                src: img[1], transition: 'fade2', 'transition­Duration': 2500,
            })
        } else {
            BannerSlides.push({ src: img[1]})
        }
    });
    $('#index').vegas({
        delay: 7000,
        timer: false,
        preload: true,
        'preload­Image': true,
        // overlay: '/images/overlays/06.png',
        // color: '#555555',
        transition: 'blur2',
        'transition­Duration': 2000,
        slides: BannerSlides,
        init: function() {
            setTimeout(()=>{
                $('#index').css('background-image', 'none');
            }, 2000);
        }
    });
    var reSetBannerHeight = function() {
        let ww = $(window).width();
        let currentH = $('#index').css('height');
         if (ww >= 992 && currentH !== '512px') {
            $('#index').css('height', '512px');
        } else if (ww < 992 && ww >= 480 && currentH !== '440px') {
            $('#index').css('height', '440px');
        } else if (ww < 480 && currentH !== '66.7vw') {
            $('#index').css('height', '66.7vw');
        }
    };
    */

    // [商品頁]商品頁面照片 slider
    var pdImgSwiper = new Swiper('.product-pictures__swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        grabCursor: true,
        prevButton: '.pd-pics-prev',
        nextButton: '.pd-pics-next'
    });
    // [商品頁]點選右邊垂直小圖,跳輪播
    $('.small-pictures__wrapper .picture').on('click', function () {
        var index = $(this).data('index');
        pdImgSwiper.slideTo(index, 100, false);
    });

    // [商品頁]商品照片右側垂直圖片區,檢查是否加入slider
    var checkVerticalPictures = function checkVerticalPictures() {
        if ($(window).width() < 768) return false;

        var VerticalContainer = $('.vertical-pictures');
        var VerticalContent = $('.vertical-pictures .small-pictures__wrapper');
        var containerHeight = VerticalContainer.height();
        var contentHeight = VerticalContent.height();
        if (contentHeight > containerHeight) {
            $('.small-pictures__wrapper').addClass('swiper-wrapper');
            $('.vertical-pictures .prev-btn').removeClass('hidden');
            $('.vertical-pictures .more-btn').removeClass('hidden');

            new Swiper('.vertical-pictures', {
                direction: 'vertical',
                freeMode: false,
                freeModeMomentumRatio: 5,
                threshold: 50,
                slidesPerView: 5,
                spaceBetween: 2,
                prevButton: '.prev-btn',
                nextButton: '.more-btn'
            });
        }
    };
    checkVerticalPictures();

    // [商品頁]替過長評論增加...閱讀更多
    var attachReadMore = function attachReadMore() {
        $('.comment__wrapper.dotdotdot').each(function () {
            var lines = $(this).data('lines');
            var height = parseInt($(this).css('line-height'), 10) * lines;
            var isTruncated = $(this).triggerHandler('isTruncated');

            if ($(this).height() > height && isTruncated !== false) {
                $(this).find('br').replaceWith('<span />');
                var readmoreDom = '<a class="readmore">' + i18n.t('閱讀更多') + '</a>';
                $(this).append(readmoreDom);

                $(this).dotdotdot({
                    ellipsis: '......',
                    wrap: 'letter',
                    after: 'a.readmore',
                    height: height,
                    watch: 'true'
                });
            } else if ($(this).height() === height) {
                if (isTruncated) {
                    console.log('do nothing');
                } else {
                    console.log('do something');
                }
            } else {
                $(this).find('a.readmore').remove();
            }
        });
    };
    attachReadMore();

    // [分類頁] 行事曆 slider, 需要點開行事曆時，手動 trigger
    /*
    var CalendarSlider = new Swiper('.calendar-panel.swiper-container', {
        slidesPerView: '1',
        simulateTouch: false,
        allowTouchMove: false,
        // touchMoveStopPropagation: false,
        prevButton: '.calendar-panel__header .prev-month:not(.disabled)',
        nextButton: '.calendar-panel__header .next-month:not(.disabled)',
        onSlideChangeEnd: (CalendarSlider) => {
            let currMonth = moment().add(CalendarSlider.activeIndex, 'months').format('YYYY/MM');
            $('.calendar-panel__header .curr-month').text(currMonth);
        }
    });
    */

    // [商品頁]展開評論的[閱讀更多]
    $(document).on('click', '.dotdotdot a.readmore', function (e) {
        e.preventDefault();
        var wrapper = $(this).parent();
        var isTruncated = wrapper.triggerHandler('isTruncated');
        if (isTruncated) {
            var content = wrapper.triggerHandler('originalContent');
            wrapper.trigger('destroy').text(content[0].wholeText);
        } else {
            // 曾經有[閱讀更多],因為resize後不需要了
            wrapper.find('a.readmore').hide().remove();
        }
    });

    // [商品頁]商家特色 小icon們slider
    new Swiper('.feature-icons.swiper-container', {
        slidesPerView: 'auto',
        freeMode: true,
        freeModeSticky: true,
        spaceBetween: 5,
        paginationClickable: true
    });

    // [商品頁]更多相關文章 click: 展開最多四篇
    $('.product-left-block .more-articles').on('click', function () {
        $('.related-articles .blog-card.hidden').removeClass('hidden');
        $(this).hide();
    });

    // [商品頁]相關商品 slider
    // [文章頁]相關商品 slider
    new Swiper('.relate-product-container', {
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        prevButton: '.swiper-btn.prev',
        nextButton: '.swiper-btn.next'
    });

    // [商品頁]查看所有評論
    $('#view_product_comments').on('click', function (e) {
        e.preventDefault();
        var commentTop = $('.comment__header').offset().top - 240;
        $('html, body').animate({
            scrollTop: commentTop
        }, 500);
    });

    // [店家頁]上方查看所有評論
    $('#all_comments').on('click', function (e) {
        e.preventDefault();
        var commentTop = $('.branch-comment__container').offset().top - 100;
        $('html, body').animate({
            scrollTop: commentTop
        }, 500);
    });

    // [店家頁]評價分數條
    $('.rating-progress').each(function (i, v) {
        var progress = $(v).find('.progress');
        var offset = $(progress).data('offset');
        var time = offset / 100 * 500;
        var newLeft = -100 + parseInt(offset) + '%';

        setTimeout(function () {
            $(progress).animate({
                'left': newLeft
            }, time);
        }, i * 150);
    });

    // [店家頁]相關文章
    new Swiper('.swiper-container.branch-articles', {
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        prevButton: '.article.my-swiper-btn__prev',
        nextButton: '.article.my-swiper-btn__next'
    });

    // [店家頁]附近商家
    new Swiper('.swiper-container.branch-nearby', {
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        prevButton: '.nearby.my-swiper-btn__prev',
        nextButton: '.nearby.my-swiper-btn__next'
    });

    // [Landing Page] 媒體報導
    new Swiper('.swiper-container.media-reports-container', {
        // freeMode: true,
        // freeModeSticky: true,
        autoplay: 3000,
        slidesPerView: 'auto'
        // paginationClickable: true,
        // touchReleaseOnEdges: true
    });

    // [部落格文章] 相關文章
    new Swiper('.relate-articles__container.swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 15,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        prevButton: '.relate-articles__container .c-slider__btn.prev',
        nextButton: '.relate-articles__container .c-slider__btn.next'
    });

    /* ====================
     * 客製化 Slider
     * ====================
     * - 基本一行 Slider 範例
     *  <div class="c-slider-container swiper-container">
     *    <div class="c-slider-header"> (可選)
     *      <div class="c-slider__btn-group">
     *        <div class="c-slider__btn prev"><i class="fa fa-long-arrow-left"></i></div>
     *        <div class="c-slider__btn next"><i class="fa fa-long-arrow-right"></i></div>
     *      </div>
     *    </div>
     *    <div class="c-slider-wrapper swiper-wrapper">
     *       <div class="swiper-slide"></div>
     *       ...
     *       <div class="swiper-slide"></div>
     *    </div>
     *  </div>
     *
     * - 兩行 Slider 範例 (data-count <= 6 維持一行顯示)
     *  <div class="c-slider-container c-slider--multi swiper-container"
     *       data-count="7">
     *    .....同上
     *  </div>
     */

    // [地區頁面] 主打活動
    // var CustomSlider = $('.c-slider-container');
    // for(let i = 0, max = CustomSlider.length; i < max; i++) {
    //     let tmpSlider = $(CustomSlider[i]);
    //     let margin    = $(CustomSlider[i]).data('margin');
    //     margin = (margin === null) ? 10 : margin;
    //     let column = 1;

    //     if (tmpSlider.hasClass('c-slider--multi')) {
    //         //檢查 data-count
    //         let count = tmpSlider.data('count');
    //         column = (parseInt(count) > 8) ? 2 : 1;
    //     }
    //     CustomSlider[i] = FunNowTools.setSlider(CustomSlider[i], margin, column);
    // }

    // [分類頁面] 最新上架店家
    new Swiper('.latest-branchs-slider', {
        slidesPerView: 'auto',
        spaceBetween: 10,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        prevButton: '.c-slider__btn.prev',
        nextButton: '.c-slider__btn.next',
        breakpoints: {
            479: {
                spaceBetween: 5
            },
            767: {
                spaceBetween: 8
            }
        }
    });

    var createSwiperOption = function createSwiperOption(column, sliderName) {
        return {
            slidesPerColumn: column,
            slidesPerView: 'auto',
            spaceBetween: 30,
            paginationClickable: true,
            touchReleaseOnEdges: true,
            prevButton: '.' + sliderName + ' .c-slider__btn.prev',
            nextButton: '.' + sliderName + ' .c-slider__btn.next',
            breakpoints: {
                991: {
                    spaceBetween: 10
                },
                1199: {
                    spaceBetween: 15
                }
            }
        };
    };
    // [地區頁面] 分類列表
    var column = $('.category-slider').data('count') > 8 ? 2 : 1;
    var options = createSwiperOption(column, 'category-slider');
    new Swiper('.category-slider', options);

    // [地區頁面] 主題精選列表
    var tagColumn = $('.tag-slider').data('count') > 8 ? 2 : 1;
    var tagOptions = createSwiperOption(tagColumn, 'tag-slider');
    new Swiper('.tag-slider', tagOptions);

    // [地區頁] 熱門部落格文章
    new Swiper('.popular-blog__slider-wrapper .c-slider-container', {
        slidesPerColumn: 1,
        slidesPerView: 'auto',
        spaceBetween: 30,
        paginationClickable: true,
        touchReleaseOnEdges: true,
        breakpoints: {
            991: {
                spaceBetween: 10
            },
            1199: {
                spaceBetween: 15
            }
        }
    });

    // window滾動事件
    // 1.header 滾動縮放
    // 2.商品頁面,右側卡片
    // $('.product-card').offsetTop - header Height - nav Height
    var pdStickTop, pdOffStickTop;
    var countProductCardStickData = function countProductCardStickData() {
        if ($(window).width() < 992) {
            $('.product-card').removeClass('sticky');
            return false;
        }
        if ($('.product-card').length == 0) return false;

        pdStickTop = $('.product-card').offset().top - 44 - 44;
        pdOffStickTop = $('#productCardBottom').offset().top - $('.product-card').height() - 88;
        pdStickTop = pdStickTop - 26; // header,nav 多了sticky的差異
        // console.log('pdCard stick: '+pdStickTop, pdOffStickTop);
    };
    countProductCardStickData();

    // Landing Page 下 Header背景切換
    // var landingPageHeaderSwitch = function(scrollTop) {
    //     if (scrollTop >= 70) {
    //         if ($('header').hasClass('bg-transparent')) {
    //             $('header').removeClass('bg-transparent');
    //         }
    //     } else {
    //         if ($('header').hasClass('bg-transparent') === false) {
    //             $('header').addClass('bg-transparent');
    //         }
    //     }
    // };
    // Header滾動時，縮放切換
    // var headerStickySwitch = function(scrollTop, isLanding) {
    //     if (scrollTop > 0) {
    //         if ($('header').hasClass('sticky') === false) {
    //             $('header').addClass('sticky');
    //             // landing page頁面不用body的padding-top
    //             if (isLanding === false) {
    //                 $('body').css('padding-top', '44px');
    //             }
    //         }
    //     } else {
    //         if ($('header').hasClass('sticky')) {
    //             $('header').removeClass('sticky');
    //             // landing page頁面不用body的padding-top
    //             if (isLanding === false) {
    //                 $('body').css('padding-top', '70px');
    //             }
    //         }
    //     }
    // };
    // [商品頁] 商品資訊固定右側
    var productCardStickySwitch = function productCardStickySwitch(scrollTop) {
        if ($('.product-card').length > 0) {
            if (scrollTop >= pdStickTop && scrollTop < pdOffStickTop) {
                $('.product-card').addClass('sticky');
            } else {
                $('.product-card').removeClass('sticky');
            }
        }
    };
    // 檢查頁面是否為 landing page 版型
    // var isLandingPage = function() {
    //     let path = location.pathname;
    //     let indexReg   = new RegExp(/^\/(cn\/|en\/|jp\/)?$/);
    //     let isApp      = (path.indexOf('/app') > -1);
    //     let isLanding  = (path.indexOf('/landing') > -1);
    //     let isNewIndex = indexReg.test(path) || (path.indexOf('/test/index') > -1);
    //     let isLineCoupon = (path.indexOf('/line-coupon') > -1);
    //     if (isApp || isLanding || isNewIndex || isLineCoupon) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
    // 不同頁面中, 畫面滾動需要執行的事件
    var scrollAction = function scrollAction() {
        var scrollTop = $(this).scrollTop();

        // landing page頁面的header樣式切換
        // if (isLandingPage()) {
        //     landingPageHeaderSwitch(scrollTop);
        // }

        // [All] mobile寬度以下, header不需要sticky效果
        if (window.innerWidth < 768) {
            return false;
        }
        // headerStickySwitch(scrollTop, isLandingPage());

        // [商品頁] tablet寬度以下, 商品資訊不需要sticky
        if (window.innerWidth < 992) {
            return false;
        }
        productCardStickySwitch(scrollTop);
    };

    window.addEventListener('scroll', scrollAction);
    // $(window).scroll(scrollAction);
    $(window).resize(FunNowTools.debounce(checkVerticalPictures, 100, false));
    $(window).resize(FunNowTools.debounce(countProductCardStickData, 100, false));
    $(window).resize(FunNowTools.debounce(attachReadMore, 100, false));
    // $(window).resize(FunNowTools.debounce(HiddenMobileMenu, 100, false));
    // $(window).resize(FunNowTools.debounce(reSetBannerHeight, 200, false));
    // 裝置轉向
    window.addEventListener('orientationchange', attachReadMore, false);
    // window.addEventListener('orientationchange', reSetBannerHeight, false);
});