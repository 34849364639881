'use strict';

// 現有語言包
var LANG_GROUP = [{ key: 'zh-TW', file_name: 'zh_TW' }, { key: 'zh-CN', file_name: 'zh_CN' }, { key: 'en-US', file_name: 'en_US' }, { key: 'ja-JP', file_name: 'ja_JP' }];
var LANGUAGE = $.cookie('language') || navigator.language || navigator.userLanguage;
// 不在語言包內語系, 預設英文
var FIND_LANG = LANG_GROUP.filter(function (lang) {
    return lang.key.toLocaleLowerCase() === LANGUAGE.toLocaleLowerCase();
});
LANGUAGE = FIND_LANG.length <= 0 ? 'en-US' : FIND_LANG[0].key;
var FILE_LANGUAGE = FIND_LANG.length <= 0 ? 'en_US' : FIND_LANG[0].file_name;
var setI18n = function setI18n() {
    var language = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : FILE_LANGUAGE;

    i18n.set({ 'lang': language, 'path': '/lang/' });
};
setI18n();