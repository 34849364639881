'use strict';

$(function () {
    'use strict';

    if (!/^\/(|(tw|cn|jp|en)\/)$/.test(location.pathname)) return false;

    // [首頁]FunNow 2.0 Banner
    if ($('.swiper-container.banner__swiper-container').length === 0) return false;

    var BannerSwiper = new Swiper('.swiper-container.banner__swiper-container', {
        autoplay: 2000,
        speed: 500,
        loop: true,
        // slidesPerColumn: 1,
        // slidesPerView: 'auto',
        paginationClickable: true,
        // grabCursor: true,
        pagination: '.swiper-pagination'
    });
    var BannerCSVUrl = 'https://cdn.myfunnow.com/web/csv/banner_20200630.csv ';
    var settupBannerSlides = function settupBannerSlides(sheetData) {
        var lines = sheetData.split(/\r?\n/);
        lines.forEach(function (line, i) {
            // 第一行說明內容,不處理
            if (i === 0) {
                return false;
            }
            // 資料以,隔開 [名稱, 圖片, 連結]
            var data = line.split(',');
            if (data[1] !== '') {
                var bgImg = 'background-image: url(\'' + data[1] + '\')';
                var slide = '<div class="swiper-slide banner-slide" data-href="' + data[2] + '" style="' + bgImg + '"></div>';
                // console.log(slide)
                BannerSwiper.prependSlide(slide);
            }
            // re-bind click event
            $('.swiper-slide.banner-slide').unbind('click');
            $('.swiper-slide.banner-slide').on('click', clickBannerHandler);
            BannerSwiper.slideTo(0, 1000, false);
            BannerSwiper.startAutoplay();
        });
    };
    var fetchBannerSheetData = function fetchBannerSheetData() {
        $.ajax({
            type: 'GET',
            xhrFields: {
                withCredentials: false
            },
            url: BannerCSVUrl,
            success: function success(result) {
                sessionStorage.setItem('BannerSlideData', result);
                settupBannerSlides(result);
            },
            error: function error(_error) {
                console.error(_error);
            }
        });
    };
    // 檢查 session 有無Banner資料
    var cacheBannerData = sessionStorage.getItem('BannerSlideData');
    BannerSwiper.stopAutoplay();
    if (!cacheBannerData) {
        fetchBannerSheetData();
    } else {
        settupBannerSlides(cacheBannerData);
    }

    var clickBannerHandler = function clickBannerHandler() {
        var href = $(this).data('href');
        if (href.startsWith('http')) {
            window.open(href);
        }
    };
    $('.swiper-slide.banner-slide').on('click', clickBannerHandler);
});