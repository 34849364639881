'use strict';

$(function () {
    'use strict';
    // 預設所有 ajax 都帶 header set-cookie

    $.ajaxSetup({
        headers: {
            'Accept-Language': LANGUAGE
        },
        xhrFields: { withCredentials: true }
    });

    var Auth = Auth || {};

    Auth.View = {
        modalTitle: $('.quick-login-header h3.title'),
        // 快速登入Modal 註冊
        registerForm: $('.register-form'),
        registerBtn: $('.register-form .register_btn'),
        // 快速登入Modal 登入
        loginForm: $('.loginForm'),
        loginBtn: $('.loginForm .login_btn'),
        // 信箱跟密碼中間的提示訊息
        hintMsg: $('form .hint-msg'),
        // 登出
        logoutBtn: $('#logout_btn'),
        logoutBtnxs: $('#logout_btn_xs'),
        FBloginBtn: $('.fblogin_btn'),
        // fb 首次授權資料確認頁 註冊按鈕
        FBregisterBtn: $('#fbregister_btn'),
        loginWrapper: $('#loginWrapper'),
        FBloginWrapper: $('#FBloginWrapper'),
        loginLoading: $('#loginLoading'),
        // LINE 登入頁面
        LINEWrapper: $('#LINELoginWrapper'),
        // CathayMR 跳轉頁面
        CathayMR: $('#CathayMR_Redirect'),
        // 包住"註冊"與"登入"兩個表單的區塊
        switchBlock: $('.switch_block'),
        // 切換"會員登入"與"快速註冊"連結
        switchLink: $('.switch-hint span.switch'),
        showPwd: $('.login-form .show-pwd'),
        // 忘記密碼
        forgotForm: $('#forgotForm'),
        forgotBtn: $('#forgot_btn'),
        // 重設密碼
        resetForm: $('#resetForm'),
        resetBtn: $('#reset_pwd_btn'),
        // Header 內登入狀態有關的項目
        headerNotLogin: $('.js-header-user-not-login'),
        headerLogin: $('.js-header-user-login')
    };
    Auth.ShowMsg = function (msg) {
        this.View.hintMsg.find('span').text(msg);
        this.View.hintMsg.addClass('show');
    };
    Auth.ShowErrorMsg = function (msg) {
        this.View.loginForm.find('.login-form').addClass('has-error');
        this.View.registerForm.find('.login-form').addClass('has-error');
        this.View.hintMsg.find('span').text(msg);
        this.View.hintMsg.addClass('error show');
    };
    Auth.HideMsg = function () {
        this.View.loginForm.find('.login-form').removeClass('has-error');
        this.View.registerForm.find('.login-form').removeClass('has-error');
        this.View.hintMsg.find('span').text('');
        this.View.hintMsg.removeClass('error show');
    };

    Auth.FB = {
        Status: undefined,
        AppId: GLOBAL.FB_APP_ID,
        Fields: 'name,first_name,last_name,age_range,link,birthday,gender,locale,timezone,email,updated_time,is_verified'
    };

    Auth.CheckEmail = function (form) {
        var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var userEmail = form.find('input[name="username"]').val().trim();
        if (userEmail === '') {
            Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
            return Auth.ShowErrorMsg('請填寫必要欄位');
        }
        $.ajax({
            type: 'GET',
            url: FunNowTools.API + ('/account/exists?email=' + userEmail),
            dataType: 'json',
            success: function success(res) {
                if (res.code === 0) {
                    callback();
                } else {
                    // 恢復註冊按鈕狀態
                    Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                    Auth.ShowErrorMsg(res.message);
                }
            },
            error: function error(_error) {
                // 恢復註冊按鈕狀態
                Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                // 註冊失敗後處理
                var result = _error.responseJSON;
                Auth.FBRegisterFail(result.message);
            }
        });
    };

    Auth.Registered = function (form) {
        var userEmail = form.find('input[name="username"]').val().trim();
        var password = form.find('input[name="password"]').val().trim();
        if (userEmail === '' || password === '') {
            Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
            return Auth.ShowErrorMsg('請填寫必要欄位');
        }
        // 欄位填寫檢查通過,發送註冊事件
        gtag('event', 'Register', {
            'event_category': 'Funnel',
            'event_label': 'Email'
        });

        // 2018-04-18 邀請碼(invitecode)僅限 FB 註冊帶入
        var invitecode = $.cookie('promo') || '';

        var data = {
            'username': userEmail,
            'password': password,
            'gender': parseInt(FunNowTools.genderCode('unknow')),
            'currency': 0, // 0:NTD, 1:HKD, 2:JPY
            // 'birthday'   : FunNowTools.Today.replace(/\//g, '-'),
            'invitecode': invitecode,
            'email': userEmail,
            'name': 'New User',
            'os': 'web',
            'osVersion': navigator.userAgent,
            'appVersion': '1.0',
            'deviceId': 'WEB-' + FunNowTools.UUID,
            'Accept-Language': LANGUAGE // 全域變數,語系

            // 跟FB註冊使用相同API
        };$.ajax({
            type: 'POST',
            url: FunNowTools.API + '/register',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    console.log('Register successful -> Login');
                    var dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'registration',
                        'authenticationMethod': 'email',
                        'userId': result.data.uid
                    });
                    // 已經登入，導向該回去的頁面
                    Auth.loginSuccess(result.data);
                    if (window.Freshchat) {
                        window.Freshchat.resetUserFreshchat(result.data);
                    }
                } else {
                    // 恢復註冊按鈕狀態
                    Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                    // 註冊失敗後處理
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'Quick Fail'
                    });
                    Auth.FBRegisterFail(result.message);
                }
            },
            error: function error(_error2) {
                // 恢復註冊按鈕狀態
                Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                // 註冊失敗後處理
                gtag('event', 'Register', {
                    'event_category': 'Funnel',
                    'event_label': 'Quick Fail'
                });
                var result = _error2.responseJSON;
                Auth.FBRegisterFail(result.message);
            }
        });
    };

    Auth.login = function (name, password, sendEvent, callback) {
        if (sendEvent) {
            gtag('event', 'Login', {
                'event_category': 'Funnel',
                'event_label': 'Email'
            });
        }
        // 詢問地理坐標之前，最好有提示畫面
        // GEOLOCATION.getLocation();

        var data = {
            'username': name,
            'password': password,
            'os': 'web',
            'osVersion': navigator.userAgent,
            'appVersion': '1.0',
            'deviceId': 'WEB-' + FunNowTools.UUID
        };
        Auth.postLogin(data, callback);
    };

    Auth.postLogin = function (data, callback) {
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/login',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    console.log('login api success, set cookie and chage avatar');
                    var dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'login',
                        'authenticationMethod': 'email',
                        'userId': result.data.uid
                    });
                    Auth.loginSuccess(result.data);
                } else {
                    // 恢復註冊按鈕狀態
                    Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                    // 恢復登入按鈕狀態
                    Auth.View.loginBtn.removeClass('disabled').removeAttr('disabled');
                    // TBD::login fail handle
                    console.log(result.message);
                    gtag('event', 'Login', {
                        'event_category': 'Funnel',
                        'event_label': 'Email Fail'
                    });
                    Auth.ShowErrorMsg(result.message);
                }
                if (callback) {
                    callback();
                }
            },
            error: function error(_error3) {
                // 恢復註冊按鈕狀態
                Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                // 恢復登入按鈕狀態
                Auth.View.loginBtn.removeClass('disabled').removeAttr('disabled');
                // 登入失敗後處理
                gtag('event', 'Login', {
                    'event_category': 'Funnel',
                    'event_label': 'Email Fail'
                });
                var result = _error3.responseJSON;
                Auth.ShowErrorMsg(result.message);
            }
        });
    };

    Auth.loginSuccess = function (data) {
        // $.cookie('token', data.token, {expires: 30, path: '/' });
        // $.cookie('uid', data.uid, {expires: 30, path: '/' });
        Auth.setLoginCookie(data);
        Auth.setHeaderLoginStatus('login');
        // gtag('set', 'userId', data.uid);

        var phone = $.cookie('phone');
        if (phone !== undefined) {
            Auth.updateMember(data, phone);
        }

        var promo = $.cookie('promo');
        if (promo !== undefined) {
            Auth.memberPromoCode(promo);
        } else {
            Auth.loginAndRedirect();
        }
    };

    Auth.loginAndRedirect = function () {
        var isReplace = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        // Auth.changeAvatarStatus('login');
        console.log('login success, redirect to = ' + FunNowTools.RedirectTo);
        var redirect = FunNowTools.RedirectTo === '' || FunNowTools.RedirectTo === '/auth/login' ? '/' : FunNowTools.RedirectTo;
        if ($.cookie('RedirectTo') !== undefined) {
            $.removeCookie('RedirectTo', { path: '/' });
        }

        if ($('#quickLoginModal').is(':visible')) {
            $('#quickLoginModal').modal('hide');
        }
        if (redirect.indexOf(location.pathname) < 0) {
            if (isReplace) {
                window.location.replace(redirect);
            } else {
                window.location.href = redirect;
            }
        }
    };

    Auth.updateMember = function (login_data, phone) {
        var data = {
            'birthday': login_data.birthday,
            'currency': 0,
            'email': login_data.email,
            'gender': login_data.gender,
            'nickname': login_data.nickname,
            'phone': phone
        };
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/member',
            data: data,
            dataType: 'json',
            success: function success(result) {
                $.removeCookie('phone', { path: '/' });
                if (result.code === 0) {
                    // update member success, 不處理
                    console.log(result.message);
                } else {
                    // update member fail, 不處理
                    console.log(result.message);
                }
            },
            error: function error(_error4) {
                // update member fail, 不處理
                console.error(_error4);
            }
        });
    };

    Auth.memberPromoCode = function (promo) {
        var data = {
            'promo_code': promo
        };
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/promo',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                // 使用活動馬後，刪除cookie
                $.removeCookie('promo', { path: '/' });
                if (result.code === 0) {
                    // promo code success, 不處理
                    console.log(result.message);
                } else {
                    // promo code fail, 不處理
                    console.log(result.message);
                }
                Auth.loginAndRedirect();
            }
        });
    };

    Auth.getMember = function () {
        $.ajax({
            type: 'GET',
            url: FunNowTools.API + '/member',
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    window.Freshchat.init(result.data);
                    Auth.setLoginCookie(result.data);
                    Auth.setHeaderLoginStatus('login');
                    Auth.checkMichelinPage(result.data);
                } else {
                    // TBD::get user data fail handle
                    console.log(result.message);
                }
            },
            error: function error(_error5) {
                window.Freshchat.init();
                console.error(_error5);
                // let result = error.responseJSON;
                // Auth.errorAlert('Get Member Error', result.message);
            }
        });
    };
    Auth.setLoginCookie = function (data, others) {
        $.cookie('FNaccount', data.account, { expires: 1, path: '/' });
        $.cookie('FNcurrency', data.currency, { expires: 1, path: '/' });
        for (var key in others) {
            $.cookie(key, others[key], { expires: 1, path: '/' });
        }
    };
    Auth.setHeaderLoginStatus = function (status) {
        if (status === 'login') {
            // Auth.View.headerLogin 移除 hidden
            Auth.View.headerLogin.each(function (i, v) {
                if ($(v).hasClass('hidden')) {
                    $(v).removeClass('hidden');
                }
            });
            // Auth.View.headerNotLogin 加入 hidden
            Auth.View.headerNotLogin.each(function (i, v) {
                if (!$(v).hasClass('hidden')) {
                    $(v).addClass('hidden');
                }
            });
        } else {
            // 登出狀態
            // Auth.View.headerLogin 加入 hidden
            Auth.View.headerLogin.each(function (i, v) {
                if (!$(v).hasClass('hidden')) {
                    $(v).addClass('hidden');
                }
            });
            // Auth.View.headerNotLogin 移除 hidden
            Auth.View.headerNotLogin.each(function (i, v) {
                if ($(v).hasClass('hidden')) {
                    $(v).removeClass('hidden');
                }
            });
        }
    };
    Auth.checkMichelinPage = function (data) {
        if (/orders\/michelin/.test(location.href)) {
            $('#memberMail').text(data.email);
        }
    };

    Auth.logout = function () {
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/logout',
            data: {
                push_token: ''
            },
            dataType: 'json',
            success: function success(result) {
                if (typeof result === 'string') {
                    result = JSON.parse(result);
                }
                // console.log(result);
                if (result.code === 0) {
                    if ($.cookie('promo') !== '') {
                        $.removeCookie('promo', { path: '/' });
                    }
                    var isLINEDomain = /line.myfunnow.com/.test(location.href);
                    if ($.cookie('Line_SID') !== undefined && !isLINEDomain) {
                        $.removeCookie('Line_SID', { path: '/' });
                    }
                    var isMichelineDomain = /michelin.myfunnow.com/.test(location.href);
                    if ($.cookie('MICHELIN') !== undefined && !isMichelineDomain) {
                        $.removeCookie('MICHELIN', { path: '/' });
                    }
                    $.removeCookie('FNaccount', { path: '/' });
                    $.removeCookie('FNcurrency', { path: '/' });
                    Auth.setHeaderLoginStatus('logout');
                }

                if (window.fcWidget) {
                    window.fcWidget.user.clear();
                }

                window.location.href = '/';
            }
        });
    };

    /**
     * 使用[FB 註冊/登入]按鈕
     */
    Auth.callFBLogin = function (link) {
        gtag('event', 'Login', {
            'event_category': 'Funnel',
            'event_label': 'FB'
        });

        // 收禮頁面點擊 FB註冊/登入時
        if (/\/gift\/[0-9a-z]+/.test(location.pathname)) {
            $.cookie('AcceptGift', '1', { expires: 1, path: '/' });
        }
        // 導向 facebook 進行 OAth2 授權，並讓 facebook redirect 回 /auth/fb-login
        window.location.href = link;
    };

    Auth.FBLogin = function (fbdata) {
        var data = {
            'fbToken': fbdata.fbtoken,
            'fbId': fbdata.id,
            'os': 'web',
            'osVersion': navigator.userAgent,
            'appVersion': '1.0',
            'deviceId': 'WEB-' + FunNowTools.UUID,
            'Accept-Language': LANGUAGE
        };

        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/fblogin',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    // 登入成功: 原有會員(清除收禮 cookie)
                    if ($.cookie('AcceptGift')) {
                        $.removeCookie('AcceptGift', { path: '/' });
                    }

                    gtag('event', 'Login', {
                        'event_category': 'Funnel',
                        'event_label': 'FB Success'
                    });
                    Auth.loginSuccess(result.data);
                } else {
                    // 登入失敗, 顯示fb註冊畫面
                    Auth.View.FBloginWrapper.removeClass('hidden');
                    Auth.View.loginLoading.removeClass('show');
                    Auth.View.initInviteCode();
                }
            },
            error: function error(_error6) {
                console.error(_error6);
                // 登入失敗, 顯示fb註冊畫面
                Auth.View.FBloginWrapper.removeClass('hidden');
                Auth.View.loginLoading.removeClass('show');
                Auth.View.initInviteCode();
            }
        });
    };

    Auth.FBRegistered = function (fbdata) {
        // fb-register
        // 註冊成功: 有收禮 cookie -> 發送完成任務 API -> 呼叫登入成功
        // 註冊失敗: 顯示錯誤訊息

        var data = {
            'username': fbdata.id,
            'gender': FunNowTools.genderCode(fbdata.gender),
            'currency': 0, // 0:NTD, 1:HKD, 2:JPY
            'birthday': FunNowTools.birthday(fbdata.birthday).replace(/\//g, '-'),
            'invitecode': fbdata.invitecode,
            'email': fbdata.email,
            'fromFacebook': true,
            'name': fbdata.name,
            'fbId': fbdata.id,
            'fbToken': fbdata.fbtoken,
            'os': 'web',
            'osVersion': navigator.userAgent,
            'appVersion': '1.0',
            'deviceId': 'WEB-' + FunNowTools.UUID,
            'Accept-Language': LANGUAGE // 全域變數,語系
        };

        var promo = $.cookie('promo');
        if (promo !== undefined) {
            // 註冊用了活動碼,就從cookie刪除,沒刪除的留給登入再使用
            $.removeCookie('promo', { path: '/' });
        }
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/register',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    console.log('Register successful -> Login');
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'FB'
                    });

                    // 收禮時,[FB註冊會員] 標註, 讓送禮的人達成任務
                    if ($.cookie('AcceptGift') === '1') {
                        $.cookie('AcceptGift', true, { expires: 1, path: '/' });
                    }

                    // 已經登入，導向該回去的頁面
                    Auth.loginSuccess(result.data);
                } else {
                    // FB 註冊按鈕解除封印
                    Auth.View.FBregisterBtn.removeClass('disabled').removeAttr('disabled');
                    // TBD::註冊失敗後處理
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'FB Fail'
                    });
                    Auth.FBRegisterFail(result.message);
                }
            },
            error: function error(_error7) {
                // 恢復註冊按鈕狀態
                Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
                // 註冊失敗後處理
                gtag('event', 'Register', {
                    'event_category': 'Funnel',
                    'event_label': 'FB Fail'
                });
                var result = _error7.responseJSON;
                Auth.FBRegisterFail(result.message);
            }
        });
    };

    Auth.FBRegisterFail = function () {
        var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

        var msg = message == '' ? i18n.t('FB registration failed') : message;
        Auth.errorAlert(i18n.t('Registration failed'), msg);
    };

    /**
     * LineCoupon 帶來的使用者登入處理
     */
    Auth.LINELogin = function (loginData) {
        var data = {
            'sid': loginData.sid,
            'sub': loginData.sub,
            'token': loginData.token,
            'os': 'web',
            'osVersion': navigator.userAgent,
            'appVersion': '1.0',
            'deviceId': 'WEB-' + FunNowTools.UUID
            // 'Accept-Language': LANGUAGE
        };
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/login/line',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    gtag('event', 'Login', {
                        'event_category': 'Funnel',
                        'event_label': 'LINE Success'
                    });
                    // 紀錄登入帳號資訊
                    Auth.setLoginCookie(result.data);
                    // 登入導向正確網址
                    Auth.loginAndRedirect();
                } else {
                    // 登入失敗, 自動幫忙註冊
                    Auth.LINERegister(loginData);
                }
            },
            error: function error(_error8) {
                console.error(_error8);
                // 登入失敗, 自動幫忙註冊
                Auth.LINERegister(loginData);
            }
        });
    };
    Auth.LINERegister = function (loginData) {
        // LINE_User {iss, aud, exp, sub, name, email, picture}
        var data = {
            'currency': 0, // 0:NTD, 1:HKD, 2:JPY
            'email': LINE_User.email,
            'fromLine': true,
            'name': LINE_User.name,
            'lineId': loginData.sid,
            'lineSub': loginData.sub,
            'lineToken': loginData.token,
            'linePicture': LINE_User.picture,
            'os': 'web',
            'osVersion': navigator.userAgent,
            'appVersion': '1.0',
            'deviceId': 'WEB-' + FunNowTools.UUID,
            'Accept-Language': LANGUAGE // 全域變數,語系
        };

        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/register',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    console.log('Register successful -> Login');
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'LINE'
                    });
                    // 紀錄登入帳號資訊
                    Auth.setLoginCookie(result.data);
                    // 已經登入，導向該回去的頁面
                    Auth.loginAndRedirect();
                } else {
                    // 註冊失敗後處理
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'LINE Fail'
                    });
                    Auth.LINERegisterFail(result.message);
                }
            },
            error: function error(_error9) {
                // 註冊失敗後處理
                gtag('event', 'Register', {
                    'event_category': 'Funnel',
                    'event_label': 'LINE Fail'
                });
                var result = _error9.responseJSON;
                Auth.LINERegisterFail(result.message);
            }
        });
    };
    Auth.LINERegisterFail = function () {
        var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

        var msg = message == '' ? 'LINE ' + i18n.t('Registration failed') : message;
        Auth.errorAlert(i18n.t('Registration failed'), msg, function () {
            window.location.href = $.cookie('RedirectTo') || '/';
        });
    };

    /**
     * Cathay My Rewards 跳轉頁面登入/註冊 後再跳轉
     */
    Auth.CathayLogin = function (queryData) {
        var loginData = Object.assign({
            os: 'web',
            osVersion: navigator.userAgent,
            appVersion: '1.0',
            deviceId: 'WEB-' + FunNowTools.UUID
        }, queryData);

        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/login/cathay',
            data: loginData,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    gtag('event', 'Login', {
                        'event_category': 'Funnel',
                        'event_label': 'CathayMR Success'
                    });
                    // 紀錄登入帳號資訊
                    Auth.setLoginCookie(result.data, { 'HideNav': 'cathay' });
                    // 登入導向正確網址
                    var replace = true;
                    Auth.loginAndRedirect(replace);
                } else if (result.code === 16) {
                    // 登入失敗(尚未註冊), 自動幫忙註冊
                    Auth.CathayRegister(loginData);
                } else {
                    Auth.CathayLoginFail(result);
                }
            },
            error: function error(_error10) {
                // console.error(error)
                var errData = _error10.responseJSON;
                // 登入失敗, 導向404頁面
                Auth.CathayLoginFail(errData);
            }
        });
    };
    Auth.CathayLoginFail = function (data) {
        var msg = data.message || 'Cathay My Rewards Login Fail!';
        Auth.errorAlert(i18n.t('Registration failed'), msg, function () {
            window.location.href = '/error/404';
        });
    };
    Auth.CathayRegister = function (loginData) {
        // let username = loginData.user_id + '@cathay.member'
        var data = {
            'currency': 0, // 0:NTD, 1:HKD, 2:JPY
            'fromCathay': true,
            'cathayUserID': loginData.user_id,
            'cathayToken': loginData.token,
            'name': 'Cathay member',
            'os': loginData.os,
            'osVersion': loginData.osVersion,
            'appVersion': loginData.appVersion,
            'deviceId': loginData.deviceId,
            'Accept-Language': LANGUAGE // 全域變數,語系
        };

        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/register',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.code === 0) {
                    console.log('Register successful -> Login');
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'CathayMR'
                    });
                    // 紀錄登入帳號資訊
                    Auth.setLoginCookie(result.data, { 'HideNav': 'cathay' });
                    // 已經登入，導向該回去的頁面
                    var replace = true;
                    Auth.loginAndRedirect(replace);
                } else {
                    // 註冊失敗後處理
                    gtag('event', 'Register', {
                        'event_category': 'Funnel',
                        'event_label': 'CathayMR Fail'
                    });
                    Auth.CathayRegisterFail(result.message);
                }
            },
            error: function error(_error11) {
                // 註冊失敗後處理
                gtag('event', 'Register', {
                    'event_category': 'Funnel',
                    'event_label': 'CathayMR Fail'
                });
                var result = _error11.responseJSON;
                Auth.CathayRegisterFail(result.message);
            }
        });
    };
    Auth.CathayRegisterFail = function () {
        var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

        var msg = message == '' ? 'Cathay My Rewards ' + i18n.t('Registration failed') : message;
        Auth.errorAlert(i18n.t('Registration failed'), msg, function () {
            window.location.href = $.cookie('RedirectTo') || '/';
        });
    };

    Auth.errorAlert = function (title, message) {
        var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

        Auth.Simplert(title, message, 'warning', callback);
    };
    Auth.Simplert = function (title, message, type) {
        var callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

        swal({
            title: title,
            text: message,
            type: type,
            confirmButtonColor: '#3085d6',
            confirmButtonText: i18n.t('OK')
        }).then(function () {
            if (typeof callback === 'function') {
                callback();
            }
        });
    };

    Auth.forgotPassword = function (form) {
        var email = form.find('input[name="email"]').val().trim();
        if (email === '') {
            return null;
        }

        var data = {
            'email': email
        };
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/password/recover',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                gtag('event', 'Login', {
                    'event_category': 'Funnel',
                    'event_label': 'Forgot Password'
                });
                if (result.code === 0) {
                    Auth.ShowMsg(i18n.t('Please check your email'));
                } else {
                    Auth.ShowErrorMsg(result.message);
                    console.log(result.message);
                }
            },
            error: function error(_error12) {
                var result = _error12.responseJSON;
                Auth.ShowErrorMsg(result.message);
            }
        });
    };

    Auth.resetPassword = function (form) {
        var pwd = form.find('input[name="password"]').val().trim();
        var pwd2 = form.find('input[name="password_again"]').val().trim();
        if (pwd === '' || pwd2 === '') return false;
        if (pwd !== pwd2) {
            Auth.ShowErrorMsg(i18n.t('密碼不一致'));
            return false;
        }

        var data = {
            token: FunNowTools.getParameter('token'),
            password: pwd
        };
        $.ajax({
            type: 'POST',
            url: FunNowTools.API + '/password/reset',
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                gtag('event', 'Login', {
                    'event_category': 'Funnel',
                    'event_label': 'Reset Password'
                });
                if (result.code === 0) {
                    Auth.Simplert('', i18n.t('密碼變更成功，請重新登入'), 'success', function () {
                        location.href = '/';
                    });
                } else {
                    Auth.ShowErrorMsg(result.message);
                    console.log(result.message);
                }
            },
            error: function error(_error13) {
                var result = _error13.responseJSON;
                Auth.ShowErrorMsg(result.message);
            }
        });
    };

    Auth.UpdateFBData = function () {
        var inviteCode = $.cookie('invitecode') || $.cookie('promo') || '';
        FBUser.name = Auth.View.registerForm.find('input[name="nickname"]').val();
        FBUser.email = Auth.View.registerForm.find('input[name="email"]').val();
        FBUser.gender = Auth.View.registerForm.find('input[name="gender"]:checked').val();
        FBUser.birthday = Auth.View.registerForm.find('input[name="birthday"]').val();
        FBUser.invitecode = Auth.View.registerForm.find('input[name="invitecode"]').val() || inviteCode;
        return FBUser;
    };

    Auth.View.loginForm.submit(function (e) {
        e.preventDefault();
    });
    Auth.View.loginBtn.on('click', function (e) {
        e.preventDefault();
        // 重新指定loginForm
        Auth.View.loginForm = $(this).parents('form');
        Auth.HideMsg();
        // 封印登入按鈕
        $(this).addClass('disabled').attr('disabled', 'disabled');
        var name = Auth.View.loginForm.find('input[name="username"]').val();
        var password = Auth.View.loginForm.find('input[name="password"]').val();
        Auth.login(name, password, true, function () {
            location.reload();
        });
    });

    Auth.View.forgotBtn.on('click', function (e) {
        e.preventDefault();
        Auth.HideMsg();
        Auth.forgotPassword(Auth.View.forgotForm);
    });

    Auth.View.resetBtn.on('click', function (e) {
        e.preventDefault();
        Auth.HideMsg();
        Auth.resetPassword(Auth.View.resetForm);
    });

    Auth.View.logoutBtn.on('click', function (e) {
        e.preventDefault();
        Auth.logout();
    });

    Auth.View.logoutBtnxs.on('click', function (e) {
        e.preventDefault();
        Auth.logout();
    });

    Auth.View.FBloginBtn.on('click', function (e) {
        e.preventDefault();
        Auth.View.loginWrapper.addClass('hidden');
        Auth.View.loginLoading.addClass('show');

        // FB redirect 網址內不能有 location.hash 資料
        var redirectTo = FunNowTools.RedirectTo;
        if (redirectTo !== '') {
            if (redirectTo.indexOf('#') > 0) {
                redirectTo = redirectTo.substr(0, redirectTo.indexOf('#'));
            }
            $.cookie('RedirectTo', redirectTo, { expires: 1, path: '/' });
            FunNowTools.setRedirect(redirectTo);
        }
        // 封印 FB 註冊登入按鈕
        $(this).addClass('disabled').attr('disabled', 'disabled');

        var link = $(this).data('href');
        Auth.callFBLogin(link);
        // Auth.updateFBStatus(Auth.callFBLogin, [link]);
    });

    Auth.View.FBregisterBtn.on('click', function (e) {
        e.preventDefault();
        // 封印FB註冊按鈕
        $(this).addClass('disabled').attr('disabled', 'disabled');

        var fbdata = Auth.UpdateFBData();
        Auth.FBRegistered(fbdata);
    });

    Auth.View.switchLink.on('click', function () {
        Auth.View.switchBlock = $(this).parents('.switch_block');
        var show = Auth.View.switchBlock.find('form:not(.hidden)');
        var hide = Auth.View.switchBlock.find('form.hidden');
        show.addClass('hidden');
        hide.removeClass('hidden');
        Auth.HideMsg();
        // 更換標題
        if (show.attr('id') === 'loginForm') {
            Auth.View.modalTitle.text(i18n.t('register_now'));
        } else {
            Auth.View.modalTitle.text(i18n.t('會員登入'));
        }
        // 切換[會員登入]與[免費註冊]文字
        $(this).parent().addClass('hidden');
        $(this).parent().siblings('p.hidden').removeClass('hidden');
    });

    Auth.View.registerBtn.on('click', function (e) {
        e.preventDefault();
        // 重新定位registerForm
        Auth.View.registerForm = $(this).parents('form');
        // 封印註冊按鈕
        $(this).addClass('disabled').attr('disabled', 'disabled');
        Auth.HideMsg();
        Auth.CheckEmail(Auth.View.registerForm, Auth.Registered.bind(this, Auth.View.registerForm));
    });

    Auth.View.showPwd.on('click', function () {
        var dispalyPwd = $(this).attr('display-pwd');
        dispalyPwd = dispalyPwd === undefined ? 'hide' : dispalyPwd;
        if (dispalyPwd === 'hide') {
            $(this).siblings('input[type="password"]').attr('type', 'text');
            $(this).attr('display-pwd', 'show');
            $(this).addClass('dark');
        } else if (dispalyPwd === 'show') {
            $(this).siblings('input[type="text"]').attr('type', 'password');
            $(this).attr('display-pwd', 'hide');
            $(this).removeClass('dark');
        }
    });

    Auth.View.initInviteCode = function () {
        var inviteCode = $.cookie('invitecode') || $.cookie('promo') || '';
        $.removeCookie('invitecode', { path: '/' });
        $('input[name="invitecode"]').val(inviteCode);
    };

    // 在 /auth/fb-login 頁面, fb 取得授權後導回頁面
    if (Auth.View.FBloginWrapper.length > 0) {
        if (typeof ERROR !== 'undefined' && ERROR !== '') {
            Auth.errorAlert(i18n.t('FB authorization failed'), ERROR, function () {
                window.location.href = '/auth/login';
            });
        }

        // 自動幫忙用 fbdata 登入,
        // 登入失敗後，留在原本頁面讓使用者自行註冊
        Auth.FBLogin(FBUser);
    }

    // 在 /auth/line-login 頁面, 取得LINE相關資料後返回頁面
    if (Auth.View.LINEWrapper.length > 0) {
        if (typeof ERROR !== 'undefined' && ERROR !== '') {
            Auth.errorAlert(ERROR, ERROR_MSG, function () {
                window.location.href = $.cookie('RedirectTo') || '/';
            });
        }
        Auth.LINELogin(LINE_Login);
    }

    // 在 /cathay-my-rewards 頁面, 取得國泰相關資料後頁面處理
    if (Auth.View.CathayMR.length > 0) {
        var queryData = {
            'timestamp': FunNowTools.getParameter('x-3rd-timestamp'),
            'vendor_id': FunNowTools.getParameter('x-3rd-vendor-id'),
            'platform_id': FunNowTools.getParameter('x-3rd-platform-id'),
            'app_id': FunNowTools.getParameter('x-3rd-app-id'),
            'user_id': FunNowTools.getParameter('x-3rd-user-id'),
            'custom_data': FunNowTools.getParameter('x-3rd-custom-data'),
            'token': FunNowTools.getParameter('x-3rd-token'),
            'short_id': FunNowTools.getParameter('x-3rd-short-id'),
            'signature': FunNowTools.getParameter('x-3rd-signature')
        };
        var nextUrl = FunNowTools.getParameter('x-3rd-next-url');
        if (nextUrl) {
            if (nextUrl.includes('/orders/#/')) {
                nextUrl = nextUrl.replace('/orders/#/', '/orders/');
            }
            $.cookie('RedirectTo', nextUrl, { expires: 1, path: '/' });
            FunNowTools.setRedirect(nextUrl);
        }
        Auth.CathayLogin(queryData);
    }

    // 透過檢查 member info 判斷登入狀態
    Auth.getMember();

    // Email auto-complete
    var autoComeleteOption = {
        domains: FunNowTools.domains
    };
    if (isMobile.any === false && /(Chrome|Firefox)/.test(navigator.userAgent)) {
        autoComeleteOption.suggClass = 'email-suggest';
    }
    $('input:not(.not-eac)[type="email"]').emailautocomplete(autoComeleteOption);

    // 快速登入Modal視窗關閉後檢查
    $('#quickLoginModal').on('hidden.bs.modal', function () {
        if (Auth.View.FBloginBtn.hasClass('disabled')) {
            Auth.View.FBloginBtn.removeClass('disabled').removeAttr('disabled');
        }
        if (Auth.View.loginBtn.hasClass('disabled')) {
            Auth.View.loginBtn.removeClass('disabled').removeAttr('disabled');
        }
        if (Auth.View.registerBtn.hasClass('disabled')) {
            Auth.View.registerBtn.removeClass('disabled').removeAttr('disabled');
        }
    });
});