'use strict';

var FunNowTools = function () {
    /*
     * 頻率控制 返回函數連續調用時，fn 執行頻率限定為每多少時間執行一次
     * @param fn {function} 需要調用的函數
     * @param delay {number} 延遲時間，單位毫秒
     * @param immediate {bool} 給 immediate參數傳遞false 綁定的函數先執行，而不是delay後後執行。
     * @return {function}實際調用函數
     */
    var throttle = function throttle(fn, delay, immediate, debounce) {
        var curr = +new Date(); //當前事件
        var last_call = 0,
            last_exec = 0,
            timer = null;
        //  時間差, 上下文,
        var diff, context, args;
        var exec = function exec() {
            last_exec = curr;
            fn.apply(context, args);
        };
        return function () {
            curr = +new Date();
            context = this, args = arguments, diff = curr - (debounce ? last_call : last_exec) - delay;
            clearTimeout(timer);
            if (debounce) {
                if (immediate) {
                    timer = setTimeout(exec, delay);
                } else if (diff >= 0) {
                    exec();
                }
            } else {
                if (diff >= 0) {
                    exec();
                } else if (immediate) {
                    timer = setTimeout(exec, -diff);
                }
            }
            last_call = curr;
        };
    };
    /*
     * 空閒控制 返回函數連續調用時，空閒時間必須大於或等於 delay，fn 才會執行
     * @param fn {function} 要調用的函數
     * @param delay {number} 空閒時間
     * @param immediate {bool} 給 immediate參數傳遞false 綁定的函數先執行，而不是delay後後執行。
     * @return {function}實際調用函數
     */
    var debounce = function debounce(fn, delay, immediate) {
        return throttle(fn, delay, immediate, true);
    };
    var getGMT = function getGMT() {
        return new Date().getTimezoneOffset() / -60;
    };
    var getGenderCode = function getGenderCode(gender) {
        switch (gender) {
            case 'male':
                return 1;
            case 'female':
                return 0;
            default:
                return 1;
        }
    };
    var getToday = function getToday() {
        var date = new Date();
        var today = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        return today;
    };
    var getBirthday = function getBirthday(birthday) {
        var correctRe = /\d{4}\/\d{2}\/\d{2}/;
        var dashRe = /\d{4}\-\d{2}\-\d{2}/;
        var re = /(\d{2})\/(\d{2})\/(\d{4})/;

        if (correctRe.test(birthday)) {
            return birthday;
        } else if (dashRe.test(birthday)) {
            return birthday.replace(/\-/g, '/');
        } else if (re.test(birthday)) {
            return birthday.replace(re, function (a, b, c, d) {
                return d + '/' + b + '/' + c;
            });
        }
        return '';
    };
    var s4 = function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };
    var guid = function guid() {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    var getParameterByName = function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&|#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };
    // 替換 block-loading
    var replaceBlockLoding = function replaceBlockLoding(dom, imageUrl) {
        var img = new Image();
        img.src = imageUrl;

        img.onload = function () {
            dom.style.backgroundImage = 'url(\'' + imageUrl + '\')';
            dom.classList.remove('block-loading');
        };
    };
    // 設定客製化 Slider
    var setCustomSlider = function setCustomSlider(HTMLElement) {
        var margin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
        var column = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

        var smBetween = margin / 2 > 10 ? 15 : 10;
        var swiper = new Swiper(HTMLElement, {
            slidesPerColumn: column,
            slidesPerView: 'auto',
            spaceBetween: parseInt(margin),
            paginationClickable: true,
            touchReleaseOnEdges: true,
            prevButton: '.c-slider__btn.prev',
            nextButton: '.c-slider__btn.next',
            breakpoints: {
                991: {
                    spaceBetween: 10
                },
                1199: {
                    spaceBetween: smBetween
                }
            }
        });
        return swiper;
    };
    // 取得部落格文章
    var getBlogArticle = function getBlogArticle() {
        var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        if (id === '') return false;
        var url = '/blog/' + id + '?type=json';
        $.get(url, function (data) {
            if (data && typeof callback === 'function') {
                callback.call(context, data);
            }
        });
    };
    // 登入後重新導向原本網址
    var RedirectTo = function RedirectTo() {
        var reference = document.referrer;
        if (/myfunnow.com/.test(reference) === false || /auth/.test(reference)) {
            // 前一頁不在 funnow 或 auth 功能下(忘記密碼,登入等)
            // 登入後頁面導向首頁
            reference = '/';
        }
        var cookieReTo = $.cookie('RedirectTo');
        var redirectTo = getParameterByName('redirect');
        redirectTo = cookieReTo !== undefined ? cookieReTo : redirectTo === null ? reference : redirectTo;
        // redirectTo = (redirectTo === null) ? reference : redirectTo;
        return redirectTo;
    };
    var setRedirect = function setRedirect(url) {
        this.RedirectTo = url;
    };
    // [任務頁面] 取得網址上任務id
    var getMissionId = function getMissionId() {
        var matches = location.pathname.match(/\/mission\/([0-9]+)$/);
        if (matches && matches.length > 1) {
            return matches[1];
        }
        return false;
    };
    // 隱藏header, footer
    var hiddenHeaderFooter = function hiddenHeaderFooter() {
        $('#appdownload').remove(); // android 上方 smartbanner
        $('header').hide();
        $('body').css('padding-top', '0');
        $('footer').hide();
        // product 頁面 css 修正
        $('nav').css('top', '0');
        // blog 文章頁面 css 修正
        $('.reading-progress-bar').addClass('no-header');
    };
    // 檢查 cookie 內, 是否需要隱藏 header, footer
    var checkHiddenCookie = function checkHiddenCookie() {
        var hidden = $.cookie('hidden_header_footer');
        if (hidden !== undefined && hidden === '1' && isMobile.phone) {
            hiddenHeaderFooter();
        }
    };
    // 提供 APP webview 呼叫
    var hideHeaderFooter = function hideHeaderFooter() {
        $.cookie('hidden_header_footer', 1, { expires: 1, path: '/' });
        hiddenHeaderFooter();
    };
    // 添加語系前綴網址
    var prefixLangURL = function prefixLangURL() {
        var prefix = '';
        switch (LANGUAGE) {
            case 'zh-CN':
                prefix += '/cn';
                break;
            case 'ja-JP':
                prefix += '/jp';
                break;
            case 'en-US':
                prefix += '/en';
                break;
            default:
                break;
        }
        return prefix;
    };
    // 取出部分內容
    var subRawHTML = function subRawHTML(html) {
        var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;

        var raw,
            tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        raw = tmp.textContent || tmp.innerText || '';
        raw = raw.replace(/&nbsp;/ig, '');
        raw = raw.substr(0, length);
        return raw;
    };
    // 檢查iOS裝置下的APP webview
    var iOSWebView = function iOSWebView() {
        var standalone = window.navigator.standalone,
            userAgent = window.navigator.userAgent.toLowerCase(),
            isLine = /line/.test(userAgent),
            safari = /safari/.test(userAgent),
            ios = /iphone|ipod|ipad/.test(userAgent);
        var isWebView = false;

        if (ios) {
            if (isLine) {
                // Line web view
                isWebView = true;
            } else if (!standalone && safari) {
                //browser
            } else if (standalone && !safari) {
                //standalone
                isWebView = true;
            } else if (!standalone && !safari) {
                //uiwebview
                isWebView = true;
            }
        } else {
            //not iOS
            console.log('not iOS');
        }
        return isWebView;
    };
    // 發送 Floodlight 代碼
    var sendFloodlightCode = function sendFloodlightCode(cat) {
        var id_name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DCLK_FLDiv';

        if (document.querySelector('#' + id_name) !== null) {
            return false;
        }
        var frameId = id_name + '_frame';
        var axel = Math.random() + '';
        var a = axel * 10000000000000000;
        var u1 = id_name.indexOf('thank') >= 0 ? 'u1=[Order];' : '';
        var flDiv = document.body.appendChild(document.createElement('div'));
        flDiv.setAttribute('id', id_name);
        flDiv.style.position = 'absolute';
        flDiv.style.top = '0';
        flDiv.style.left = '0';
        flDiv.style.width = '1px';
        flDiv.style.height = '1px';
        flDiv.style.display = 'none';
        var iframeHTML = '<iframe id=' + frameId + '\n        src="https://8254593.fls.doubleclick.net/activityi;src=8254593;type=invmedia;cat=' + cat + ';' + u1 + 'dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?"\n        width="1" height="1" frameborder="0" style="display:none"></iframe>';
        flDiv.innerHTML = iframeHTML;
        console.log('send fls.doubleclick, cat = ' + cat);
    };
    var insertStringAt = function insertStringAt(original, index, partStr) {
        return original.substr(0, index) + partStr + original.substr(index);
    };
    var htmlEntities = function htmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    };
    var thousandComma = function thousandComma(number) {
        var num = number.toString();
        var pattern = /(-?\d+)(\d{3})/;

        while (pattern.test(num)) {
            num = num.replace(pattern, '$1,$2');
        }
        return num;
    };
    // 取出圖片縮圖網址 images/bid_001/00.jpg -> images/bid_001/thumbnail/00.jpg
    var thumbnailImage = function thumbnailImage(path) {
        if (path === '') {
            return 'web/images/default.png';
        }
        if (path.startsWith('/')) {
            path = path.substr(1);
        }

        var index = path.lastIndexOf('/');
        var prefix = path.substring(0, index);
        var suffix = path.substr(index);
        return prefix + '/thumbnail' + encodeURIComponent(suffix);
    };
    var coverImage = function coverImage(path) {
        if (path === '') {
            return 'web/images/default.png';
        }
        if (path.startsWith('/')) {
            path = path.substr(1);
        }
        var index = path.lastIndexOf('/');
        var prefix = path.substring(0, index);
        var suffix = path.substr(index);
        return prefix + encodeURIComponent(suffix);
    };
    // 偵測作業系統
    var iOSversion = function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            // supports iOS 2.0 and later: <https://bit.ly/TJjs1V>
            var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        } else {
            return false;
        }
    };
    var nl2br = function nl2br(str, is_xhtml) {
        var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    };
    var getAPIBaseUrl = function getAPIBaseUrl() {
        // let api = 'https://stg-api-go.myfunnow.com/v2/funnow';
        // // 判斷是否為local開發環境
        // if (location.hostname.startsWith('dev')) {
        //     api = 'http://localhost:8080/v2/funnow';
        // }
        // // 判斷是否為 prod 環境
        // if (location.hostname === 'www.myfunnow.com' || location.hostname === 'v2www.myfunnow.com') {
        //     api = 'https://api-go.myfunnow.com/v2/funnow';
        // }
        // // 判斷是否為 stg2
        // if (location.hostname.startsWith('stg2-www')) {
        //     api = 'https://stg-api-go.myfunnow.com/v2/funnow';
        // }
        // return api;
        return GLOBAL.API_URI;
    };

    var domains = ['aol.com', 'att.net', 'comcast.net', 'facebook.com', 'gmail.com', 'gmx.com', 'googlemail.com', 'google.com', 'hotmail.com', 'hotmail.co.uk', 'mac.com', 'me.com', 'mail.com', 'msn.com', 'live.com', 'sbcglobal.net', 'verizon.net', 'yahoo.com', 'yahoo.com.tw', 'yahoo.co.uk', 'email.com', 'fastmail.fm', 'games.com' /* AOL */, 'gmx.net', 'hush.com', 'hushmail.com', 'icloud.com', 'iname.com', 'inbox.com', 'lavabit.com', 'love.com' /* AOL */, 'outlook.com', 'pobox.com', 'protonmail.com', 'rocketmail.com' /* Yahoo */, 'safe-mail.net', 'wow.com' /* AOL */, 'ygm.com' /* AOL */, 'ymail.com' /* Yahoo */, 'zoho.com', 'yandex.com', 'bellsouth.net', 'charter.net', 'cox.net', 'earthlink.net', 'juno.com', 'btinternet.com', 'virginmedia.com', 'blueyonder.co.uk', 'freeserve.co.uk', 'live.co.uk', 'ntlworld.com', 'o2.co.uk', 'orange.net', 'sky.com', 'talktalk.co.uk', 'tiscali.co.uk', 'virgin.net', 'wanadoo.co.uk', 'bt.com', 'sina.com', 'qq.com', 'naver.com', 'hanmail.net', 'daum.net', 'nate.com', 'yahoo.co.jp', 'yahoo.co.kr', 'yahoo.co.id', 'yahoo.co.in', 'yahoo.com.sg', 'yahoo.com.ph', 'hotmail.fr', 'live.fr', 'laposte.net', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'gmx.fr', 'sfr.fr', 'neuf.fr', 'free.fr', 'gmx.de', 'hotmail.de', 'live.de', 'online.de', 't-online.de' /* T-Mobile */, 'web.de', 'yahoo.de', 'libero.it', 'virgilio.it', 'hotmail.it', 'aol.it', 'tiscali.it', 'alice.it', 'live.it', 'yahoo.it', 'email.it', 'tin.it', 'poste.it', 'teletu.it', 'mail.ru', 'rambler.ru', 'yandex.ru', 'ya.ru', 'list.ru', 'hotmail.be', 'live.be', 'skynet.be', 'voo.be', 'tvcablenet.be', 'telenet.be', 'hotmail.com.ar', 'live.com.ar', 'yahoo.com.ar', 'fibertel.com.ar', 'speedy.com.ar', 'arnet.com.ar', 'yahoo.com.mx', 'live.com.mx', 'hotmail.es', 'hotmail.com.mx', 'prodigy.net.mx', 'yahoo.com.br', 'hotmail.com.br', 'outlook.com.br', 'uol.com.br', 'bol.com.br', 'terra.com.br', 'ig.com.br', 'itelefonica.com.br', 'r7.com', 'zipmail.com.br', 'globo.com', 'globomail.com', 'oi.com.br'];

    var findGetParameter = function findGetParameter() {
        var result = {};
        location.search.substr(1).split('&').forEach(function (item) {
            var tmp = item.split('=');
            var parameter = (tmp[1] || '').replace(/\+/g, ' ');
            result[tmp[0]] = decodeURIComponent(parameter);
        });
        return result;
    };

    return {
        API: getAPIBaseUrl(),
        debounce: debounce,
        GMT: getGMT(),
        UUID: guid(),
        Today: getToday(),
        RedirectTo: RedirectTo(),
        setRedirect: setRedirect,
        genderCode: getGenderCode,
        birthday: getBirthday,
        getParameter: getParameterByName,
        MissionId: getMissionId(),
        checkHiddenCookie: checkHiddenCookie,
        hideHeaderFooter: hideHeaderFooter,
        replaceLoadingBlock: replaceBlockLoding,
        setSlider: setCustomSlider,
        getArticle: getBlogArticle,
        prefixUrl: prefixLangURL(),
        subContent: subRawHTML,
        isWebView: iOSWebView(),
        sendFL: sendFloodlightCode,
        insertStr: insertStringAt,
        domains: domains,
        htmlEntities: htmlEntities,
        thousandComma: thousandComma,
        getThumbnail: thumbnailImage,
        getCover: coverImage,
        iOSversion: iOSversion(),
        nl2br: nl2br,
        findGetParameter: findGetParameter
    };
}();

FunNowTools.checkHiddenCookie();

// 取得地理資訊, 登入時會用到
var GEOLOCATION = {
    latitude: 25.059730,
    longitude: 121.523146
};
function GEOException(message) {
    this.message = message;
    this.name = 'GEOException';
}
GEOLOCATION.setPosition = function (position) {
    GEOLOCATION.latitude = position.coords.latitude;
    GEOLOCATION.longitude = position.coords.longitude;
};
GEOLOCATION.userDenied = function (err) {
    throw new GEOException(err.message);
};
GEOLOCATION.getLocation = function (callback) {
    if (navigator.geolocation) {
        if (typeof callback === 'function') {
            navigator.geolocation.getCurrentPosition(callback, GEOLOCATION.userDenied);
        } else {
            navigator.geolocation.getCurrentPosition(GEOLOCATION.setPosition, GEOLOCATION.userDenied);
        }
    } else {
        console.info('Geolocation is not supported by this browser.');
    }
    GLOBAL.GEO = this;
};