'use strict';

$(function () {
    'use strict';

    var isCategory = /\/categories\/[0-9]+\-[0-9]/.test(location.pathname);
    var isThemes = /\/themes\/[0-9]+\-[0-9]/.test(location.pathname);
    var isSearch = /\/search/.test(location.pathname);
    var watchPos = undefined;

    var DateHelper = {
        createWeekDates: function createWeekDates() {
            var week = [];
            var tmp = moment();
            for (var i = 0; i < 7; i++) {
                week.push({
                    date: tmp.format('YYYY/MM/DD'),
                    day: tmp.day()
                });
                tmp.add(1, 'days');
            }
            return week;
        },
        getMonthObj: function getMonthObj(lastMonth) {
            var monthObj = [];
            var curr = moment();
            var currMonth = moment({ year: curr.year(), month: curr.month(), day: 1 });
            while (currMonth.unix() <= lastMonth.unix()) {
                monthObj.push({
                    year: currMonth.year(),
                    month: currMonth.month(),
                    firstDay: currMonth.day(),
                    maxDays: currMonth.daysInMonth(),
                    rowCount: this.getMonthRowCount(currMonth.year(), currMonth.month())
                });
                currMonth.add(1, 'months');
            }
            return monthObj;
        },
        getMonthRowCount: function getMonthRowCount(year, month) {
            var currMonth = moment({ year: year, month: month, day: 1 });
            var startDay = currMonth.day();
            var dayCounts = currMonth.daysInMonth();
            var weeks = Math.floor((dayCounts + startDay) / 7);
            var remain = (dayCounts + startDay) % 7;
            return remain === 0 ? weeks : weeks + 1;
        },
        getCalendarDate: function getCalendarDate(lastMonth) {
            var monthObj = [];
            var curr = moment();
            var currMonth = moment({ year: curr.year(), month: curr.month(), day: 1 });
            while (currMonth.unix() <= lastMonth.unix()) {
                monthObj.push({
                    year: currMonth.year(),
                    month: currMonth.month(),
                    firstDay: currMonth.day(),
                    maxDays: currMonth.daysInMonth(),
                    rowCount: this.getMonthRowCount(currMonth.year(), currMonth.month())
                });
                currMonth.add(1, 'months');
            }
            return monthObj;
        }
    };

    function NoResult() {
        /**
        <div class="filter-panel">
          <div class="empty-result">
            <img src="/images/icon/nosearch.svg" alt="no-result">
            <p>搜尋條件太嚴格囉！<br>試試擴大搜尋範圍吧～</p>
          </div>
        </div>
        */
        var itemDOM = document.createElement('div');
        itemDOM.setAttribute('class', 'filter-panel');
        var noResultText = i18n.t('搜尋條件太嚴格囉！<br>試試擴大搜尋範圍吧～');
        $(itemDOM).append('\n            <div class="empty-result">\n                <img src="/images/icon/nosearch.svg" alt="no-result">\n                <p>' + noResultText + '</p>\n            </div>');

        return $(itemDOM);
    }

    function Loading() {
        var itemDOM = $('.branchs-loading');
        return itemDOM;
    }

    // function ADImage() {
    //     let html = '<div class="funnow-propaganda-image hidden"></div>';
    //     return {dom:html};
    // }

    function CalendarSlide(data, customDate) {
        var tempDay = moment({ y: data.year, M: data.month, d: 1 });
        var today = moment(moment().format('YYYY-MM-DD'));
        var lastDay = moment(moment().add(30, 'days').format('YYYY-MM-DD'));
        var emptyDate = '<div class="item"></div>';
        var rowData = '';

        function createSingleDateItem(dd, customDate) {
            var appendClass = '';
            if (dd.unix() < today.unix() || dd.unix() > lastDay.unix()) {
                appendClass = ' disabled';
            } else {
                // 已選 start
                if (customDate.start !== null && dd.unix() >= moment(customDate.start).unix()) {
                    if (customDate.end !== null && dd.unix() <= moment(customDate.end).unix()) {
                        // 已選 start, end
                        appendClass = ' enabled';
                    } else if (dd.unix() === moment(customDate.start).unix()) {
                        appendClass = ' enabled';
                    }
                }
            }
            var item = '<div class="item' + appendClass + '" value="' + tempDay.format('YYYYMMDD') + '">' + tempDay.format('D') + '</div>';
            dd.add(1, 'days');
            return item;
        }

        for (var i = 0; i < data.rowCount; i++) {
            rowData += '<div class="row">';
            if (i === 0) {
                rowData += emptyDate.repeat(data.firstDay);
                for (var j = data.firstDay; j < 7; j++) {
                    rowData += createSingleDateItem(tempDay, customDate);
                }
            } else {
                for (var _j = 0; _j < 7; _j++) {
                    if (tempDay.date() <= data.maxDays && tempDay.month() === data.month) {
                        rowData += createSingleDateItem(tempDay, customDate);
                    } else {
                        rowData += emptyDate;
                    }
                }
            }
            rowData += '</div>';
        }

        var slideContent = '\n        <div class="row">\n            <div class="item week">\u65E5</div><div class="item week">\u4E00</div><div class="item week">\u4E8C</div><div class="item week">\u4E09</div><div class="item week">\u56DB</div><div class="item week">\u4E94</div><div class="item week">\u516D</div>\n        </div>\n        ' + rowData;

        return {
            dom: slideContent
        };
    }

    function FilterItem(type, text, value, callback) {
        // var html = html = `<div class="tag" data-type="${this.type}" data-value="${this.value}">${this.text} <span class="close"></span></div>`;;
        var close = document.createElement('span');
        close.setAttribute('class', 'close');

        var itemDOM = document.createElement('div');
        itemDOM.textContent = text;
        itemDOM.setAttribute('class', 'tag');
        itemDOM.setAttribute('data-type', type);
        itemDOM.setAttribute('data-value', value);
        // 非地區tag才加上 X
        if (type !== 'regionid') {
            itemDOM.appendChild(close);
            itemDOM.lastElementChild.onclick = function (e) {
                var current = e.currentTarget;
                callback(current.parentNode);
            };
        }

        var setDom = function setDom(newDom) {
            this.dom = newDom;
        };

        return {
            dom: itemDOM,
            type: type,
            text: text,
            value: value,
            setDom: setDom
        };
    }

    function BranchCard(branch) {
        var generateStar = function generateStar(num) {
            var stars = '';
            for (var i = 0; i < num; i++) {
                stars += '<img src="/images/icon/star.svg" alt="star" class="svg-icon svg-icon--lg">';
            }
            return stars;
        };
        var generateEmptyStar = function generateEmptyStar(num) {
            var emptyStars = '';
            for (var i = 0; i < num; i++) {
                emptyStars += '<img src="/images/icon/star-o.svg" alt="star-o" class="svg-icon svg-icon--lg">';
            }
            return emptyStars;
        };
        var branchNameEn = branch.branchname_en;
        var branchName = branch.branchname;
        var imgThumb = GLOBAL.CDN_URI + FunNowTools.getThumbnail(branch.branchimage);
        var imgSrc = GLOBAL.CDN_URI + FunNowTools.getCover(branch.branchimage);
        var rating = branch.rated === '0' ? '5.0' : branch.rating;
        var starsCount = parseInt(Math.round(parseFloat(rating)));
        var emptyStarsCount = 5 - starsCount;
        var ratingStars = generateStar(starsCount) + generateEmptyStar(emptyStarsCount);

        var traficInfo = '',
            buyers = '',
            priceFrom = '';
        if (branch.transportation.length > 0) {
            var temp = branch.transportation.replace(/[・|．]/g, '');
            temp = FunNowTools.nl2br(temp);
            traficInfo = '<p class="card-info__description">' + temp + '</p>';
        }
        if (parseInt(branch.rated) > 0) {
            var _temp = i18n.t('已有 %1 人購買', branch.rated);
            buyers = '<p class="card-info__description">' + _temp + '</p>';
        }
        var tempPrice = branch.currency + ' ' + branch.discount;
        priceFrom = i18n.t('%1 起', tempPrice);

        var html = '\n        <a href="/branches/' + branch.bid + '#' + branchNameEn + '" class="card card--category-branch" target="_blank">\n          <img class="cover lazyload" src="' + imgThumb + '" data-original="' + imgSrc + '" alt="' + branchName + '">\n          <div class="card__info">\n            <p class="card-info__area card-info__area--sm">' + branch.area + '</p>\n            <h4 class="card-info__title">' + branchName + '</h4>\n            <div class="card-info__description-group">\n              ' + traficInfo + '\n              ' + buyers + '\n            </div>\n            <div class="card-info__hori-block">\n              <div class="evaluate">\n                <span class="number">' + rating + '</span>\n                <div class="rating-stars">' + ratingStars + '</div>\n              </div>\n              <div class="price price--sell">' + priceFrom + '</div>\n            </div>\n          </div>\n        </a>\n        ';

        return { dom: html };
    }

    function ProductCard(product) {
        var imgThumb = GLOBAL.CDN_URI + FunNowTools.getThumbnail(product.cover_image);
        var imgSrc = GLOBAL.CDN_URI + FunNowTools.getCover(product.cover_image);
        var tempPrice = product.symbol_code + ' ' + product.discount.toLocaleString();
        var priceFrom = i18n.t('%1 起', tempPrice);
        var errorImg = GLOBAL.CDN_URI + FunNowTools.getCover('');
        var html = '\n        <a href="/products/' + product.product_id + '" class="card card--category-branch" target="_blank">\n          <img class="cover lazyload" src="' + imgThumb + '" onerror="this.src=\'' + errorImg + '\'" data-original="' + imgSrc + '" alt="' + product.product_name + '-' + product.branch_name + '">\n          <div class="card__info">\n            <p class="card-info__area card-info__area--sm">' + product.area + '</p>\n            <h4 class="card-info__title">' + product.branch_name + '</h4>\n            <h4 class="card-info__title">' + product.product_name + '</h4>\n            <div class="card-info__hori-block">\n              <span class="price"><del>' + i18n.t('RSP') + ' ' + product.symbol_code + ' ' + product.rrp.toLocaleString() + '</del></span>\n              <span class="price price--sell">' + priceFrom + '</span>\n            </div>\n          </div>\n        </a>\n        ';
        return { dom: html };
    }

    function FilterProducts() {
        // 搜尋頁面
        this.bannerKeyword = $('.category-title__block .search-keyword');
        this.keywordForm = $('form[name="keyword"]'); // in search aside form
        this.regionDOM = $('.js-category-regionid');
        // desktop 左手邊, tablet,mobile Modal內
        this.sortDOM = $('.js-category-sort');
        this.timeDOM = $('.js-category-time');
        this.priceDOM = $('.js-category-price');
        this.tagsDOM = $('.js-category-tags');
        // 人數下拉選單
        this.customNumDOM = $('.js-custom-number');
        // 日期下拉選單, 內容在 init 內產生
        this.customDateDOM = $('.js-custom-date');
        this.calendarDOM = null;

        // 搜尋頁面
        // in search aside & pop modal
        this.keyword = $('input[name="keyword"].js-category-keyword');
        this.keywordSearchBtn = $('.js-category-keyword ~ .keyword-search-icon');
        this.regionInput = this.regionDOM.find('input[name="regionid"]');
        // 分類 & 搜尋頁面
        this.sortInput = this.sortDOM.find('input[name="sort"]');
        this.timeInput = this.timeDOM.find('input[name="time"]');
        this.timeBtn = this.timeDOM.find('.filter-time__item');
        this.priceInput = this.priceDOM.find('input[name="price"]');
        this.tagsCheck = this.tagsDOM.find('input[name="tags"]');
        // 人數 list
        this.customNumList = this.customNumDOM.find('li');
        // 展開行事曆 btn
        this.selectDateBtn = this.customDateDOM.find('.selector-date-btn');

        // Modal [類型]toggle, 搜尋按鈕
        this.filterTagsToggleTitle = this.tagsDOM.find('.filter-type__toggle');
        this.filterTagsToggleContent = this.tagsDOM.find('#mobile_category_tags');
        this.clearFilterBtn = $('#category_filter .js-clear-filter');
        this.modalSearchBtn = $('.filter-search-btn__block .filter-search-btn');

        // 篩選條件與區塊
        this.filterItems = [];
        this.filterItemsBlock = $('.filter-items__block .filter-items');

        // 文章列表
        this.articleLists = $('.js-category-articles');

        // 主要區塊, 分店顯示區塊, 頁碼顯示區塊
        this.mainBlock = $('.category-container main');
        this.branchBlock = $('.branchs-block');
        this.paginationBlock = $('.pagination-block');

        this.customNum = 0;
        this.calendarData = null;
        this.calendarSlide = { aside: null, button: null };
        this.customDate = { start: null, end: null };

        this.processing = false;
        this.loading = new Loading();
    }

    FilterProducts.prototype = {
        init: function init() {
            var _this = this;

            this.keywordSearchBtn.on('click', this.filterByKeyword.bind(this));
            this.keywordForm.on('submit', this.submitKeyword.bind(this));
            this.regionInput.on('click', this.selectRadio.bind(this));
            this.sortInput.on('click', this.selectRadio.bind(this));
            this.timeInput.on('click', this.selectRadio.bind(this));
            this.timeBtn.on('click', this.clickTimeFilter.bind(this));
            this.priceInput.on('input change', this.changePriceRange.bind(this));
            this.tagsCheck.on('click', this.selectCheck.bind(this));
            this.customNumList.on('click', this.clickListItem.bind(this));

            // 行事曆被點開
            this.selectDateBtn.on('click', this.initCalendarSwiper.bind(this));

            // 彈出篩選視窗, 類型在 width > 480時, 預設展開
            if (window.innerWidth > 480) {
                this.filterTagsToggleTitle.removeClass('collapsed');
                this.filterTagsToggleContent.addClass('in');
            }

            // 載入相關文章
            if (this.articleLists.length > 0) {
                var idsStr = this.articleLists.data('ids');
                var ids = ('' + idsStr).split(',');
                this.fetchBlogArticles(ids);
            }

            // 分頁點擊後,使用JS替換內容
            this.paginationBlock.find('.pagination-group .js-switch-page').on('click', this.changePage.bind(this));

            // Modal 清除所有篩選條件
            this.clearFilterBtn.on('click', function () {
                _this.resetInput('keyword');
                _this.resetInput('regionid');
                _this.resetInput('sort');
                _this.resetInput('time');
                _this.resetInput('price');
                _this.resetCheck();
            });

            // 搜尋頁面, 預設有資料在畫面上
            var filterTags = this.filterItemsBlock.find('.tag');
            if (filterTags.length > 0 && isSearch) {
                this.addSearchPageFilterTags.call(this, filterTags);
            }

            // Modal Filter 確定按鈕 => 關閉 modal
            this.modalSearchBtn.on('click', function () {
                _this.checkModalKeyword();
                _this.fetchBranchs();
                $('#category_filter').modal('hide');
            });
        },

        initCustomDate: function initCustomDate() {
            if (this.customDateDOM.find('.js-day-mode-0').length > 0) {
                this.createWeekList();
                $('.js-day-mode-0 li').on('click', this.clickListItem.bind(this));
            }
            if (this.customDateDOM.find('.js-day-mode-1').length > 0) {
                this.calendarConfirmBtn = this.customDateDOM.find('.js-day-mode-1 .js-calendar-confirm');
                this.calendarConfirmBtn.on('click', this.calendarConfirm.bind(this));

                this.calendarDOM = this.customDateDOM.find('.js-day-mode-1 .calendar-panel');
                this.createMonthSlider();
            }
        },

        createMonthSlider: function createMonthSlider() {
            var _this2 = this;

            var currMonth = moment().format('YYYY/MM');
            var lastDay = moment().add(30, 'days');
            var lastMonth = moment({ year: lastDay.year(), month: lastDay.month(), day: 1 });
            this.calendarData = DateHelper.getCalendarDate(lastMonth);
            console.log(this.calendarData);
            // init Current Month text
            this.calendarDOM.find('.calendar-panel__header .curr-month').text(currMonth);
            this.calendarDOM.find('.swiper-wrapper').empty();
            this.calendarData.forEach(function (data) {
                var slide = new CalendarSlide(data, _this2.customDate);
                _this2.calendarDOM.find('.swiper-wrapper').append('<div class="swiper-slide">' + slide.dom + '</div>');
            });
        },

        initCalendarSwiper: function initCalendarSwiper(e) {
            e.preventDefault();
            e.stopPropagation();
            var current = e.currentTarget;
            // 展開月曆
            var dropdown = $(current).parents('.c-dropdown');
            dropdown.toggleClass('dd-open');

            // bind Swiper 動作
            if ($(current).parents('.filter-panel').length > 0) {
                // 左側日曆被點開
                if (this.calendarSlide.aside === null) {
                    // 左側月曆還沒初始化
                    this.calendarSlide.aside = this.bindCalendarSwiper('.filter-panel .calendar-panel.swiper-container');
                    this.bindCalendarDateClickEvent();
                }
            } else if ($(current).parents('.filter-time__item').length > 0) {
                // 四顆按鈕的日曆被點開
                if (this.calendarSlide.button === null) {
                    // 四顆按鈕的月曆還沒初始化
                    this.calendarSlide.button = this.bindCalendarSwiper('.filter-time__item .calendar-panel.swiper-container');
                    this.bindCalendarDateClickEvent();
                }
                // 因為 stopPropagation, 手動更新按鈕 class
                this.timeBtn.removeClass('active');
                $(current).parents('.filter-time__item').addClass('active');
            }
        },

        // bind 月曆上每一天 click 動作
        bindCalendarDateClickEvent: function bindCalendarDateClickEvent() {
            $('.swiper-slide .item:not(.disabled)').on('click', this.clickCalendarDate.bind(this));
        },
        // 初始化行事曆 swiper
        bindCalendarSwiper: function bindCalendarSwiper(selector) {
            return new Swiper(selector, {
                slidesPerView: '1',
                simulateTouch: false,
                allowTouchMove: false,
                // touchMoveStopPropagation: false,
                prevButton: '.calendar-panel__header .prev-month:not(.disabled)',
                nextButton: '.calendar-panel__header .next-month:not(.disabled)',
                onSlideChangeEnd: function onSlideChangeEnd(CalendarSlider) {
                    var currMonth = moment().add(CalendarSlider.activeIndex, 'months').format('YYYY/MM');
                    $('.calendar-panel__header .curr-month').text(currMonth);
                }
            });
        },

        // 產生七天下拉選項
        createWeekList: function createWeekList() {
            this.customDateDOM.find('.js-day-mode-0').each(function (i, v) {
                $(v).empty();
                var week = DateHelper.createWeekDates();
                week.forEach(function (data) {
                    var dateValue = data.date.replace(/\//g, '');
                    var dayString = i18n.t('shortDays').split(',')[data.day];
                    var item = '<li name="starttime" value="' + dateValue + '">' + data.date + ' ' + dayString + '</li>';
                    $(v).append(item);
                });
            });
        },

        setInputRadioChecked: function setInputRadioChecked(name, value) {
            var selector = '.js-category-' + name + ' input[name="' + name + '"][value="' + value + '"]';
            var inputRadios = $(selector);
            var length = inputRadios.length;

            if (length <= 0) return false;

            // 因為同樣的 input[type=radio][name=regionid], 只能顯示一個checked
            inputRadios.each(function (i, v) {
                if (window.innerWidth >= 992 && i >= length / 2) {
                    return 0;
                }
                $(v).prop('checked', true);
            });
        },

        checkModalKeyword: function checkModalKeyword() {
            var modalKeyword = this.keyword.length > 1 ? this.keyword[1].value : '';
            if (modalKeyword === '') return false;
            var itemIdx = this.filterItems.findIndex(function (item) {
                return item.type === 'keyword';
            });
            var keywordText = modalKeyword.trim();
            // 補上畫面上搜尋文字
            this.keyword[0].text = this.keyword[0].value = keywordText;
            $('h1.search-keyword').text('"' + keywordText + '"');

            if (itemIdx === -1) {
                // 新增篩選條件
                var item = new FilterItem('keyword', keywordText, modalKeyword, this.removeFilterItem.bind(this));
                this.addFilterItem.call(this, item, false);
            } else {
                this.filterItems[itemIdx].value = modalKeyword;
                this.filterItems[itemIdx].text = modalKeyword;
            }
        },

        // 進入搜尋頁面時帶入的條件，增加到畫面上
        addSearchPageFilterTags: function addSearchPageFilterTags(tags) {
            var _this3 = this;

            var self = this;
            tags.each(function (i, tag) {
                var type = $(tag).data('type');
                var value = $(tag).data('value');
                var text = $(tag).text().trim();

                if (type === 'regionid') {
                    self.setInputRadioChecked('regionid', value);
                }

                var item = new FilterItem(type, text, value);
                // 綁定目前 tag 關閉事件
                $(tag).find('span.close').on('click', self.removeFilterItem.bind(_this3, tag));
                item.setDom(tag);
                self.filterItems.push(item);
            });
        },

        disabledFilterInput: function disabledFilterInput(mode) {
            this.keyword.each(function (i, v) {
                $(v).prop('disabled', mode);
            });
            this.regionInput.each(function (i, v) {
                $(v).prop('disabled', mode);
            });
            this.sortInput.each(function (i, v) {
                $(v).prop('disabled', mode);
            });
            this.timeInput.each(function (i, v) {
                $(v).prop('disabled', mode);
            });
            this.priceInput.each(function (i, v) {
                $(v).prop('disabled', mode);
            });
            this.tagsCheck.each(function (i, v) {
                $(v).prop('disabled', mode);
            });
        },

        // 移除原有的 filter item, 加入新 item 前
        removeOldFilterItem: function removeOldFilterItem(newItem) {
            var oldItem = '.tag[data-type="' + newItem.type + '"]';
            var self = this;
            var isTheSame = false;

            // 客製化時間, 移除原本 time 項目
            if (newItem.type === 'starttime' || newItem.type === 'endtime') {
                this.filterItems.forEach(function (v, i) {
                    if (v.type === 'time') {
                        self.filterItems.splice(i, 1);
                        self.filterItemsBlock.find('.tag[data-type="time"]').remove();
                    }
                });
            } else if (newItem.type === 'time') {
                this.filterItems.forEach(function (v, i) {
                    if (v.type === 'starttime') {
                        self.filterItems.splice(i, 1);
                        self.filterItemsBlock.find('.tag[data-type="starttime"]').remove();
                    } else if (v.type === 'endtime') {
                        self.filterItems.splice(i, 1);
                        self.filterItemsBlock.find('.tag[data-type="endtime"]').remove();
                    }
                });
            }

            this.filterItems.forEach(function (v, i) {
                if (v.type === newItem.type && v.value == newItem.value) {
                    isTheSame = true;
                } else if (v.type !== 'tags' && v.type === newItem.type) {
                    self.filterItems.splice(i, 1);
                    self.filterItemsBlock.find(oldItem).remove();
                } else if (v.type === newItem.type) {
                    if (parseInt(v.value) === parseInt(newItem.value)) {
                        self.filterItems.splice(i, 1);
                        oldItem += '[data-value="' + newItem.value + '"]';
                        self.filterItemsBlock.find(oldItem).remove();
                    }
                }
            });
            return isTheSame;
        },

        // 刪除 filterItems 裡面的 item
        removeFilterItem: function removeFilterItem(itemDOM) {
            // 已經在處理中,不動作
            if (this.processing) return false;

            var item = $(itemDOM);
            var self = this;
            var change = false;
            this.filterItems.forEach(function (v, i) {
                if (v.type === item.data('type') && v.type !== 'tags') {
                    self.filterItems.splice(i, 1);
                    item.remove();
                    change = true;

                    // reset input[type="radio"] or input[type="text"]
                    self.resetInput(v.type);
                } else if (v.type === item.data('type')) {
                    if (parseInt(v.value) === parseInt(item.data('value'))) {
                        self.filterItems.splice(i, 1);
                        item.remove();
                        change = true;

                        // reset input[type="check"]
                        self.resetCheck(v.value);
                    }
                }
            });

            // 統計非"排序"的條件數量, 0:隱藏篩選條件區塊
            var notSortItems = this.filterItems.filter(function (item) {
                return item.type !== 'sort';
            });
            if (notSortItems.length === 0) {
                this.filterItemsBlock.parent().addClass('hidden');
            }

            if (change) {
                this.fetchBranchs();
            }
        },

        // 重設 input checked 狀態
        resetInput: function resetInput(type) {
            switch (type) {
                case 'regionid':
                    this.regionInput.prop('checked', false);
                    break;
                case 'sort':
                    this.sortInput.prop('checked', false);
                    break;
                case 'starttime':
                case 'endtime':
                    this.resetSelectDateBtnText();
                // caution: break is omitted intentionally
                case 'time':
                    this.timeInput.prop('checked', false);
                    break;
                case 'price':
                    this.resetPrice();
                    break;
                case 'keyword':
                    this.resetKeywordInput();
                    break;
                default:
                    break;
            }
        },

        resetSelectDateBtnText: function resetSelectDateBtnText() {
            this.selectDateBtn.each(function (i, dom) {
                if (i === 0) $(dom).text('日期');
                if (i === 1) $(dom).text('日期 年份/月/日');
            });
        },

        resetPrice: function resetPrice() {
            var _this4 = this;

            var priceOriginal = this.priceDOM.find('input[name="price"].original');
            priceOriginal.each(function (i, v) {
                $(v).val('0,100');
                $(v).next().prop('style', '--low:1%; --high:99%;');
                var range = _this4.getPriceRange($(v));
                var rangeText = range.currency + ' ' + range.min + ' ~ ' + range.currency + ' ' + range.max;
                _this4.replacePriceRangeText($(v), rangeText);
            });
        },
        resetKeywordInput: function resetKeywordInput() {
            this.keyword.val('');
            this.bannerKeyword.text('""');
        },

        // 重設 "標籤" 選中的選項
        resetCheck: function resetCheck() {
            var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';

            if (value === 'all') {
                this.tagsDOM.find('input[name="tags"]').prop('checked', false);
            } else {
                this.tagsDOM.find('input[value="' + value + '"]').prop('checked', false);
            }
        },

        appendFilterItem: function appendFilterItem(item) {
            // "排序" 不增加到畫面的篩選條件裡
            if (item.type !== 'sort') {
                this.filterItemsBlock.append(item.dom);
            }
        },

        // Adds a filter item (畫面 與 filterItems array)
        addFilterItem: function addFilterItem(item) {
            var fetchNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            if (this.processing) {
                console.log('skip');
                return false;
            }
            // 切換[排序]不影響畫面的篩選條件
            if (item.type !== 'sort' && this.filterItemsBlock.parent().hasClass('hidden')) {
                this.filterItemsBlock.parent().removeClass('hidden');
            }
            // 刪除同類型 filter
            var isSame = this.removeOldFilterItem(item);
            if (!isSame) {
                this.appendFilterItem(item);
                this.filterItems.push(item);

                if (item.type === 'sort' && item.value === 'distance') {
                    // 切換為隱藏狀態
                    this.switchProcess('hide', false);
                    // 再呼叫位置存取功能
                    this.askGEOLocation();
                } else {
                    if (watchPos !== undefined) {
                        navigator.geolocation.clearWatch(watchPos);
                        watchPos = undefined;
                    }
                    if (fetchNow) {
                        this.fetchBranchs();
                    }
                }
            }
        },

        getPositionFail: function getPositionFail(err) {
            // GPS 取得失敗, 恢復顯示狀態
            this.switchProcess('show');
            console.warn('ERROR(' + err.code + '): ' + err.message);
        },

        // 使用者授權取得座標位置後,將座標寫入cookie,繼續加入篩選條件流程
        getGEOPosition: function getGEOPosition(pos) {
            var lat = pos.coords.latitude;
            var lng = pos.coords.longitude;

            $.cookie('GEO_lat', lat, { expires: 1, path: '/' });
            $.cookie('GEO_lng', lng, { expires: 1, path: '/' });

            var fetchLater = sessionStorage.getItem('fetchLater');
            if (fetchLater) {
                sessionStorage.removeItem('fetchLater');
                console.log('fetch data later...');
            } else {
                this.fetchBranchs();
            }
        },

        // 當座標改變時，重新紀錄GPS座標到cookie內
        changeGEOPosition: function changeGEOPosition(pos) {
            var lat = pos.coords.latitude;
            var lng = pos.coords.longitude;

            $.cookie('GEO_lat', lat, { expires: 1, path: '/' });
            $.cookie('GEO_lng', lng, { expires: 1, path: '/' });
        },

        // Ask user to provide GEO location
        askGEOLocation: function askGEOLocation() {
            try {
                if (navigator.geolocation) {
                    var options = {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    };
                    navigator.geolocation.getCurrentPosition(this.getGEOPosition.bind(this), this.getPositionFail.bind(this), options);

                    options.enableHighAccuracy = false;
                    watchPos = navigator.geolocation.watchPosition(this.changeGEOPosition, this.getPositionFail.bind(this), options);
                } else {
                    console.info('Geolocation is not supported by this browser.');
                }
            } catch (error) {
                console.error(error.message);
            }
        },

        switchProcess: function switchProcess(mode) {
            var removeBranch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            if (removeBranch) {
                // 移除原本 branch cards
                this.branchBlock.children().remove();
            }

            if (mode === 'show') {
                // 隱藏 loading
                this.loading.removeClass('show');
                // 顯示商店
                this.branchBlock.removeClass('hidden');
            } else if (mode === 'hide') {
                // 隱藏商店
                this.branchBlock.addClass('hidden');
                // 顯示 loading
                this.loading.addClass('show');
            }
        },

        // 搜尋頁面 左側關鍵字按下 enter
        submitKeyword: function submitKeyword(e) {
            e.preventDefault();
            var form = e.currentTarget;
            var input = $(form).find('input[name="keyword"]');
            var value = input.val().trim();

            if (value === '') return;

            this.bannerKeyword.text('"' + value + '"');

            var item = new FilterItem('keyword', value, value, this.removeFilterItem.bind(this));
            this.addFilterItem.call(this, item, true);
        },

        // 搜尋頁面 左側關鍵字搜尋按鈕
        filterByKeyword: function filterByKeyword(e) {
            var current = e.currentTarget;
            var value = $(current).prev().val().trim();

            if (value === '') return;

            // 發送 tracking event, 搜尋
            fbq('track', 'Search', { search_string: value });

            this.bannerKeyword.text('"' + value + '"');

            var item = new FilterItem('keyword', value, value, this.removeFilterItem.bind(this));
            this.addFilterItem.call(this, item, true);
        },

        // 選取 "時間" 四顆按鈕來篩選
        clickTimeFilter: function clickTimeFilter(e) {
            var current = e.currentTarget;
            var value = $(current).data('value');
            var type = $(current).data('type');
            var text = $(current).text().trim();

            // button add "active" class
            this.timeBtn.removeClass('active');
            $(current).addClass('active');

            var fetchNow = true;
            if (type === 'time' && value === 'custom') {
                // do nothing
            } else {
                var item = new FilterItem(type, text, value, this.removeFilterItem.bind(this));
                this.addFilterItem.call(this, item, fetchNow);
            }
        },

        // 選取 "排序" or "預訂時間" 選項
        selectRadio: function selectRadio(e) {
            var current = e.currentTarget;
            var value = $(current).val();
            var type = $(current).attr('name');
            var text = $(current).parent().text().trim();

            var fetchNow = true;
            if ($(current).parents('.filter-block').length > 0) {
                fetchNow = false;
                sessionStorage.setItem('fetchLater', '1');
            }
            if (type === 'time' && value === 'custom') {
                // 檢查選中日期
            } else {
                var item = new FilterItem(type, text, value, this.removeFilterItem.bind(this));
                this.addFilterItem.call(this, item, fetchNow);
            }
        },

        // 選中 "人數" or "七天日期" 下拉選項
        clickListItem: function clickListItem(e) {
            var current = e.currentTarget;
            var value = $(current).val();
            var type = $(current).attr('name');
            var text = $(current).text();

            if (type === 'customNum') {
                this.customNum = value;
                text = i18n.t('%1人以上', value);
            } else if (type === 'starttime') {
                value = moment(value.toString()).format('YYYY-MM-DD');
                this.setInputRadioChecked('time', 'custom');
                // 更新元件文字
                if ($(current).parents('.c-dropdown').hasClass('filter-time__item')) {
                    // 上方四個按鈕, 寬度較小, 顯示文字縮短
                    var shortDate = moment(value).format('MM/DD');
                    $(current).parent().siblings().text(shortDate);
                } else {
                    $(current).parent().siblings().text(text);
                }
            }

            var fetchNow = true;
            var item = new FilterItem(type, text, value, this.removeFilterItem.bind(this));
            this.addFilterItem.call(this, item, fetchNow);

            // 關閉list
            $(current).parents('.c-dropdown').removeClass('dd-open');
        },

        // click 月曆上日期
        clickCalendarDate: function clickCalendarDate(e) {
            e.preventDefault();
            e.stopPropagation();

            var current = e.currentTarget;
            var value = current.attributes.value.value;
            this.updateCustomDate(value);

            // 更新月曆畫面
            var currentCalendarSlide = null;
            if ($(current).parents('aside').length > 0) {
                currentCalendarSlide = this.calendarSlide.aside;
            } else {
                currentCalendarSlide = this.calendarSlide.button;
            }
            this.reRenderCalender(currentCalendarSlide);
            this.switchCalendarConfirmBtn();
        },
        // 更新 customDate, 傳入日期格式為 YYYYMMDD
        updateCustomDate: function updateCustomDate(inputDate) {
            var dateStr = moment(inputDate).format('YYYY-MM-DD');

            if (this.customDate.start === null) {
                this.customDate.start = dateStr;
            } else if (this.customDate.start !== null && this.customDate.end === null) {
                // 判斷第二次點擊日期是否比原本的前面, start 與 end 對調
                if (moment(this.customDate.start).unix() > moment(inputDate).unix()) {
                    this.customDate.end = this.customDate.start;
                    this.customDate.start = dateStr;
                } else {
                    this.customDate.end = dateStr;
                }
            } else if (this.customDate.start !== null && this.customDate.end !== null) {
                // 已經有 start 與 end 時，再次點擊 => 重新選 start 狀態
                this.customDate.end = null;
                this.customDate.start = dateStr;
            }
            console.log('update custom data = ', this.customDate);
        },
        // 更新月曆畫面，並重新綁定 click 行為
        reRenderCalender: function reRenderCalender(calenderSlide) {
            var _this5 = this;

            this.calendarData.forEach(function (data, i) {
                var updateSlide = new CalendarSlide(data, _this5.customDate);
                calenderSlide.slides[i].innerHTML = updateSlide.dom;
            });
            this.bindCalendarDateClickEvent();
        },
        //  更新月曆上確認按鈕 disabled 狀態
        switchCalendarConfirmBtn: function switchCalendarConfirmBtn() {
            if (this.customDate.start !== null && this.customDate.end !== null) {
                if (this.calendarConfirmBtn.attr('disabled') === 'disabled') {
                    this.calendarConfirmBtn.removeAttr('disabled');
                }
            } else {
                if (this.calendarConfirmBtn.attr('disabled') === undefined) {
                    this.calendarConfirmBtn.attr('disabled', 'disabled');
                }
            }
        },
        // 送出確認入住、退房日期
        calendarConfirm: function calendarConfirm(e) {
            console.log(this.customDate);
            var current = e.currentTarget;
            var type = 'endtime';
            var text = moment(this.customDate.start).format('YYYY/MM/DD') + ' - ' + moment(this.customDate.end).format('YYYY/MM/DD');
            var value = this.customDate.start + ',' + this.customDate.end;

            // 更新元件文字
            if ($(current).parents('.c-dropdown').hasClass('filter-time__item')) {
                // 上方四個按鈕, 寬度較小, 顯示文字縮短
                var shortDateRange = moment(this.customDate.start).format('MM/DD') + ' - ' + moment(this.customDate.end).format('MM/DD');
                $(current).parents('.calendar-wrapper').siblings().text(shortDateRange);
            } else {
                $(current).parents('.calendar-wrapper').siblings().text(text);
            }
            // enable input radio
            this.setInputRadioChecked('time', 'custom');

            var fetchNow = true;
            var item = new FilterItem(type, text, value, this.removeFilterItem.bind(this));
            this.addFilterItem.call(this, item, fetchNow);

            // 關閉list
            $(current).parents('.c-dropdown').removeClass('dd-open');
        },

        // 選取 "標籤" 的 checkbox
        selectCheck: function selectCheck(e) {
            var current = e.currentTarget;
            var value = $(current).val();
            var type = $(current).prop('name');
            var text = $(current).parent().text().trim();
            var isCheck = $(current).prop('checked');
            var fetchNow = true;
            if ($(current).parents('.filter-block').length > 0) {
                fetchNow = false;
                sessionStorage.setItem('fetchNow', '0');
            }

            if (isCheck) {
                var item = new FilterItem(type, text, value, this.removeFilterItem.bind(this));
                this.addFilterItem.call(this, item, fetchNow);
            } else {
                var oldItem = '.tag[data-type="' + type + '"][data-value="' + value + '"]';
                var itemDOM = this.filterItemsBlock.find(oldItem);
                this.removeFilterItem(itemDOM[0]);
            }
        },

        // 價格區間變動時
        changePriceRange: function changePriceRange(e) {
            var current = e.currentTarget;
            var fetchNow = true;
            if ($(current).parents('.filter-block').length > 0) {
                fetchNow = false;
                sessionStorage.setItem('fetchNow', '0');
            }

            var self = $(current);
            if (self.hasClass('ghost')) {
                self = self.prev();
            }
            var range = this.getPriceRange(self);
            var rangeText = range.currency + ' ' + range.min + ' ~ ' + range.currency + ' ' + range.max;
            this.replacePriceRangeText(self, rangeText);

            // event type = 'input', 還在拖曳 price range 範圍
            // event type = 'change', value 確定真的變更, 更新 filter 並套用
            if (e.type === 'change') {
                var type = self.attr('name');
                var value = range.min + ',' + range.max;
                var item = new FilterItem(type, rangeText, value, this.removeFilterItem.bind(this));

                this.addFilterItem.call(this, item, fetchNow);
            }
        },

        // 替換價格區間文字
        replacePriceRangeText: function replacePriceRangeText(slider, text) {
            var priceRangeDiv = slider.parent('.price-range__block').siblings('.title-block').children('.js-price-range');
            priceRangeDiv.text(text);
        },

        // Gets the price range.
        getPriceRange: function getPriceRange(slider) {
            var priceRangeData = {
                currency: slider.data('currency'),
                min: parseInt(slider.data('min')),
                max: parseInt(slider.data('max'))
            };
            var rangeMin = priceRangeData.min + Math.round((priceRangeData.max - priceRangeData.min) * slider[0].valueLow / 100);
            var rangeMax = priceRangeData.min + Math.round((priceRangeData.max - priceRangeData.min) * slider[0].valueHigh / 100);

            return {
                currency: priceRangeData.currency,
                min: rangeMin,
                max: rangeMax
            };
        },

        filterByType: function filterByType(array, type) {
            return array.filter(function (v) {
                if (v.type === type) return true;else return false;
            });
        },

        // 依照篩選條件組成網址參數
        generateURLpath: function generateURLpath(filters) {
            var pageNum = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            var page = pageNum == null ? FunNowTools.getParameter('page') : pageNum;
            page = page ? page : 1;

            var url = '',
                tmp = void 0;
            // 關鍵字
            tmp = this.filterByType(filters, 'keyword');
            if (tmp.length > 0) {
                url += 'keyword=' + tmp[0].value + '&';
            }
            // 城市
            tmp = this.filterByType(filters, 'regionid');
            if (tmp.length > 0) {
                url += 'region_id=' + tmp[0].value + '&';
            }
            // 排序
            tmp = this.filterByType(filters, 'sort');
            if (tmp.length > 0) {
                url += 'sort=' + tmp[0].value + '&';
            }
            // 人數
            tmp = this.filterByType(filters, 'customNum');
            if (tmp.length > 0) {
                url += 'cusnum' + tmp[0].value + '&';
            }
            // 預訂時間
            tmp = this.filterByType(filters, 'time');
            if (tmp.length > 0) {
                url += 'time=' + tmp[0].value + '&';
            }
            tmp = this.filterByType(filters, 'starttime');
            if (tmp.length > 0) {
                url += 'time=custom&starttime=' + encodeURIComponent(tmp[0].value) + '&';
            }
            tmp = this.filterByType(filters, 'endtime');
            if (tmp.length > 0) {
                var startTime = tmp[0].value.split(',')[0];
                var endTime = tmp[0].value.split(',')[1];
                url += 'time=custom&starttime=' + encodeURIComponent(startTime) + '&endtime=' + encodeURIComponent(endTime) + '&';
            }
            // 價格
            tmp = this.filterByType(filters, 'price');
            if (tmp.length > 0) {
                url += 'price=' + tmp[0].value + '&';
            }
            // 類型
            tmp = this.filterByType(filters, 'tags');
            if (tmp.length > 0) {
                var tagStr = 'tags=';
                tmp.forEach(function (tag) {
                    tagStr += tag.value + ',';
                });
                tagStr = tagStr.slice(0, tagStr.length - 1);
                url += tagStr + '&';
            }
            // 頁數
            url += 'page=' + page;

            // 符合米其林特殊規則，所以要 API URL 要再多加參數
            // let stay = FunNowTools.getParameter('stay') || 'false';
            // url += '&stay=' + stay;

            return url;
        },

        // 重新產生店家卡片
        arrangeBranchs: function arrangeBranchs(branchs) {
            var self = this;
            branchs.forEach(function (branch) {
                var branchCard = new BranchCard(branch);
                self.branchBlock.append(branchCard.dom);

                // 手動加入宣傳廣告區塊,
                // if ((i+1) === branchs.length/2 && i >= 2) {
                //     let ad = new ADImage();
                //     self.branchBlock.append(ad.dom);
                // }
            });
        },
        // 重新產生商品卡片
        arrangeProducts: function arrangeProducts(products) {
            var self = this;
            products.forEach(function (product) {
                var productCard = new ProductCard(product);
                self.branchBlock.append(productCard.dom);
            });
        },

        togglePagination: function togglePagination(mode) {
            var removeChild = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            if (removeChild) {
                this.paginationBlock.find('.pagination-group').children().remove();
            }
            if (mode === 'show') {
                this.paginationBlock.removeClass('hidden');
            } else if (mode === 'hide') {
                this.paginationBlock.addClass('hidden');
            }
        },

        arrangePagination: function arrangePagination(page_info) {
            var pageHTML = '';
            var pageUrl = '';
            if (page_info.pageNumRange.start > 1) {
                pageUrl = location.pathname + '?' + this.generateURLpath(this.filterItems, page_info.pageNumRange.start - 1);
                pageHTML += '<button data-href="' + pageUrl + '" class="js-switch-page prev"><img src="/images/icon/right-arrow.svg"></button>';
            }
            for (var i = page_info.pageNumRange.start; i <= page_info.pageNumRange.end; i++) {
                var activeClass = i === page_info.index ? ' active' : ' js-switch-page';
                pageUrl = location.pathname + '?' + this.generateURLpath(this.filterItems, i);
                pageHTML += '<button data-href="' + pageUrl + '" class="btn page-btn ' + activeClass + '">' + i + '</button>';
            }
            if (page_info.pageNumRange.end < page_info.pageSize) {
                pageUrl = location.pathname + '?' + this.generateURLpath(this.filterItems, page_info.pageNumRange.end + 1);
                pageHTML += '<button data-href="' + pageUrl + '" class="js-switch-page next"><img src="/images/icon/right-arrow.svg"></button>';
            }
            this.paginationBlock.find('.pagination-group').append(pageHTML);
            this.paginationBlock.find('.pagination-group .js-switch-page').on('click', this.changePage.bind(this));

            // 產生頁數說明文字
            var shortName = this.paginationBlock.find('.js-page-info').data('short-name');
            var pageText = i18n.t('第%1 - %2筆，共 %3 筆%4搜尋結果', page_info.dataRange.start, page_info.dataRange.end, page_info.total, shortName);
            this.paginationBlock.find('.js-page-info').text(pageText);
        },

        changePage: function changePage(e) {
            var target = e.currentTarget;
            var url = $(target).data('href');
            if (url.startsWith('http://')) {
                url = url.replace('http://', 'https://');
            }

            // 重新指定網址語系
            if (LANGUAGE === 'zh-CN' && url.startsWith('/cn') === false) {
                url = '/cn' + url;
            }
            if (LANGUAGE === 'en-US' && url.startsWith('/en') === false) {
                url = '/en' + url;
            }
            if (LANGUAGE === 'ja-JP' && url.startsWith('/jp') === false) {
                url = '/jp' + url;
            }

            this.fetchBranchs(url);
        },

        fetchBranchs: function fetchBranchs() {
            var apiPath = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

            var self = this;
            self.processing = true;

            self.disabledFilterInput(true);
            // 移除搜尋無結果畫面
            self.removeNoResult();
            // 移除原本 branch cards, 顯示 loading
            self.switchProcess.call(self, 'hide', true);
            // 隱藏分頁資訊
            self.togglePagination.call(self, 'hide', true);

            if (apiPath === '') {
                apiPath = location.pathname + '?' + this.generateURLpath(this.filterItems, 1);
            }
            apiPath += '&type=api';

            $.getJSON(apiPath, function (data) {
                // console.log(data);
                if (data.products && data.products.length > 0) {
                    self.arrangeProducts.call(self, data.products);
                    self.arrangePagination.call(self, data.page_info);

                    // 顯示分頁資訊
                    self.togglePagination.call(self, 'show');
                } else {
                    var noResult = new NoResult();
                    var guessText = i18n.t('我們猜您會喜歡');
                    self.branchBlock.before(noResult);
                    self.branchBlock.before('<p class="guess-result">' + guessText + '</p>');
                    data.recommends ? self.arrangeProducts(data.recommends) : false;
                }
                // 觸發 lazy load 來替換店家縮圖
                self.triggerLazyLoad();

                // 顯示店家區塊, 隱藏loading
                self.switchProcess.call(self, 'show');
                self.disabledFilterInput(false);
                self.processing = false;
                self.scrollToBranchTop(350);
            });
        },

        scrollToBranchTop: function scrollToBranchTop() {
            var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

            offset = offset !== '' ? offset : window.innerWidth < 992 ? 100 : 150;
            var branchsBlockTop = $('.branchs-block').offset().top - offset;
            $('html, body').animate({
                scrollTop: branchsBlockTop
            }, 500);
        },

        removeNoResult: function removeNoResult() {
            var noResult = this.mainBlock.find('.empty-result');
            if (noResult.length > 0) {
                noResult.parent().remove();
            }
            var guess = this.mainBlock.find('.guess-result');
            if (guess.length > 0) {
                guess.remove();
            }
        },

        // 取得blog文章
        fetchBlogArticles: function fetchBlogArticles(ids) {
            var self = this;
            if (Array.isArray(ids) === false) {
                self.articleLists.find('.spinner-dotdotdot').remove();
                return false;
            }

            ids.forEach(function (v) {
                var path = 'https://' + GLOBAL.BLOG_HOME_URI + '/blog/' + v.trim() + '?type=json';
                $.getJSON(path, function (data) {
                    // console.log(data);
                    if (data.status === 'success') {
                        var list = '<li><a href="/blog/' + data.article.id + '" target="_blank">' + data.article.subject + '</a></li>';
                        self.articleLists.append(list);
                    }
                }).then(function () {
                    self.articleLists.find('.spinner-dotdotdot').remove();
                });
            });
        },

        triggerLazyLoad: function triggerLazyLoad() {
            $('img.cover.lazyload').lazyload();
        },
        getQueryParams: function getQueryParams(qs) {
            qs = qs.split('+').join(' ');
            var params = {};
            var re = /[?&]?([^=]+)=([^&]*)/g;
            var tokens = '';
            while ((tokens = re.exec(qs)) !== null) {
                var isValues = /\,/.test(tokens[2]);
                var value = isValues ? tokens[2].split(',') : [tokens[2]];
                params[decodeURIComponent(tokens[1])] = value;
            }
            return params;
        },
        addParamsFilter: function addParamsFilter(filterObj, filterValue) {
            var filterKey = filterObj.key;
            var filter = filterObj.dom.filter(function (idx, dom) {
                return dom.value == filterValue;
            })[0];
            if (filter) {
                var filterText = $(filter).parent().text().trim();
                if ($(filter).attr('type') === 'radio') {
                    this.setInputRadioChecked(filterKey, filterValue || filterObj.default);
                } else {
                    $(filter).prop('checked', true);
                }
                var filterItem = new FilterItem(filterKey, filterText, filterValue, this.removeFilterItem.bind(this));
                this.addFilterItem.call(this, filterItem, false);
            }
        },
        setParamsFilter: function setParamsFilter() {
            var _this6 = this;

            var params = this.getQueryParams(location.search);
            var categoryFilter = [{
                key: 'sort',
                dom: this.sortInput,
                default: 'popular'
            }, {
                key: 'tags',
                dom: this.tagsCheck
            }, {
                key: 'time',
                dom: this.timeInput,
                default: 'all'
            }, {
                key: 'regionid',
                dom: this.regionInput,
                default: '1'
            }];

            categoryFilter.forEach(function (filterObj) {
                var filterKey = filterObj.key;
                (params[filterKey] || []).forEach(function (value) {
                    _this6.addParamsFilter(filterObj, value);
                });
            });
        }
    };

    if (isCategory || isThemes) {
        var category = new FilterProducts();
        var defaultSort = isCategory ? 'popular' : 'score';
        category.initCustomDate();
        category.setParamsFilter();
        category.init();
        category.setInputRadioChecked('sort', defaultSort);
    }
    if (isSearch) {
        var search = new FilterProducts();
        search.setParamsFilter();
        search.init();
    }
});