'use strict';

$(function () {
    'use strict';

    var isCardVerifyReturn = /\/(?=card-verify-return|grab-verify-return|ipay88-verify-return)/.test(location.pathname);

    function FailAlert(url) {
        var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        swal({
            title: '3D驗證失敗',
            text: msg || '返回預訂頁面',
            type: 'warning',
            confirmButtonText: i18n.t('OK')
        }).then(function (result) {
            if (result.value) {
                location.href = url;
                return false;
            }
        });
    }

    function addSelectedCardToSessionOrderData(card) {
        var orderData = sessionStorage.getItem('NotLoginBookingData');
        orderData = JSON.parse(orderData);
        orderData.selected_credit = card;
        sessionStorage.setItem('NotLoginBookingData', JSON.stringify(orderData));
        sendAddPaymentInfo(orderData);
    }

    function sendAddPaymentInfo(orderData) {
        // fb event tracking 新增付款資料
        fbq('track', 'AddPaymentInfo', {
            content_ids: [orderData.pid.toString()],
            value: orderData.total_price
        });
    }

    function sendPurchaseTrackingCode(order) {
        var product = order.products[0];
        fbq('track', 'Purchase', {
            value: order.txn_info.amount,
            currency: order.txn_info.symbol_code,
            content_type: 'product',
            content_ids: [product.id.toString()],
            content_name: order.branch_name + '-' + product.name
        });
        gtag('event', 'purchase', {
            'event_category': '交易成功',
            'event_label': 'pid=' + product.id + ', ' + order.txn_info.symbol_code + ' ' + order.txn_info.amount
        });
        gtag('event', 'Paid', {
            'event_category': 'Funnel',
            'event_label': 'Direct Pay'
        });
        gtag('event', 'purchase', {
            'transaction_id': order.orderID,
            'affiliation': order.branch_name,
            'value': order.txn_info.amount,
            'currency': order.txn_info.symbol_code
        });
        gtag('event', 'conversion', {
            'send_to': 'AW-754093270/ZkUQCOWmn6sBENaZyucC',
            'value': product.price,
            'currency': product.symbol_code,
            'transaction_id': product.id
        });
    }

    function getLatestCard(recTradeId) {
        return new Promise(function (resolve, reject) {
            $.ajax({
                type: 'GET',
                url: FunNowTools.API + ('/payment/tp3d/' + recTradeId),
                dataType: 'json',
                success: function success(result) {
                    console.log(result);
                    if (result.code === 0) {
                        resolve(result.data);
                    } else {
                        reject(result);
                    }
                },
                error: function error(_error) {
                    console.error(_error);
                    _error = _error.response ? _error.response.data : _error;
                    reject(_error);
                }
            });
        });
    }

    function checkOrderDetail(orderNumber) {
        return new Promise(function (resolve, reject) {
            $.ajax({
                type: 'GET',
                url: FunNowTools.API + ('/order/' + orderNumber),
                dataType: 'json',
                success: function success(result) {
                    console.log(result);
                    if (result.code === 0) {
                        resolve(result.data);
                    } else {
                        reject(result);
                    }
                },
                error: function error(_error2) {
                    console.error(_error2);
                    _error2 = _error2.response ? _error2.response.data : _error2;
                    reject(_error2);
                }
            });
        });
    }

    function orderStatus(orderNumber) {
        return new Promise(function (resolve, reject) {
            var apiUrl = '/order/' + orderNumber + '/status';
            $.ajax({
                type: 'GET',
                url: '' + FunNowTools.API + apiUrl,
                dataType: 'json',
                success: function success(result) {
                    if (result.code === 0) {
                        resolve(result.data);
                    } else {
                        reject(result);
                    }
                },
                error: function error(_error3) {
                    console.error(_error3);
                    _error3 = _error3.response ? _error3.response.data : _error3;
                    reject(_error3);
                }
            });
        });
    }

    if (isCardVerifyReturn) {
        $.removeCookie('ORDER_DETAIL_ID', { path: '/' });

        var redirectUrl = $.cookie('RedirectTo');
        var is3DVerify = $.cookie('3DVerify');
        var status = parseInt($('input[name="status"]').val());
        var recTradeId = $('input[name="rec_trade_id"]').val();
        // let authCode = $('input[name="auth_code"]').val();
        // let bankTransId = $('input[name="bank_transaction_id"]').val();
        var orderNumber = $('input[name="order_number"]').val();

        var current = new Date().getTime();
        if (!is3DVerify || !redirectUrl || current - is3DVerify > 10 * 60 * 1000) {
            // 非3d驗證回來, 沒有redirect網址, 超過10分鐘 => 回首頁
            location.href = '/';
            return false;
        }

        if (status !== 0) {
            // 驗證失敗(自行推測, 因為 status !== 0)
            return FailAlert(redirectUrl);
        }

        var data = sessionStorage.getItem('NotLoginBookingData');
        if (data) {
            // 取出新增成功卡片資料, 加回訂單資料
            orderStatus(orderNumber).then(function (result) {
                if (result.orderStatus === 2) {
                    return getLatestCard(recTradeId);
                }
                throw new Error(result.message);
            }).then(function (res) {
                addSelectedCardToSessionOrderData(res);
                location.href = redirectUrl;
                return false;
            }).catch(function (err) {
                return FailAlert(redirectUrl, err.message);
            });
        } else {
            // 檢查訂單號碼, 馬來西亞交易後返回
            orderStatus(orderNumber).then(function (result) {
                if (result.orderStatus === 2) {
                    return checkOrderDetail(orderNumber);
                }
                throw new Error(result.message);
            }).then(function (result) {
                if (result.status === 2) {
                    sendPurchaseTrackingCode(result);
                    var orderInfoUrl = '/orders#/info/' + result.id;
                    var source = window.sessionStorage.getItem('3rd_Source');
                    if (source && source === 'Michelin') {
                        orderInfoUrl = '/orders/michelin';
                    }
                    location.href = orderInfoUrl;
                    return false;
                }
                throw new Error(result.message);
            }).catch(function (error) {
                console.error(error);
                return FailAlert(redirectUrl, error.message || error);
            });
        }
    }
});