'use strict';

$(function () {
    'use strict';

    var BRANCH_ID = $('#allCommentModal .modal-body').data('bid');
    var PRODUCT_ID = $('#allCommentModal .modal-body').data('pid');

    var commentBlockHtml = '<div class="header">\n            <div class="face"></div>\n            <div class="comment-user">\n                <p></p>\n                <div class="stars"></div>\n            </div>\n        </div>\n        <div class="comment__wrapper"></div>\n        <div class="footer">\n            <div class="date-time"></div>\n            <div class="like-btn">\n                <i class="heart"></i> ' + i18n.t('useful') + ' (<span></span>)\n            </div>\n        </div>';
    var loadingCommentHtml = '<div class="comment-more__btn" name="more_comment" data-start="10">' + i18n.t('moreContent') + '</div>\n        <div class="loading-comment spinner hidden">\n            <div class="rect1"></div>\n            <div class="rect2"></div>\n            <div class="rect3"></div>\n            <div class="rect4"></div>\n            <div class="rect5"></div>\n        </div>';
    var branchComment = [];

    var getComments = function getComments(commentType, apiData) {
        var data = apiData;
        var id = commentType === 'branches' ? BRANCH_ID : PRODUCT_ID;
        var formatType = commentType === 'branches' ? 'branch' : commentType;
        return new Promise(function (resolve, reject) {
            var apiUrl = '/' + formatType + '/' + id + '/comments';
            $.ajax({
                type: 'GET',
                url: apiUrl,
                data: Object.assign({ type: 'json' }, data),
                dataType: 'json',
                success: function success(result) {
                    // console.log(result.data);
                    if (result.status.toLowerCase() === 'ok') {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                }
            });
        });
    };

    var commentLoading = function commentLoading($tabDOM) {
        var isload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var $moreComment = $tabDOM.find('div[name$="more_comment"]');
        if (isload) {
            $moreComment.addClass('hidden').next('.loading-comment').removeClass('hidden');
        } else {
            $moreComment.removeClass('hidden').next('.loading-comment').addClass('hidden');
        }
    };

    var generateMoreComment = function generateMoreComment(comments) {
        var re = /(\d{4})\-(\d{2})\-(\d{2}).*/;
        var commentDOM = comments.map(function (v) {
            var stars = parseInt(v.rating);
            var empty = 5 - stars;
            var starDOM = '';
            var $DOM = $('<div class="comment-block"></div>');
            while (stars-- > 0) {
                starDOM += '<img src="/images/icon/star.svg" alt="star" class="svg-icon">';
            }
            while (empty-- > 0) {
                starDOM += '<img src="/images/icon/star-o.svg" alt="star-o" class="svg-icon">';
            }
            $DOM.append(commentBlockHtml);
            $DOM.find('.face').addClass('face' + v.rating); // 添加笑臉階級圖示Class
            $DOM.find('.comment-user>p').text(v.rated_by); // 添加評論人名
            $DOM.find('.comment-user .stars').append(starDOM); // 添加星星
            $DOM.find('.comment__wrapper').text(v.rating_description); // 添加評論內容
            $DOM.find('.footer .date-time').text(v.rate_time.replace(re, '$1-$2-$3')); // 添加評論日期
            $DOM.find('.footer .heart').addClass(v.liked ? 'like' : ''); // 添加有無點選有用Class
            $DOM.find('.footer .like-btn>span').text(v.like_count); // 添加有用數量
            $DOM.find('.footer .like-btn').attr('data-commentID', v.id);
            $DOM.find('.footer .like-btn').attr('data-liked', v.liked);
            return $DOM[0].outerHTML;
        });
        return commentDOM.join('');
    };

    var clickSortList = function clickSortList(e) {
        e.preventDefault();
        var selectionText = $(this).text();
        var sortby = $(this).data('value');
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
        $($(this).parent().attr('data-target')).text(selectionText);
        $($(this).parent().attr('data-target')).attr('data-sortby', sortby);
        $(this).parents('.c-dropdown').removeClass('dd-open');

        var $commentDOM = $(this).parents('.tab-pane');
        var $commentContainer = $commentDOM.find('.comment-container');

        var commentType = $(this).parent().data('comment-type');
        var apiData = { start: 0, sortby: sortby };

        getComments(commentType, apiData).then(function (result) {
            $commentContainer.empty();
            $commentContainer.append(generateMoreComment(result.comment));
            if (result.total_count > 10) {
                $commentDOM.find('.comment-more-container').empty().append('' + loadingCommentHtml).find('.comment-more__btn').attr('data-comment-type', commentType);
            }
        });
    };

    var handleCommentLike = function handleCommentLike(event) {
        if ($.cookie('FNaccount') && $.cookie('FNcurrency')) {
            var target = $(event.currentTarget);
            var data = {
                pid: PRODUCT_ID,
                comment_id: this.dataset.commentid
            };
            var isLiked = this.dataset.liked === 'true' ? true : false;
            var ajaxType = isLiked ? 'DELETE' : 'PUT';

            var doLike = isLiked ? 'unlike' : 'like';
            $.ajax({
                type: ajaxType,
                url: FunNowTools.API + ('/review/' + data.comment_id + '/' + doLike),
                dataType: 'json',
                success: function success(result) {
                    if (result.code === 0) {
                        var _doLike = isLiked ? 'removeClass' : 'addClass';
                        target.find('.heart')[_doLike]('like');

                        var likeNum = parseInt(target.find('span').text()) + (isLiked ? -1 : 1);
                        target.find('span').text(likeNum);
                        target.attr('data-liked', !isLiked);
                    }
                }
            });
        } else {
            // 未登入處理, 記錄返回 link 與 未登入訂單資料
            var url = location.pathname + location.hash;
            $.cookie('RedirectTo', url, { expires: 1, path: '/' });
            FunNowTools.setRedirect(url);
            $('#quickLoginModal').modal('show');
        }
    };

    var clickBranchTab = function clickBranchTab() {
        if (branchComment.length === 0) {
            var $commentContainerDOM = $('#branch-comment .comment-container');
            var commentType = 'branches';
            var apiData = { start: 0, sortby: 'latest' };

            getComments(commentType, apiData).then(function (result) {
                branchComment = result.comment;
                $commentContainerDOM.empty();
                $commentContainerDOM.append(generateMoreComment(branchComment));
                $('#branch-comment .branch-info .total-comments').append(i18n.t('共 %1 則評價', result.total_count));
                if (result.total_count > 10) {
                    $('#branch-comment').append('<div class="comment-more-container">\n                            ' + loadingCommentHtml + '\n                        </div>').find('.comment-more__btn').attr('data-comment-type', commentType);
                }
            });
        }
    };

    var doMoreComments = function doMoreComments() {
        var $DOM = $(this);
        var $commentTypeDOM = $(this).parents('.comment-block__wrapper');
        var start = $(this).data('start');
        var sortby = $(this).parent().siblings('.sort-selection-bar').find('[data-sortby]').attr('data-sortby');
        var commentType = $(this).data('comment-type');
        var apiData = { start: start, sortby: sortby };
        commentLoading($commentTypeDOM, true);
        getComments(commentType, apiData).then(function (result) {
            var $commentContainer = $DOM.parent().siblings('.comment-container');
            commentLoading($commentTypeDOM, false);
            if (result.nextPage) {
                $DOM.data('start', result.nextStart);
            } else {
                $DOM.remove();
            }
            $commentContainer.append(generateMoreComment(result.comment));
        }).catch(function (result) {
            console.log(result.message);
            commentLoading($commentTypeDOM, false);
            $DOM.remove();
        });
    };

    // modal裡的 sort dropdown
    $('.blog-modal .c-dropdown .sort-selection-list li').on('click', clickSortList);
    // 點擊有用按鈕
    $('.product-left-block').on('click', '.comment-block .like-btn', handleCommentLike);
    $('.js-comment-container').on('click', '.comment-block .like-btn', handleCommentLike);
    // 點選商店評論Tab
    $('#branch-tab').on('click', clickBranchTab);
    // 載入更多評論
    $('.blog-modal').on('click', 'div[name$="more_comment"]', doMoreComments);
});