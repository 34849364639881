'use strict';

function LandingPage(folder, sheet_url) {
    this.imgFolder = folder;
    this.url = sheet_url; //'https://docs.google.com/spreadsheets/d/e/2PACX-1vS32gNbT4uA3SfIJjtb4Ts5nRun8Q1Z_U9a8dI4taYvNFnRAVWdakPlUO3WJ_Z9Aw2PMopaX5TYt4t8/pub?gid=0&single=true&output=csv';
    this.categories = [];
    this.category_name = [];
    this.DATASHEET = {};
    this.filter_list = $('ul.filter');
    this.items = $('.filter-items');
    this.loading = $('.filter-loading');
}

LandingPage.prototype.FetchDataSheet = function (lines) {
    var self = this;
    var fields = [];
    // id, name, category_name, category
    lines.forEach(function (v, i) {
        var rowData = v.split(',');
        // 遇到空行,跳過
        if (rowData[0] == '') {
            console.log('line(' + i + ') 沒資料');
        } else {
            // 第一行, 取出欄位
            if (i === 0) {
                fields = rowData;
                rowData.forEach(function (v) {
                    self.DATASHEET[v] = [];
                });
            } else {
                rowData.forEach(function (v, j) {
                    self.DATASHEET[fields[j]].push(v);
                });
                if (self.categories.indexOf(rowData[rowData.length - 1]) < 0) {
                    self.categories.push(rowData[rowData.length - 1]);
                    self.category_name.push(rowData[rowData.length - 2]);
                }
            }
        }
    });
    return true;
};

LandingPage.prototype.settingCategory = function () {
    var self = this;
    for (var i = 0, max = self.categories.length; i < max; i++) {
        var list = '<li><a href="#" data-filter=".' + self.categories[i] + '">' + self.category_name[i] + '</a></li>';
        self.filter_list.append(list);
    }
};

/*
 <div class="item thumb {{filterClass}}">
    <a href="https://m.myfunnow.com/branches/478">
      <figure>
        <figcaption class="text-overlay">
          <div class="info">
            <h4>台北沐蘭精品旅館</h4>
            <p>汽車旅館x台北</p>
          </div>
        </figcaption>
        <img src="https://www.myfunnow.com/assets/images/landing_v2/motel/478.jpg" alt="台北沐蘭精品旅館">
      </figure>
    </a>
  </div>
 */
LandingPage.prototype.appendItem = function () {
    var self = this;
    // let base_link = 'https://' + 'location.host' + '/branches/';
    var base_link = 'https://' + 'www.myfunnow.com' + '/branches/';
    var cdn_base = GLOBAL.CDN_URI + 'web/landing/' + self.imgFolder + '/';
    for (var i = 0, max = self.DATASHEET['id'].length; i < max; i++) {
        var itemHTML = '<div class="item thumb ' + self.DATASHEET['category'][i] + '">' + '<a href="' + base_link + self.DATASHEET['id'][i] + '">' + '<figure><figcaption class="text-overlay">' + '<div class="info"><h4>' + self.DATASHEET['name'][i] + '</h4>' + '<p>' + self.DATASHEET['category_name'][i] + '</p></div></figcaption>' + '<img src="' + cdn_base + self.DATASHEET['id'][i] + '.jpg">' + '</figure></a></div>';
        self.items.append(itemHTML);
    }
};

LandingPage.prototype.arrangeIsotope = function () {
    var self = this;
    self.items.imagesLoaded(function () {
        self.items.isotope({
            itemSelector: '.item',
            layoutMode: 'masonry'
        });
        self.items.isotope('shuffle');
        self.loading.hide();
        var defaultFilter = location.hash.substring(1);
        if (defaultFilter.length != 0) {
            self.items.isotope({
                filter: '.' + defaultFilter
            });
        }
    });
};

LandingPage.prototype.bindFilterClick = function () {
    var self = this;
    self.filter_list.on('click', 'li a[data-filter]', function (e) {
        e.preventDefault();
        self.filter_list.find('li a').removeClass('active');
        $(this).addClass('active');
        var selector = $(this).attr('data-filter');

        self.items.isotope({
            filter: selector
        });
    });
};

LandingPage.prototype.LoadDataSheet = function () {
    var self = this;
    $.ajaxSetup({ xhrFields: { withCredentials: false } });
    $.get(this.url, function (response) {
        var lines = response.split(/\r?\n/);
        var res = self.FetchDataSheet(lines);
        if (res) {
            self.settingCategory();
            self.appendItem();
            self.arrangeIsotope();
            self.bindFilterClick();
        }
    });
};