'use strict';

$(function () {
    // Header 放大鏡點開的搜尋區塊 desktop tablet mobile
    'use strict';

    // var $mainInput = $('.search-group .search-input__wrapper .search-input');
    // var mainFilterShow = function() {
    //     $('.search-group').addClass('filter-open');
    // };

    var mainFilterHide = function mainFilterHide() {
        $('.search-group').removeClass('filter-open');
    };

    // $mainInput.on('focus', function() {
    //     mainFilterShow();
    // });
    $('html').on('click', function (e) {
        var $target = $(e.target);
        if (!$target.parents().hasClass('filter-open')) {
            mainFilterHide();
        }
    });
    // $mainInput.bind('input', function() {
    //     if (this.value.length > 0) {
    //         mainFilterHide();
    //     } else {
    //         mainFilterShow();
    //     }
    // });

    var LIST_CATEGORY_MAX = 5;
    var LIST_TAGS_MAX = 10;

    function SearchBlock(form) {
        this.$form = form;

        // this.bannerSubmit= form.find('.search-input__wrapper button.search-submit-btn');
        // this.headerSubmit= form.find('button.header-search-submit');
        // this.mobileSubmit= form.find('button.mobile-search-submit');
        this.searchSubmit = form.find('.input-block .js-search-btn');

        this.filterWrapper = form.find('.search-filter__wrapper');
        this.filterGroup = form.find('.search-filter-group');
        this.loading = form.find('.search-filter-loading');

        this.keyword = form.find('input[name="keyword"]');
        this.region_id = form.find('input[name="region_id"]');

        this.regions = form.find('ul.region-filter li');
        this.tags = form.find('ul.tags-filter li');
        this.categories = form.find('ul.category-filter li');
        this.select_regions = form.find('select[name="region_id"]');
        this.select_category = form.find('select[name="category_id"]');

        this.inSwitching = false;
        this.init();
    }

    SearchBlock.prototype = {
        init: function init() {
            // let self = this;
            // this.keyword.on('focus', ()=>{
            //     self.$form.addClass('filter-open');
            // });
            this.$form.on('submit', this.submitToSearch.bind(this));
            this.keyword.on('input', this.handleInput.bind(this));
            this.regions.on('click', this.handleRegionClick.bind(this));
            this.select_regions.on('change', this.handleRegionChange.bind(this));
            // this.bannerSubmit.on('click', this.submitToSearch.bind(this));
            // this.headerSubmit.on('click', this.submitToSearch.bind(this));
            // this.mobileSubmit.on('click', this.submitToSearch.bind(this));
            this.searchSubmit.on('click', this.submitToSearch.bind(this));
            this.fetchRegionInfo(parseInt(this.region_id.val()));
        },
        generateSearchLink: function generateSearchLink() {
            var tag_id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
            var category_id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

            var region_id = this.region_id.val() || this.select_regions.val();
            var keyword = this.keyword.val().trim();
            category_id = window.innerWidth <= 991 ? this.select_category.val() : category_id;
            if (keyword !== '') {
                tag_id = '';
                category_id = this.select_category.length > 0 ? category_id : '';
            }
            var new_url = FunNowTools.prefixUrl + ('/search?keyword=' + keyword + '&region_id=' + region_id + '&category_id=' + category_id + '&tags=' + tag_id);

            if (keyword === '' && parseInt(region_id) !== -1 && category_id !== '' && parseInt(category_id) !== -1) {
                new_url = FunNowTools.prefixUrl + ('/categories/' + region_id + '-' + category_id);
            }

            console.log(new_url);
            return new_url;
        },
        submitToSearch: function submitToSearch() {
            var new_url = this.generateSearchLink();
            this.keyword.val('');

            location.href = new_url;
        },
        clickToSearch: function clickToSearch(e) {
            var target = $(e.target);
            var target_id = target.data('id');
            var clickType = /tags/.test(target.parent('ul')[0].className) ? 'tag' : 'category';
            var tag_id = clickType === 'tag' ? target_id : '';
            var category_id = clickType === 'category' ? target_id : window.innerWidth <= 991 ? this.select_category.val() : '';
            var new_url = this.generateSearchLink(tag_id, category_id);

            location.href = new_url;
        },
        settingFilterTags: function settingFilterTags(tags) {
            /*
            <div class="filter-title">熱門標籤</div>
            <ul class="tags-filter">
              <li>多人聚餐</li>
            </ul>
            */
            this.tags.remove();
            if (tags.length === 0) {
                this.filterGroup.find('.filter-title').get(1).innerText = '';
            } else {
                this.filterGroup.find('.filter-title').get(1).innerText = i18n.t('熱門標籤');
                var max = tags.length > LIST_TAGS_MAX ? LIST_TAGS_MAX : tags.length;
                var list_tag = '';

                for (var i = 0; i < max; i++) {
                    var id = tags[i].id;
                    var name = tags[i].name;
                    list_tag += '<li data-id="' + id + '">' + name + '</li>';
                }
                this.filterGroup.find('ul.tags-filter').html(list_tag);
                this.tags = this.filterGroup.find('ul.tags-filter li');
                this.tags.on('click', this.clickToSearch.bind(this));
            }
        },
        settingFilterCategory: function settingFilterCategory(categories) {
            /*
            <div class="filter-title">熱門分類</div>
            <ul class="category-filter">
              <li>按摩。舒壓</li>
            </ul>
            */
            var hasHotCategories = this.filterGroup.find('.filter-title').length > 1;
            if (hasHotCategories === false) {
                return false;
            }

            this.categories.remove();
            if (categories.length === 0) {
                this.filterGroup.find('.filter-title').get(0).innerText = '';
            } else {
                this.filterGroup.find('.filter-title').get(0).innerText = i18n.t('熱門分類');
                var max = categories.length > LIST_CATEGORY_MAX ? LIST_CATEGORY_MAX : categories.length;
                var list_category = '';

                for (var i = 0; i < max; i++) {
                    var id = categories[i].id;
                    var name = categories[i].name;
                    list_category += '<li data-id="' + id + '">' + name + '</li>';
                }
                this.filterGroup.find('ul.category-filter').html(list_category);
                this.categories = this.filterGroup.find('ul.category-filter li');
                this.categories.on('click', this.clickToSearch.bind(this));
            }
        },
        settingSelectCategory: function settingSelectCategory(categories) {
            var _this = this;

            this.select_category.children().remove();
            this.select_category.append('<option value="">' + i18n.t('All categories') + '</option>');
            if (categories.length > 0) {
                categories.forEach(function (category) {
                    var id = category.id;
                    var name = category.name;
                    _this.select_category.append('<option value="' + id + '">' + name + '</option>');
                });
            }
            this.select_category.removeAttr('disabled');
        },
        settingTagCategory: function settingTagCategory(data) {
            this.settingFilterTags.call(this, data.tag);
            this.settingFilterCategory.call(this, data.category);
            if (this.select_category.length > 0) {
                this.settingSelectCategory.call(this, data.category);
            }
            this.showFilter();
            this.loading.hide();
        },
        fetchRegionInfo: function fetchRegionInfo(region_id) {
            this.hideFilter();
            this.loading.show();
            var self = this;
            var sessionKey = 'region_' + region_id + '_' + LANGUAGE;
            var sessionData = window.sessionStorage.getItem(sessionKey);
            if (sessionData) {
                return this.settingTagCategory.call(this, JSON.parse(sessionData));
            }

            var api_url = FunNowTools.API + ('/pub/region/' + region_id);

            $.ajax({
                url: api_url,
                type: 'GET',
                headers: {
                    'Accept-Language': LANGUAGE
                },
                dataType: 'json',
                success: function success(res) {
                    console.log(res);
                    if (res.code === 0) {
                        var data = {
                            'tag': res.data.tag,
                            'category': res.data.category
                        };
                        window.sessionStorage.setItem(sessionKey, JSON.stringify(data));

                        return self.settingTagCategory.call(self, res.data);
                    } else {
                        self.loading.hide();
                    }
                }
            });
        },
        showFilter: function showFilter() {
            this.select_regions.removeAttr('disabled');
            // this.bannerSubmit.removeAttr('disabled');
            // this.headerSubmit.removeAttr('disabled');
            // this.mobileSubmit.removeAttr('disabled');
            this.searchSubmit.removeAttr('disabled');
            this.filterGroup.show();
            this.inSwitching = false;
        },
        hideFilter: function hideFilter() {
            this.inSwitching = true;
            this.filterGroup.hide();
            this.select_regions.attr('disabled', 'disabled');
            // this.bannerSubmit.attr('disabled', 'disabled');
            // this.headerSubmit.attr('disabled', 'disabled');
            // this.mobileSubmit.attr('disabled', 'disabled');
            this.searchSubmit.attr('disabled', 'disabled');
        },
        switchRegionActive: function switchRegionActive(target) {
            this.regions.removeClass('active');
            $(target).addClass('active');
        },
        handleRegionClick: function handleRegionClick(e) {
            if (this.inSwitching) {
                return false;
            }

            var keyword = this.keyword.val();
            var val = $(e.target).data('id');
            var oldVal = parseInt(this.region_id.val());
            if (oldVal === val) {
                return false;
            }

            this.region_id.val(val);
            this.switchRegionActive.call(this, e.target);

            if (val !== -1 && keyword === '') {
                this.fetchRegionInfo.call(this, val);
            } else {
                this.filterGroup.hide();
            }
        },
        handleRegionChange: function handleRegionChange(e) {
            var val = parseInt($(e.target).val());
            this.select_category.attr('disabled', 'disabled');
            if (val !== -1) {
                this.fetchRegionInfo.call(this, val);
            } else {
                this.filterGroup.hide();
                this.select_category.children().remove();
                this.select_category.append('<option>' + i18n.t('Select category') + '</option>');
            }
        },
        handleInput: function handleInput(e) {
            var target = $(e.target);
            var region_id = this.region_id.val() || this.select_regions.val();
            region_id = parseInt(region_id);
            if (target.val().length > 0) {
                this.filterGroup.hide();
            } else if (region_id !== -1) {
                this.fetchRegionInfo.call(this, region_id);
            }
        }
    };

    var Searchs = [];
    var search_blocks = $('form[name="searchForm"]');
    for (var i = 0; i < search_blocks.length; i++) {
        var SB = new SearchBlock($(search_blocks[i]));
        Searchs.push(SB);
    }
});