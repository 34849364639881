'use strict';

$(function () {
    'use strict';

    var isBlogIndex = /\/(blog|blog\/list)$/.test(location.pathname);
    var isListPage = /\/blog\/(list|tag|category|author)/.test(location.pathname);
    var isBlogArticle = /\/blog\/[0-9]+$/.test(location.pathname);

    // 替換縮圖
    var replaceThumbImage = function replaceThumbImage(dom) {
        var img = new Image();
        var imageUrl = GLOBAL.CDN_URI + FunNowTools.getCover($(dom).data('image'));
        img.src = imageUrl;

        img.onload = function () {
            dom.style.backgroundImage = 'url("' + imageUrl + '")';
            // dom.style.filter = 'unset';
            $(dom).addClass('noblur');
        };
    };

    var HELP = function () {
        var getCategories = function getCategories() {
            var categories = [];
            if ($('.category-title[data-category-id]').length > 0) {
                $('.category-title[data-category-id]').each(function (i, v) {
                    categories[i] = {
                        id: $(v).data('category-id'),
                        name: $(v).text(),
                        top: v.offsetTop,
                        dom: $(v).next('.swiper-container'),
                        swiper: null,
                        isLoad: false
                    };
                });
            }
            return categories;
        };
        var subRawHTML = function subRawHTML(html) {
            var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;

            var raw,
                tmp = document.createElement('DIV');
            tmp.innerHTML = html;
            raw = tmp.textContent || tmp.innerText || '';
            raw = raw.replace(/&nbsp;/gi, '');
            raw = raw.substr(0, length);
            return raw;
        };
        var getPageInfo = function getPageInfo() {
            if ($('#page_info').length > 0) {
                return {
                    top: $('#page_info').offset().top,
                    now: $('#page_info').attr('now'),
                    max: $('#page_info').attr('max'),
                    dom: $('#articles_container'),
                    loading: $('#page_loading'),
                    end: false
                };
            } else {
                return false;
            }
        };
        var newCardsSlider = function newCardsSlider(dom) {
            return new Swiper(dom, {
                slidesPerView: 3.2,
                spaceBetween: 10,
                autoHeight: true,
                slidesOffsetBefore: 15,
                freeMode: false,
                freeModeMomentumRatio: 5,
                threshold: 30,
                breakpoints: {
                    330: {
                        slidesPerView: 1.1,
                        spaceBetween: 4
                    },
                    390: {
                        slidesPerView: 1.3
                    },
                    450: {
                        slidesPerView: 1.5,
                        spaceBetween: 6
                    },
                    510: {
                        slidesPerView: 1.7
                    },
                    570: {
                        slidesPerView: 1.9
                    },
                    660: {
                        slidesPerView: 2.2,
                        spaceBetween: 8
                    },
                    750: {
                        slidesPerView: 2.5
                    },
                    840: {
                        slidesPerView: 2.8
                    }
                }
            });
        };
        var getThumbnail = function getThumbnail(path) {
            var index = path.lastIndexOf('/');
            var prefix = path.substr(0, index);
            var suffix = path.substr(index);
            return prefix + '/thumbnail' + suffix;
        };

        return {
            getCategories: getCategories,
            subContent: subRawHTML,
            getPageInfo: getPageInfo,
            cardsSlider: newCardsSlider,
            thumbnail: getThumbnail
        };
    }();

    function BLOG() {
        this.categories = HELP.getCategories();
        this.pageInfo = HELP.getPageInfo();
    }
    /*
      <div class="blog-card" data-url="">
        <div class="blog-cover" style="background-image: url('https://dummyimage.com/300x200/ececec/343434.png');"></div>
        <p class="blog-createtime"><i class="icon icon-calendar"></i>category.create_time</p>
        <p class="blog-category">
          <span>{{ category.name }}</span>
        </p>
        <h3 class="blog-subject">article.subject</h3>
        <div class="blog-sub-content">
          {{ article.content|subContent(110) }}
        </div>
        <div class="fb-like__wrapper">
          <div class="fb-like" data-href="https://m.myfunnow.com{{ path_for('blog.article', {'id': article.id}) }}" data-layout="button_count" data-action="like" data-size="small" data-show-faces="false" data-share="true"></div>
        </div>
      </div>
     */
    BLOG.prototype.generateArticleDOM = function (article) {
        var hasContent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var createTime = article.create_time.substr(0, article.create_time.indexOf(' '));
        var categoryHTML = '';
        if (article.categories) {
            categoryHTML = '<p class="blog-category"><i class="icon icon-category"></i>';
            article.categories.forEach(function (v) {
                categoryHTML += '<span>' + v.name + '</span>';
            });
            categoryHTML += '</p>';
        }

        var subjectClass = hasContent ? 'blog-subject' : 'blog-subject blog-subject--nb';

        var articleDOM = '\n        <div class="blog-card" data-url="/blog/' + article.id + '">\n            <div class="blog-cover__wrapper">\n                <div class="blog-cover" style="background-image: url(\'' + GLOBAL.CDN_URI + HELP.thumbnail(article.cover_image) + '\');"\n                    data-image="' + article.cover_image + '"></div>\n                </div>\n                <p class="blog-createtime">\n                    <i class="icon icon-calendar"></i>' + createTime + '\n                </p>';

        if (hasContent) {
            articleDOM += categoryHTML;
        }

        articleDOM += '<h3 class="' + subjectClass + '">' + article.subject + '</h3>';

        if (hasContent) {
            // let articleUrl = 'https://' + location.hostname + '/blog/' + article.id;
            articleDOM += '<div class="blog-sub-content">' + HELP.subContent(article.content, 70) + '</div>' +
            // '<div class="fb-like__wrapper">' +
            // '<div class="fb-like" data-href="'+articleUrl+'" data-layout="button_count" data-action="like" data-size="small" data-show-faces="false" data-share="true"></div>' +
            '</div>';
        }
        articleDOM += '</div>';

        return articleDOM;
    };

    BLOG.prototype.getCategoryArticles = function (cid, swiper) {
        var limit = 6;
        // console.log('API is: ', GLOBAL.API_URI);
        // cause APIv2_URI isnt included in GLOBAL, but idk why.
        // 應該是 prod，之前有 commit 把 prd 改成 prod 了
        var v2ApiUriEnum = {
            dev: 'https://dev-funnow.myfunnow.com/v2/funnow',
            beta: 'https://beta-api-go.myfunnow.com/v2/funnow',
            stg: 'https://stg-api-go.myfunnow.com/v2/funnow',
            prod: 'https://api-go.myfunnow.com/v2/funnow'
        };

        var getArticlesAndAddSwiper = function getArticlesAndAddSwiper(countryName, categoryName) {
            var url = '/blog/list/' + countryName + '/' + categoryName + '?type=json&limit=' + limit;

            $.get(url, function (responseArray) {
                console.log('res is: ', responseArray);
                responseArray.forEach(function (v) {
                    var slide = '<div class="swiper-slide">' + blog.generateArticleDOM(v) + '</div>';
                    // append blog-catd to slider
                    swiper.appendSlide(slide);
                    // bind blog-card click event
                    $('.blog-card[data-url]').on('click', function () {
                        var url = $(this).data('url');
                        location.href = url;
                    });
                    // bind blog-card replace thumbnail
                    $('.blog-cover[data-image]').each(function (i, dom) {
                        replaceThumbImage(dom);
                    });

                    if ($(swiper.slides[0]).hasClass('load')) {
                        swiper.removeSlide(0);
                    }
                });
            });
        };
        // get category info
        $.get(v2ApiUriEnum[GLOBAL.ENV] + '/blogs/categories/' + cid, function (res) {
            var data = res.data;
            getArticlesAndAddSwiper(data.country_path_name, data.path_name);
        });
    };

    BLOG.prototype.removeLoadingSlide = function (swiper) {
        for (var i = 2; i >= 0; i--) {
            if ($(swiper.slides[i]).hasClass('load')) {
                swiper.removeSlide(i);
            }
        }
    };

    BLOG.prototype.getNextPageArticles = function (pageInfo) {
        var url = location.pathname + '?type=json&page=' + pageInfo.now;
        console.log(url);
        $.get(url, function (data) {
            var nextPageDOMs = '';
            data.forEach(function (v) {
                nextPageDOMs += blog.generateArticleDOM(v, true);
            });

            $(nextPageDOMs).insertBefore(pageInfo.dom.find('#page_loading')[0]);
            // pageInfo.dom.find('#page_loading').append(nextPageDOMs);
            pageInfo.loading.addClass('hidden');
            if (parseInt(pageInfo.now) === parseInt(pageInfo.max)) {
                pageInfo.loading.fadeOut(400, function () {
                    pageInfo.end = true;
                    console.log('no next page data');
                });
            }
            // bind blog-card click event
            $('.blog-card[data-url]').on('click', function () {
                var url = $(this).data('url');
                location.href = url;
            });
            // bind blog-card replace thumbnail
            $('.blog-cover[data-image]').each(function (i, dom) {
                replaceThumbImage(dom);
            });
            // FB like re-render
            // if (typeof FB != 'undefined') {
            //     FB.XFBML.parse(pageInfo.dom[0]);
            // }
        });
    };

    BLOG.prototype.checkLoadNextPage = function (scroll, pageInfo) {
        if (scroll > pageInfo.top && parseInt(pageInfo.now) < parseInt(pageInfo.max)) {
            pageInfo.now = parseInt(pageInfo.now) + 1;
            pageInfo.loading.removeClass('hidden');
            blog.getNextPageArticles(pageInfo);
        }
    };

    BLOG.prototype.detectScrollTop = function () {
        var scroll = $(window).scrollTop() + $(window).height() + 300;
        // console.log(scroll);
        if (isBlogIndex) {
            blog.categories.forEach(function (v, i) {
                if (scroll > v.top && !v.isLoad) {
                    console.log('load category: ' + v.name);
                    blog.categories[i].isLoad = true;
                    blog.getCategoryArticles(v.id, v.swiper);
                    blog.removeLoadingSlide(v.swiper);
                }
            });
        } else if (isListPage) {
            if (blog.pageInfo.end !== true) {
                blog.checkLoadNextPage(scroll, blog.pageInfo);
            }
        }
    };

    BLOG.prototype.categorySlider = function (dom) {
        return HELP.cardsSlider(dom);
    };
    BLOG.prototype.initAuthorsSlider = function () {
        new Swiper('.swiper-container.authors', {
            slidesPerView: 'auto',
            spaceBetween: 20,
            autoHeight: true,
            slidesOffsetBefore: 15,
            freeMode: false,
            freeModeMomentumRatio: 5,
            threshold: 50,
            breakpoints: {
                480: {
                    spaceBetween: 10
                },
                640: {
                    spaceBetween: 15
                }
            }
        });
    };

    function AUTHOR() {
        this.wrapper = $('.authors-container');
        this.avators = $('.author-avator');
        this.infoBlock = $('.author-info-block');
    }
    AUTHOR.prototype.activeAuthor = function (author) {
        author.addClass('active');
    };
    AUTHOR.prototype.clearAllActive = function () {
        this.avators.each(function (i, v) {
            $(v).removeClass('active');
        });
    };
    AUTHOR.prototype.showAuthorInfo = function () {
        this.wrapper.addClass('show');
    };
    AUTHOR.prototype.hideAuthorInfo = function () {
        this.wrapper.removeClass('show');
    };
    AUTHOR.prototype.isAuthorInfoShow = function () {
        return this.wrapper.hasClass('show');
    };
    AUTHOR.prototype.assignAuthorInfo = function (authorDOM) {
        var aid = authorDOM.find('input[name="id"]').val();
        var name = authorDOM.find('input[name="name"]').val();
        var desc = authorDOM.find('input[name="description"]').val();
        this.infoBlock.find('h4.author-title').text(name);
        this.infoBlock.find('.author-description').text(desc);
        this.infoBlock.find('a.author-allpost').attr('href', '/blog/author/' + aid);
    };

    // 文章閱讀進度
    function ReadProgress() {
        this.headerH = $('.whole-blog-content').offset().top;
        this.blogH = $('.whole-blog-content').height();
        this.windowH = $(window).height();
        this.windowW = $(window).width();
    }
    ReadProgress.prototype.reGetParameter = function () {
        this.headerH = $('.whole-blog-content').offset().top;
        this.blogH = $('.whole-blog-content').height();
        this.windowH = $(window).height();
        this.windowW = $(window).width();
        this.calculateReadingProgress();
    };
    ReadProgress.prototype.calculateReadingProgress = function () {
        var canScrollHeight = this.headerH + this.blogH - this.windowH;
        var currentH = $(window).scrollTop();
        console.log('canScrollHeight = ' + canScrollHeight + ', currentH = ' + currentH);
        if (currentH <= canScrollHeight) {
            var newWidth = this.windowW * (currentH / canScrollHeight);
            $('.reading-progress').width(newWidth);
        } else {
            $('.reading-progress').width(this.windowW);
        }
    };

    var blog = new BLOG();

    if (isBlogIndex) {
        // let loadingSlide = $('.category-title ~ .swiper-container');
        for (var i = 0, max = blog.categories.length; i < max; i++) {
            blog.categories[i].swiper = blog.categorySlider(blog.categories[i].dom);
        }
        // initial Authors Slider
        blog.initAuthorsSlider();

        // initial detect
        var initialHeight = $(window).scrollTop() + $(window).height();
        blog.detectScrollTop(initialHeight);
        document.addEventListener('touchmove', blog.detectScrollTop, false);
        $(window).scroll(FunNowTools.debounce(blog.detectScrollTop, 50, false));

        var author = new AUTHOR();
        author.avators.on('click', function (e) {
            var self = $(this);
            e.stopPropagation();
            if ($(this).hasClass('active')) {
                // click the same author-avator
                author.hideAuthorInfo();
                author.clearAllActive();
            } else {
                author.clearAllActive();
                author.activeAuthor(self);
                author.assignAuthorInfo(self);
                if (author.isAuthorInfoShow() === false) {
                    author.showAuthorInfo();
                }
            }
        });
        author.infoBlock.on('click', function (e) {
            e.stopPropagation();
        });
        $(document).on('click', function () {
            if (author.isAuthorInfoShow()) {
                author.hideAuthorInfo();
                author.clearAllActive();
            }
        });
    } else if (isListPage) {
        var _initialHeight = $(window).scrollTop() + $(window).height();
        blog.detectScrollTop(_initialHeight);

        console.log('分類,標籤,作者的文章列表');
        document.addEventListener('touchmove', blog.detectScrollTop, false);
        $(window).scroll(FunNowTools.debounce(blog.detectScrollTop, 50, false));
    } else if (isBlogArticle && $('.whole-blog-content').length > 0) {
        var readProgress = new ReadProgress();
        readProgress.calculateReadingProgress();
        document.addEventListener('touchmove', readProgress.calculateReadingProgress, false);
        $(window).scroll(FunNowTools.debounce(readProgress.calculateReadingProgress, 10, false).bind(readProgress));
        $(window).resize(FunNowTools.debounce(readProgress.reGetParameter, 250, false).bind(readProgress));
    }

    $('.blog-cover[data-image], .author-avator[data-image]').each(function (i, dom) {
        replaceThumbImage(dom);
    });

    // 20171226, 由於blog加上smartbanner, 不用在讓分類、標籤按鈕浮出來
    // var lastScrollPos = 0;
    // var touchPosY = null;
    // var stickyModalBtn = function(direction) {
    //     if (direction === 'down') {
    //         // if (newPos < 90) return false;
    //         // 滾輪向下,網頁內容往上
    //         if ($('.blog-modal-select-block').hasClass('fixed')) {
    //             console.log('[ScrollDown]移除block');
    //             $('.blog-modal-select-block').removeClass('fixed');
    //         }
    //     } else if (direction === 'up'){
    //         // 滾輪向上,網頁內容往下
    //         if ($('.blog-modal-select-block').hasClass('fixed') === false) {
    //             console.log('[ScrollUp]加入block');
    //             // App 內 webview 修正
    //             if ($('header').css('display') === 'none') {
    //                 $('.blog-modal-select-block').addClass('fixed no-header');
    //             } else {
    //                 $('.blog-modal-select-block').addClass('fixed');
    //             }
    //         }
    //     }
    // };
    // var scrollDetect = function() {
    //     if (isMobile.phone === false) return false;
    //     let newPos = $(window).scrollTop();
    //     if (newPos > lastScrollPos) {
    //         stickyModalBtn('down');
    //     } else if (newPos < lastScrollPos) {
    //         stickyModalBtn('up');
    //     }
    //     lastScrollPos = newPos;
    // };
    // var handleTouchStart = function(evt) {
    //     touchPosY = evt.touches[0].clientY;
    // };
    // var handleTouchMove = function(evt) {
    //     if (!touchPosY) {
    //         return false;
    //     }
    //     let newPosY = evt.touches[0].clientY;
    //     if (newPosY > touchPosY) {
    //         stickyModalBtn('down');
    //     } else if (newPosY < touchPosY) {
    //         stickyModalBtn('up');
    //     }
    //     touchPosY = null;
    // };

    // document.addEventListener('touchstart', handleTouchStart, false);
    // document.addEventListener('touchmove', handleTouchMove, false);
    // $(window).scroll(FunNowTools.debounce(scrollDetect, 10, false));

    $('.blog-card[data-url]').on('click', function () {
        var url = $(this).data('url');
        location.href = url;
    });
});