'use strict';

$(function () {
    // 需要檢查FunNow2.0是否不在需要，訂單頁面皆改用vue專案製作，理論上不再用到
    'use strict';

    // 判斷送禮功能是否可用

    if ($('#booking_time').length > 0) {
        var current = Math.floor(new Date().getTime() / 1000);
        var booking = $('#booking_time').data('bookingtime');
        var bookingtime = moment(booking).unix();

        if (current < bookingtime) {
            // 可送禮
            $('button[data-target="#giftCardModal"]').removeAttr('disabled');
        } else {
            // 不可送禮
            $('button[data-target="#giftCardModal"]').remove();
        }
    }

    function GiftSendForm(form) {
        this.$form = form;
        this.orderid = form.find('input[name="orderid"]');
        this.sign = form.find('input[name="sign"]');
        this.note = form.find('textarea[name="note"]');
    }
    GiftSendForm.prototype.getValue = function () {
        if (GIFTINFO.orderid.val() && GIFTINFO.sign.val() && GIFTINFO.note.val()) {
            return {
                orderid: GIFTINFO.orderid.val(),
                sign: GIFTINFO.sign.val(),
                note: GIFTINFO.note.val()
            };
        } else {
            return false;
        }
    };
    var GIFTINFO = undefined;
    var clipboard = undefined;

    if ($('form[name="SendGiftForm"]').length > 0) {
        GIFTINFO = new GiftSendForm($('form[name="SendGiftForm"]'));
    }

    // get member info and store to localStorage
    var getMemberInfo = function getMemberInfo() {
        var uid = $.cookie('uid');
        var token = $.cookie('token');
        var api = void 0;

        if (uid !== undefined && token !== undefined) {
            api = '/api/member-info?uid=' + uid + ' &token=' + token;

            $.getJSON(api, function (response) {
                // console.log(response);
                if (response.status.toLowerCase() === 'ok') {
                    var user_data = response.data[0];
                    // console.log(user_data);
                    window.localStorage.setItem('user_info', JSON.stringify(user_data));
                }
            });
        }
    };

    // 開啟填寫禮物卡, 如果已填寫過, 取出資料來放
    $('#giftCardModal').on('show.bs.modal', function () {
        getMemberInfo();
        var tmp = location.pathname.split('/');
        var orderid = tmp[tmp.length - 1];
        var sessionKey = 'giftinfo_' + orderid;
        var sessionData = window.sessionStorage.getItem(sessionKey);
        if (sessionData) {
            sessionData = JSON.parse(sessionData);
            GIFTINFO.sign.val(sessionData.sign);
            GIFTINFO.note.val(sessionData.note);
        }
    });

    // 開啟禮物連結前, 重新產生 line 分享按鈕, 複製連結按鈕 enabled
    $('#giftLinkModal').on('show.bs.modal', function () {
        // Line@桌面版
        if (isMobile.any === false) {
            LineIt.loadButton();
        }
        $('#send_gift').removeClass('disabled').removeAttr('disabled');
    });
    $('#giftLinkModal').on('shown.bs.modal', function () {
        clipboard = new Clipboard('#copy_btn', {
            // For use in Bootstrap Modals or with any other library
            // that changes the focus you'll want to set the focused element
            // as the container value
            container: document.getElementById('giftLinkModal'),
            text: function text(trigger) {
                return i18n.t('送你一份 FunNow 的禮物，點選連結接收吧：') + trigger.getAttribute('data-clipboard-text');
            }
        });
        clipboard.on('success', function (e) {
            // console.info('Action:', e.action);
            console.info('Text:', e.text);
            // console.info('Trigger:', e.trigger);
            $('#copy_btn').tooltip('toggle');
            e.clearSelection();
        });

        clipboard.on('error', function (e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
        });
    });

    var getGiftInfo = function getGiftInfo() {
        var giftinfo = GIFTINFO.getValue();
        if (giftinfo === false) {
            return false;
        }

        // 寫入 session storage
        var sessionKey = 'giftinfo_' + giftinfo.orderid;
        window.sessionStorage.setItem(sessionKey, JSON.stringify(giftinfo));
        return giftinfo;
    };

    var fillGiftLink = function fillGiftLink(link) {
        // 移除舊的 line 分享
        $('.social-share__btn-group iframe').remove();
        $('.social-share__btn-group #line_link').remove();
        $('.social-share__btn-group #whatsapp_link').remove();

        // 如果有使用者資料,帶入邀請碼
        // let UserInfo = window.localStorage.getItem('user_info');
        // if (UserInfo) {
        //     UserInfo = JSON.parse(UserInfo);
        //     link = link + '?promo=' + UserInfo.invitecode;
        // }

        // Line@桌面版
        // let LineShare = `<div class="line-it-button" data-lang="zh_Hant" data-type="share-d" data-url="${link}" style="display: none;"></div>`;
        // Line@手機版
        var shareLink = 'http://line.naver.jp/R/msg/text/?' + i18n.t('送你一份 FunNow 的禮物，點選連結接收吧：') + '%0D%0A' + link;
        var LineShare = '<a id="line_link" href="' + shareLink + '" target="_blank"><img src="/images/icon/line-share-b.png" alt="\u7528Line\u5206\u4EAB" /></a>';
        // WhatsApp
        shareLink = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(i18n.t('送你一份 FunNow 的禮物，點選連結接收吧：') + link);
        var whatsappBtn = '<a id="whatsapp_link" href="' + shareLink + '" target="_blank" rel="noopener noreferrer"><img src="/images/icon/WhatsApp_Logo_6.png" alt="whatsapp"></a>';

        $('#share_link_input').val(link).attr('value', link);
        $('#copy_btn').attr('data-clipboard-text', link);

        if (isMobile.any) {
            $('.social-share__btn-group').append(LineShare);
            $('#share_fb').before(whatsappBtn);
            $('#share_fb').remove();
        } else {
            $('#share_fb').attr('data-link', link);
            $('#share_fb').before(LineShare);
        }
    };

    $('#send_gift').on('click', function (e) {
        e.preventDefault();
        var giftinfo = getGiftInfo();
        if (giftinfo === false) {
            var hinttext = GIFTINFO.sign.val() === '' ? i18n.t('Please fill out your name') : i18n.t('Please write some words to him/her');
            return swal({
                text: hinttext,
                type: 'warning',
                confirmButtonText: i18n.t('OK')
            });
        }

        var target = $(this);
        target.attr('disabled', 'disabled').addClass('disabled');
        var api_link = '/api/orders/gift_send';
        var data = {
            'uid': $.cookie('uid'),
            'token': $.cookie('token'),
            'orderid': giftinfo.orderid,
            'sign': giftinfo.sign,
            'note': giftinfo.note
        };

        $.ajax({
            type: 'POST',
            url: api_link,
            data: data,
            dataType: 'json',
            success: function success(result) {
                console.log(result);
                if (result.status.toLowerCase() === 'ok') {

                    var giftlink = 'https://' + location.host + FunNowTools.prefixUrl + '/gift/' + result.data.link_hash;
                    fillGiftLink(giftlink);
                    $('#giftCardModal').modal('hide');
                    $('#giftLinkModal').modal('show');
                } else {
                    // TBD::comment fail handle
                    console.log(result.message);
                    swal({
                        title: i18n.t('Error'),
                        text: result.message,
                        type: 'warning',
                        confirmButtonText: i18n.t('OK')
                    }).then(function (result) {
                        if (result.value) {
                            location.href = FunNowTools.prefixUrl + '/order/list';
                        }
                    });
                    target.removeClass('disabled').removeAttr('disabled');
                }
            }
        });
    });

    $('#share_fb').on('click', function () {
        var link = $(this).data('link');
        FB.ui({
            method: 'send',
            link: link
        });
    });

    // 刪除訂單
    var deleteOrder = function deleteOrder(id, orderRow) {
        var link = '/order/delete/' + id;
        $.ajax({
            type: 'DELETE',
            url: link,
            dataType: 'json',
            success: function success(result) {
                if (result.status.toLowerCase() === 'ok') {
                    swal({
                        title: i18n.t('Successfully deleted'),
                        text: i18n.t('The order has been deleted'),
                        type: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: i18n.t('OK')
                    }).then(function () {
                        $(orderRow).remove();
                        window.location.reload();
                    });
                }
            }
        });
    };

    $('.order-delete').on('click', function (e) {
        e.preventDefault();
        var id = $(this).data('id');
        console.log('delete id = ' + id);

        var title = $(this).data('title');
        if (title.length > 10) {
            title = title.substring(0, 10) + '...';
        }
        var orderRow = $(this).parents('.order-list-card');

        swal({
            title: i18n.t('Delete confirm'),
            text: i18n.t('Delete') + '「' + title + '」!',
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t('Cancel'),
            confirmButtonColor: '#3085d6',
            confirmButtonText: i18n.t('OK')
        }).then(function (result) {
            if (result.value) {
                // handle confirm
                deleteOrder(id, orderRow);
            } else if (result.dismiss || result.dismiss === 'cancel') {
                console.log('不刪除了');
            }
        });
    });

    function Stars() {
        this.stars = $('#comment_stars .fa');
        this.form = $('#commentForm');
        this.input = $('#commentForm input[name="stars"]');
        this.comment = $('#commentForm textarea[name="comment"]');
    }

    Stars.prototype.click = function (id) {
        this.input.val(id);
        for (var i = 0; i < 5; i += 1) {
            if (i < id) {
                this.stars[i].className = 'fa fa-star fa-lg';
            } else {
                this.stars[i].className = 'fa fa-star-o fa-lg';
            }
        }
    };

    var stars = new Stars();

    $('#comment_stars .fa').on('click', function () {
        var star_id = $(this).data('id');
        $('#comment_score').text(star_id + '.0');
        stars.click(star_id);
    });

    var commentSuccessAlert = function commentSuccessAlert() {
        swal({
            title: i18n.t('Successful'),
            text: i18n.t('Thanks for your comment!'),
            type: 'success',
            confirmButtonText: i18n.t('OK'),
            timer: 1000
        }).then(function (result) {
            if (result.dismiss || result.dismiss === 'timer') {
                window.location.href = '/order/list';
            }
        });
    };
    var commentFailAlert = function commentFailAlert(msg) {
        swal({
            title: i18n.t('Comments failed'),
            text: msg,
            type: 'warning',
            confirmButtonText: i18n.t('OK'),
            timer: 700
        }).then(function (result) {
            if (result.dismiss || result.dismiss === 'timer') {
                window.location.href = '/order/list';
            }
        });
        $('#orderCommitSubmit').removeClass('disabled').removeAttr('disabled');
    };

    var getCommentQues = function getCommentQues() {
        var checkQues = $('input[name="comment_ques"]:checked');
        var ques = [];
        checkQues.each(function (i, d) {
            ques.push(d.value);
        });
        return ques.toString();
    };
    $('#orderCommitSubmit').on('click', function () {

        var link = stars.form[0].action;
        var ids = getCommentQues();
        var data = {
            stars: stars.input.val(),
            comment: stars.comment.val(),
            quest_ids: ids
        };
        if (data.comment.length < 5) {
            swal({
                title: i18n.t('Comments failed'),
                text: i18n.t('Please enter at least 5 characters'),
                type: 'warning',
                confirmButtonText: i18n.t('OK')
            });
        } else {
            // 阻止使用者狂送評論
            $(this).addClass('disabled').attr('disabled', 'disabled');
            $.ajax({
                type: 'PUT',
                url: link,
                data: data,
                dataType: 'json',
                success: function success(result) {
                    console.log(result);
                    if (result.status.toLowerCase() === 'ok') {
                        commentSuccessAlert();
                    } else {
                        // TBD::comment fail handle
                        console.log(result.message);
                        commentFailAlert(result.message);
                    }
                }
            });
        }
    });

    // 重算灰底高度
    var resetGrayHeight = function resetGrayHeight() {
        var sections = $('section:not(#appdownload):not(.bg-gray)');
        var window_height = $(window).height() - 44; // navbar:44px;
        var total_height = 0;
        sections.each(function (index, dom) {
            total_height += $(dom).height();
        });

        var new_height = window_height - total_height;
        // 下方資訊最低高度 190,千萬死守住
        new_height = new_height < 190 ? 190 : new_height;
        $('.product-info__wrapper.half').height(new_height);
    };

    // 快速評論展開收合
    $('#quickCommentToggle').on('click', function () {
        var icon = $(this).find('i.fa');
        if (icon.hasClass('fa-angle-down')) {
            icon.removeClass('fa-angle-down').addClass('fa-angle-up');
        } else if (icon.hasClass('fa-angle-up')) {
            icon.removeClass('fa-angle-up').addClass('fa-angle-down');
        }
        $('.quick-comment__content').toggle('500', resetGrayHeight);
    });
    resetGrayHeight();

    var cancelOrder = function cancelOrder(orderid) {
        console.log('呼叫取消訂單API');
        var link = '/order/cancel';
        var data = {
            orderid: orderid,
            reason: ''
        };
        $.ajax({
            type: 'POST',
            url: link,
            data: data,
            dataType: 'json',
            success: function success(result) {
                if (result.status.toLowerCase() === 'ok') {
                    swal({
                        title: i18n.t('Successfully deleted'),
                        text: i18n.t('The order has been deleted'),
                        type: 'success',
                        timer: 2000,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: i18n.t('OK')
                    }).then(function (result) {
                        console.log(result);
                        location.href = FunNowTools.prefixUrl + '/order/list';
                    });
                } else {
                    swal({
                        type: 'error',
                        title: '取消失敗',
                        text: '請聯絡客服處理'
                    });
                    $('#cancel_btn').prop('disabled', false);
                }
            }
        });
    };

    var showCancelInfo = function showCancelInfo(msg) {
        var orderid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        var confimText = orderid != '' ? i18n.t('確定取消') : i18n.t('確定');
        var showCancel = orderid != '' ? true : false;
        $('#cancel_btn').prop('disabled', true);
        swal({
            type: 'warning',
            title: i18n.t('取消訂單'),
            text: msg,
            showCancelButton: showCancel,
            cancelButtonText: i18n.t('讓我再想想'),
            focusConfirm: false,
            confirmButtonText: confimText,
            confirmButtonColor: '#fc6c4b'
        }).then(function (result) {
            // result.value = true 確定
            if (result.value) {
                if (orderid == '') {
                    return false;
                } else {
                    cancelOrder(orderid);
                }
            } else {
                // result.dismiss = overlay:點旁邊取消
                // result.dismiss = cancel:取消
                console.log('不取消訂單');
                $('#cancel_btn').prop('disabled', false);
            }
        });
    };

    // 取消訂單
    $('#cancel_btn').on('click', function (e) {
        e.preventDefault();
        var now = moment();
        var cancelable = $(this).data('cancelable');
        if (cancelable == 0) {
            showCancelInfo(i18n.t('unable to cancel msg'));
            return false;
        }

        var orderid = $(this).data('orderid');
        var createTime = $(this).data('create-time');
        var bookingTime = $(this).data('booking-time');
        createTime = moment(createTime);
        bookingTime = moment(bookingTime);

        var afterCreateTime = now.unix() - createTime.unix();
        var beforeBookingTime = bookingTime.unix() - now.unix();
        // console.log(afterCreateTime, beforeBookingTime);

        if (afterCreateTime < 0) {
            // 時間不對,不動作
            return false;
        }

        if (afterCreateTime < 10 * 60) {
            // 10分鐘內取消,全退
            showCancelInfo(i18n.t('free cancel msg'), orderid);
        } else if (beforeBookingTime > 30 * 60) {
            // 預訂時間前30分鐘, 退7成
            showCancelInfo(i18n.t('pay cancel msg'), orderid);
        } else {
            showCancelInfo(i18n.t('no cancel msg'));
        }
    });
});