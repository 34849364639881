'use strict';

$(function () {
    // 以前的搜尋頁面使用，目前已不在使用
    'use strict';

    var Branchs = {
        block: $('.branchs-block'),
        loading: $('#branchs_loading'),
        pagination: $('.pagination-block'),
        prevBtn: $('.pagination-block .prev-btn'),
        nextBtn: $('.pagination-block .next-btn')
    };

    var watchPos = undefined;

    /*
    <div class="branch-card branch-card--bordered">
      <a href="/branches/85#Zhongshan-Li-Hao-Hotel" target="_blank">
        <div class="cover" style="background-image:url('https://cdn.myfunnow.com/stg_images%2Fbid_85%2F00.jpg')"></div>
        <div class="info-block">
          <h3 class="title">中山莉豪商旅</h3>
          <p class="description"></p>
          <div class="addition-block">
            <div>台北市中山區</div>
            <div class="rating">
              <span class="number">4.5</span>
              <div class="rating-stars">
                <img src="/images/icon/star.svg" alt="star" class="svg-icon">
                <img src="/images/icon/star.svg" alt="star" class="svg-icon">
                <img src="/images/icon/star.svg" alt="star" class="svg-icon">
                <img src="/images/icon/star.svg" alt="star" class="svg-icon">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
     */
    var getRatingStars = function getRatingStars(rating) {
        var stars = Math.round(parseFloat(rating));
        var empty_stars = 5 - stars;
        var DOM = '';
        if (stars >= 1) {
            for (var i = 1; i <= stars; i++) {
                DOM += '<img src="/images/icon/star.svg" alt="star" class="svg-icon">';
            }
        }
        if (empty_stars >= 1) {
            for (var _i = 1; _i <= empty_stars; _i++) {
                DOM += '<img src="/images/icon/star-o.svg" alt="star-o" class="svg-icon">';
            }
        }
        return DOM;
    };

    var generateBranchCrad = function generateBranchCrad(branch) {
        var imgSrc = branch.branchimage === '' ? 'web/images/default.png' : encodeURIComponent(branch.branchimage);
        imgSrc = GLOBAL.CDN_URI + imgSrc;
        var branchname = FunNowTools.htmlEntities(branch.branchname);
        var intro = branch.intro === '' ? branch.transportation : branch.intro;
        intro = FunNowTools.htmlEntities(intro);
        var stars = getRatingStars(branch.rating);

        var DOM = '\n            <div class="branch-card branch-card--bordered">\n              <a href="/branches/' + branch.bid + '#' + branch.branchname_en + '" target="_blank">\n                <div class="cover" style="background-image:url(\'' + imgSrc + '\')"></div>\n                <div class="info-block">\n                  <h3 class="title">' + branchname + '</h3>\n                  <p class="description">' + intro + '</p>\n                  <div class="addition-block">\n                    <div>' + branch.area + '</div>\n                    <div class="rating">\n                      <span class="number">' + branch.rating + '</span>\n                      <div class="rating-stars">' + stars + '</div>\n                    </div>\n                  </div>\n                </div>\n              </a>\n            </div>';
        return DOM;
    };

    var arrangeBranchData = function arrangeBranchData(branchs) {
        // var allBranch = '';
        Branchs.loading.removeClass('show');
        for (var i = 0, max = branchs.length; i < max; i++) {
            // allBranch += generateBranchCrad(branchs[i]);
            Branchs.block.append(generateBranchCrad(branchs[i]));
        }
    };

    var updatePageNumber = function updatePageNumber(page, next) {
        var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        page = parseInt(page);
        var prevBtnDOM = '';
        var nextBtnDOM = '';
        var path = '';

        if (page > 1) {
            path = location.pathname + '?page=' + (page - 1);
            if (sort) {
                path += '&sort=' + sort;
            }
            path = appendQuqeryString(['regionid', 'rpgid', 'keyword', 'tagid'], path);
            prevBtnDOM += '<a class="btn btn-ghost btn-ghost--gray prev-btn" ' + 'href="' + path + '">' + i18n.t('Prev') + '</a>';
        }

        if (next) {
            path = location.pathname + '?page=' + (page + 1);
            if (sort) {
                path += '&sort=' + sort;
            }
            path = appendQuqeryString(['regionid', 'rpgid', 'keyword', 'tagid'], path);
            nextBtnDOM += '<a class="btn btn-ghost btn-ghost--gray next-btn" ' + 'href="' + path + '">' + i18n.t('Next') + '</a>';
        }
        Branchs.pagination.append(prevBtnDOM);
        Branchs.pagination.append(nextBtnDOM);
    };

    var loadingBranchs = function loadingBranchs() {
        var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'remove';

        if (type === 'hide') {
            Branchs.block.children().hide();
            Branchs.pagination.children().hide();
        } else if (type === 'remove') {
            Branchs.block.children().remove();
            Branchs.pagination.children().remove();
        }
        Branchs.loading.addClass('show');
    };

    var fetchBranchs = function fetchBranchs(url) {
        loadingBranchs('remove');

        url = url + '&type=api';
        var page = FunNowTools.getParameter('page', url);
        var sort = FunNowTools.getParameter('sort', url);
        if (page == null) {
            page = 1;
        }

        $.get(url, function (data) {
            console.log(data);
            arrangeBranchData(data.branchs);
            updatePageNumber(page, data.next_page, sort);

            var branchsBlockTop = $('.branchs-block').offset().top - 120;
            $('html, body').animate({
                scrollTop: branchsBlockTop
            }, 500);
        });
    };

    var appendQuqeryString = function appendQuqeryString(params, path) {
        if (params.length === 0) {
            return path;
        }

        for (var i = 0, max = params.length; i < max; i++) {
            var param = FunNowTools.getParameter(params[i]);
            if (param !== null && param.length > 0) {
                path += '&' + params[i] + '=' + param;
            }
        }
        return path;
    };

    var getGEOAndRedirect = function getGEOAndRedirect(position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;

        $.cookie('GEO_lat', lat, { expires: 1, path: '/' });
        $.cookie('GEO_lng', lng, { expires: 1, path: '/' });

        var path = location.pathname + '?sort=distance';
        path = appendQuqeryString(['regionid', 'rpgid', 'keyword', 'tagid'], path);
        path += '&lat=' + lat + '&lng=' + lng;

        fetchBranchs(path);
    };

    var getPositionFail = function getPositionFail(err) {
        Branchs.block.children().show();
        Branchs.pagination.children().show();
        Branchs.loading.removeClass('show');
        console.warn('ERROR(' + err.code + '): ' + err.message);
    };

    var changeGEOLocation = function changeGEOLocation(pos) {
        var lat = pos.coords.latitude;
        var lng = pos.coords.longitude;

        $.cookie('GEO_lat', lat, { expires: 1, path: '/' });
        $.cookie('GEO_lng', lng, { expires: 1, path: '/' });
    };

    $('#categorySort').on('change', function () {
        var val = $(this).val();

        if (val === 'distance') {
            try {
                if (navigator.geolocation) {
                    loadingBranchs('hide');
                    var options = {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    };
                    navigator.geolocation.getCurrentPosition(getGEOAndRedirect, getPositionFail, options);

                    options.enableHighAccuracy = false;
                    watchPos = navigator.geolocation.watchPosition(changeGEOLocation, getPositionFail, options);
                } else {
                    console.info('Geolocation is not supported by this browser.');
                }
            } catch (err) {
                console.error(err.message);
            }
        } else {
            if (watchPos !== undefined) {
                navigator.geolocation.clearWatch(watchPos);
                watchPos = undefined;
            }
            var path = location.pathname + '?sort=' + val;
            path = appendQuqeryString(['regionid', 'rpgid', 'keyword', 'tagid'], path);

            fetchBranchs(path);
        }
    });

    var switchPage = function switchPage(e) {
        e.preventDefault();
        var path = $(e.currentTarget).attr('href');
        var sort = $('#categorySort').val();
        path += '&sort=' + sort;

        if (sort === 'distance') {
            var lat = $.cookie('GEO_lat');
            var lng = $.cookie('GEO_lng');
            if (lat && lng) {
                path += '&lat=' + lat + '&lng=' + lng;
            }
        }
        fetchBranchs(path);
    };

    $('.pagination-block').on('click', '.prev-btn,.next-btn', switchPage);
});